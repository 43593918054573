<template>
<Navbars></Navbars>
    <div class="">
        <div class="container">
            <h3 class="mt-5 mb-4 title"><b>Scan Loker</b></h3>

            <div class="row loker-group">
                <div v-for="list in detailList" :key="list.id" class="loker__link d-flex justify-content-center align-items-center">
                    <router-link :to="list.status === 'Expired' ? '/scan-loker-expired/'+list.locker_number_id+'/'+list.number_code : '/scan-loker/'+list.locker_number_id+'/'+list.number_code" :class="list.status=='Booking' ? 'active': list.status=='Expired' ? 'expired' : ''" class="loker__list d-flex justify-content-center align-items-center" :scan_again_url="list.status === 'Expired' ? '/scan-loker-expired/'+list.locker_number_id+'/'+list.number_code : '/scan-loker/'+list.locker_number_id+'/'+list.number_code" @click="storeUrlScan">
                        <div>
                            <h3 class="loker__text">
                                {{ list.number_code}}
                            </h3>
                        </div>
                    </router-link>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import Navbars from '@/components/Navbars.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'

export default {
    name: 'Loker',
    components: {
        Navbars,
    },
    methods:{
        storeUrlScan: function(event){
            var urlScanAgain = event.currentTarget.getAttribute('scan_again_url')
			localStorage.setItem('url_scan_again', urlScanAgain)
        }
    },
    setup(){
        const token = localStorage.getItem('token')
        const detailList = ref([])
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'
        const route = useRoute()
        const router = useRouter();
        localStorage.setItem('schema_id', 1)

        
        onMounted(() => {
            if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`locker-number/${route.params.id}`)
			.then(response => {
                console.log(response)
				detailList.value = response.data.data
                // localStorage.setItem('number_code', response.data.data.number_code)
			}).catch((err) => {
				console.log(err.response)
			})
		})

        return{
			detailList,
            token,
            router,
            route,
            role,
		}
    }
}
</script>

<style>
    /* ===== Loker List ===== */
    .loker-group{
        margin: 0;
        padding: 0;
    }
    .loker-group .loker__link{
        text-decoration: none;
        color: white;
        width: 16.6%;
        height: 150px;
        padding: 5px;
        transition: all 0.3s ease;
    }
    .loker-group .loker__link:hover{
        opacity: 0.8;
        color: white;
    }
    .loker-group .loker__link .loker__list{
        width: 100%;
        height: 100%;
        background: url('../../assets/images/bg-locker-img.svg'), var(--blueGradient);
        background-size: cover;
        background-position-y: bottom;
        color: white;
        text-decoration: none;

    }
    .loker-group .loker__link .loker__list h3{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: bold;
    }
    .loker-group .loker__link .loker__list.active{
        width: 100%;
        height: 100%;
        background: url('../../assets/images/icon/bubble.svg'), var(--darkGrey);
        background-size: cover;
        background-position-y: bottom; 
    }
    .loker-group .loker__link .loker__list.expired{
        width: 100%;
        height: 100%;
        background: url('../../assets/images/icon/bubble.svg'), var(--redGradient);
        background-size: cover;
        background-position-y: bottom; 
    }
    /* ===== Loker List ===== */
    @media (max-width: 768px){
        
        .loker-group .loker__link{
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100px;
            padding: 5px;
        }
    }
    @media (max-width: 576px){
        h3.title{
            text-align: center;
        }
        .loker-group .loker__link{
            text-decoration: none;
            color: white;
            width: 33%;
            height: 100px;
            padding: 5px;
        }
    }
</style>