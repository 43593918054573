import { createRouter, createWebHashHistory } from 'vue-router'
import Auth from '../views/Auth.vue'
import Slide from '../views/Slide.vue'
import About from '../views/About.vue'
import Verifikasi from "../views/Verifikasi.vue";
import RequestResetPassword from "../views/RequestResetPassword.vue"
import FormResetPassword from "../views/FormResetPassword.vue"

// Customer
import Customer from '../views/Customer/Home.vue'
import Profile from '../views/Customer/Profil.vue'
import RiwayatOrder from '../views/Customer/RiwayatOrder.vue'
import Layanan from '../views/Customer/Layanan.vue'
import Booking from '../views/Customer/Booking.vue'
import BookingDropPoint from '../views/Customer/BookingDropPoint.vue'
import Loker from '../views/Customer/LokerList.vue'
import LokerDetail from '../views/Customer/LokerDetail.vue'
import dropPointDetail from '../views/Customer/DropPointDetail.vue'
import BookingScan from '../views/Customer/Scan.vue'
import Identity from '../views/Customer/DataDiri.vue'
import Pin from '../views/Customer/Pin.vue'
import ScanAmbil from '../views/Customer/ScanAmbil.vue'
import PinAmbil from '../views/Customer/PinAmbil.vue'
import DataAmbil from '../views/Customer/DataAmbil.vue'
import Invoice from '../views/Customer/Invoice.vue'
import Rating from '../views/Customer/Rating.vue'
import DroppointFinish from '../views/Customer/DropPointFinish.vue'
import AntarJemput from '../views/Customer/AntarJemput.vue'
import AntarJemputDetail from '../views/Customer/AntarJemputDetail.vue'
import AntarJemputScanAmbil from '../views/Customer/AntarJemputScanAmbil.vue'
import AntarJemputDataScan from '../views/Customer/AntarJemputDataScan.vue'
import Comingsoon from '../views/Customer/Comingsoon.vue'
import Laundry from '../views/Customer/DetailLaundry.vue'

// Supir
import Supir from '../views/Supir/Home.vue'
import ScanLoker from '../views/Supir/ScanLoker.vue'
import ScanDroppoint from '../views/Supir/ScanDroppoint.vue'
import ScanExpired from '../views/Supir/ScanExpired.vue'
import PinPickup from '../views/Supir/PinPickup.vue'
import PinExpired from '../views/Supir/PinExpired.vue'
import LokerList from '../views/Supir/LokerList.vue'
import DroppointList from '../views/Supir/DroppointList.vue'
import DetailList from '../views/Supir/DetailList.vue'
import DetailData from '../views/Supir/DataScan.vue'
import DataDroppoint from '../views/Supir/DataDroppoint.vue'
import ScanFinish from '../views/Supir/ScanFinish.vue'
import Pickup from '../views/Supir/ListPickup.vue'
import Riwayat from '../views/Supir/RiwayatScan.vue'

// Kurir
import Kurir from '../views/Kurir/Home.vue'
import RiwayatJemput from '../views/Kurir/RiwayatJemput.vue'
import RiwayatAntar from '../views/Kurir/RiwayatAntar.vue'
import KurirScan from '../views/Kurir/KurirScan.vue'
import OrderData from '../views/Kurir/DataScan.vue'

// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/login',
    name: 'auth',
    component: Auth,
    meta: { guest: true }
  },
  {
    path: '/selamat-datang-di-wabo',
    name: 'slide',
    component: Slide,
    meta: { guest: true }
  },
  {
    path: "/verifikasi-email-:type",
    name: "verifikasi-berhasil",
    component: Verifikasi,
    meta: { guest: true },
  },
  {
    path: '/request-reset-password',
    name: 'request-reset-password',
    component: RequestResetPassword,
    meta: { guest: true }
  },
  {
    path: '/forget-password/verify/:id',
    name: 'forget-password',
    component: FormResetPassword,
    meta: { guest: true }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Customer,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/coming-soon',
    name: 'comingsoon',
    component: Comingsoon,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/antar-jemput',
    name: 'AntarJemput',
    component: AntarJemput,
    meta: { requiresAuth: true }
  },
  {
    path: '/antar-jemput/detail',
    name: 'AntarJemputDetail',
    component: AntarJemputDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/antar-jemput/ambil',
    name: 'AntarJemputAmbil',
    component: AntarJemputScanAmbil,
    meta: { requiresAuth: true }
  },
  {
    path: '/antar-jemput/ambil/data/:number',
    name: 'AntarJemputDataScan',
    component: AntarJemputDataScan,
    meta: { requiresAuth: true }
  },
  {
    path: '/riwayat-order',
    name: 'riwayat-order',
    component: RiwayatOrder,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/layanan',
    name: 'layanan',
    component: Layanan,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking',
    name: 'booking',
    component: Booking,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking-drop-point',
    name: 'bookingDropPoint',
    component: BookingDropPoint,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking/loker/:id',
    name: 'loker',
    component: Loker,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking/loker-detail/:id',
    name: 'lokerDetail',
    component: LokerDetail,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/droppoint/booking/:id',
    name: 'dropPointDetail',
    component: dropPointDetail,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking/scan',
    name: 'scan',
    component: BookingScan,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/scan/ambil',
    name: 'scanAmbil',
    component: ScanAmbil,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking/identity/:kode',
    name: 'identity',
    component: Identity,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/booking/pin/:kode',
    name: 'pin',
    component: Pin,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/detail-data/:code',
    name: 'dataAmbil',
    component: DataAmbil,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/pin/ambil',
    name: 'pinAmbil',
    component: PinAmbil,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/invoice/:laundry_id',
    name: 'invoice',
    component: Invoice,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/laundry/detail/:laundry_id',
    name: 'laundry-detail',
    component: Laundry,
    meta: { requiresAuth: true }
  },
  {
    path: '/rating',
    name: 'rating',
    component: Rating,
    // props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/droppoint/finish',
    name: 'droppoint-finish',
    component: DroppointFinish,
    // props: true,
    meta: { requiresAuth: true }
  },

  // ====== Driver ======
  {
    path: '/home',
    name: 'home',
    component: Supir,
    meta: { requiresAuth: true }
  },
  {
    path: '/loker-place',
    name: 'loker-list',
    component: LokerList,
    meta: { requiresAuth: true }
  },
  {
    path: '/droppoint-place',
    name: 'droppoint-list',
    component: DroppointList,
    meta: { requiresAuth: true }
  },
  {
    path: '/list-detail/:id',
    name: 'loker-detail',
    component: DetailList,
    meta: { requiresAuth: true }
  },
  {
    path: '/scan-loker/:id/:number_code',
    name: 'scan-loker',
    component: ScanLoker,
    meta: { requiresAuth: true }
  },
  {
    path: '/scan-droppoint/:id',
    name: 'scan-droppoint',
    component: ScanDroppoint,
    meta: { requiresAuth: true }
  },
  {
    path: '/req-pin/:number',
    name: 'pin-pickup',
    component: PinPickup,
    meta: { requiresAuth: true }
  },
  {
    path: '/scan-loker-expired/:id/:number_code',
    name: 'scan-loker-expired',
    component: ScanExpired,
    meta: { requiresAuth: true }
  },
  {
    path: '/req-pin-expired/:number',
    name: 'pin-expired',
    component: PinExpired,
    meta: { requiresAuth: true }
  },
  {
    // path: '/detail-data-pelanggan/:number',
    path: '/data-pelanggan',
    name: 'detail-data',
    component: DetailData,
    meta: { requiresAuth: true }
  },
  {
    path: '/scan/data-droppoint/:drop_code',
    name: 'data-droppoint',
    component: DataDroppoint,
    meta: { requiresAuth: true }
  },
  {
    path: '/scan-selesai',
    name: 'scan-selesai',
    component: ScanFinish,
    meta: { requiresAuth: true }
  },
  {
    path: '/pickup',
    name: 'pickup',
    component: Pickup,
    meta: { requiresAuth: true }
  },
  {
    path: '/riwayat-scan',
    name: 'riwayat-scan',
    component: Riwayat,
    meta: { requiresAuth: true }
  },

  // ======= Kurir =========
  {
    path: '/home-kurir',
    name: 'home-kurir',
    component: Kurir,
    meta: { requiresAuth: true }
  },
  {
    path: '/riwayat-jemput',
    name: 'riwayat-jemput',
    component: RiwayatJemput,
    meta: { requiresAuth: true }
  },
  {
    path: '/riwayat-antar',
    name: 'riwayat-antar',
    component: RiwayatAntar,
    meta: { requiresAuth: true }
  },
  {
    path: '/order-scan',
    name: 'order-scan',
    component: KurirScan,
    meta: { requiresAuth: true }
  },
  {
    path: '/order-scan/data/:number',
    name: 'order-data',
    component: OrderData,
    meta: { requiresAuth: true }
  },
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {  
  const token = localStorage.getItem('token') == null;  
  if (to.matched.some(record => record.meta.guest)) {
    if (!token) next({ name: 'slide' })
    else next()
  } 
  else if (to.matched.some(record => record.meta.requiresAuth)) { 
    if (token) {
      next({
        name: 'slide',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    } 
  }else{
    next()  
  }
})

export default router
