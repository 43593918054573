<template>
    <div class="login-header p-3">
        <img src="../assets/images/top-right-l.svg" class="log-decor-c" alt="">
        <img src="../assets/images/bottom-left-l.svg" class="log-decor" alt="">
        <img src="../assets/images/bg-login.jpg" class="log-bg" alt="">
        <div class="container log-inner">
            <div class="brand">
                <img src="../assets/images/WABO_Laundry_Tagline.png" class="img-log me-2 my-2" alt="">
            </div>
        </div>
    </div>

    <div class="intro" v-show="!intro">
		<swiper class="slider" :navigation="{ nextEl: '.b-next', prevEl: '.b-prev', }" :pagination="{ el: '.swiper-pagination', dynamicBullets: true, clickable: true }">
            <swiper-slide>
                <div class="title my-3">
                    <h5 class="text-center">Halo kami adalah Laundry!</h5>
                    <h5 class="text-center">Laundry dengan smartlocker pertama di Bali!</h5>
                </div>
                <figure class="text-center">
                    <img src="../assets/images/intro-img1.svg" alt="">
                </figure>
                <div class="swiper-nav-next b-next text-center">
                    <button class="btn btn__primary">Selanjutnya</button>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="title my-3">
                    <h5 class="text-center">Laundry kami menawarkan <br> keamanan cucian dengan sistem kunci barcode.</h5>
                </div>
                <figure class="text-center">
                    <img src="../assets/images/intro-img2.svg" alt="">
                </figure>
                <div class="swiper-nav-next b-next text-center">
                    <button class="btn btn__primary">Selanjutnya</button>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="title my-3">
                    <h5 class="text-center">Ayo rasakan <br> Laundry Milenial dengan Lokerlaundry!!</h5>
                </div>
                <figure class="text-center">
                    <img src="../assets/images/intro-img3.svg" alt="">
                </figure>
                
                <div class="b-next text-center">
                    <button class="btn btn__primary" @click="mulai()">Mulai</button>
                </div>
            </swiper-slide>
            <div class="swiper-pagination"></div>
        </swiper>
    </div>

    <div class="container">
        <div class="row my-5">
            <div class="col-lg-6 mx-auto">
                <div class="box-login">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link left active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">
                                Reset Password
                            </button>
                            <hr>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                            <div v-if="sukses" class="alert alert-success" role="alert">
                                <strong>Berhasil Mengubah password. <br>Anda akan di alihkan ke halaman login.</strong>
                            </div>
                            <form class="mx-2 mt-4" @submit.prevent="resetPassword()">
                                <div class="mb-4 form-group">
                                    <label for="passwordr" class="form-label"><span>Password</span></label>
                                    <input type="password" class="form-control" id="password1">
                                </div>
                                <div class="mb-4 form-group">
                                    <label for="passwordcr" class="form-label"><span>Confirm Password</span></label>
                                    <input type="password" class="form-control" id="password2">
                                </div>
                                
                                <div class="d-flex justify-content-end">
                                    <button type="submit" class="btn btn__primary mb-3 d-flex align-items-center justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right me-2" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                            <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                        </svg>
                                        Reset Password
                                    </button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { onMounted, reactive, ref} from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import axios from 'axios'

import SwiperCore, { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

SwiperCore.use([Navigation, Autoplay, Pagination, Scrollbar, A11y])
    
export default {
    name: 'Auth',
    components: {
		Swiper,
		SwiperSlide,
	},
    setup(){
        const form = reactive({
            password1: '',
            password2: '',
            token_key: ''
        });
        const route = useRoute()
        const router = useRouter();

        const failed = ref(null);

        const sukses = ref(false);
        const intro = ref(true);

        onMounted(() => {
            localStorage.removeItem('droppoint_code')
            localStorage.removeItem('role')
            localStorage.removeItem('droppoint_id')
            localStorage.removeItem('fullname')
            localStorage.removeItem('scan_type')
            localStorage.removeItem('qr_code_d')
            localStorage.removeItem('schema_id')
            localStorage.removeItem('name')
            localStorage.removeItem('redirect_after_rating')
            localStorage.removeItem('url_scan_again')
        })

        function mulai(){
            intro.value = false
        }

        function checkpass(input_text, input_text2){
            var regexp = /\d/g;
            var data = {
                'message': '',
                'valid': false
            }
            if(input_text != input_text2){
                data.message = 'Passwordnya tidak sama gan!'
                data.valid = false
                return data
            }else{
                // cek panjang password
                if(input_text.length >= 8) 
                {
                    // check password contain number
                    if(regexp.test(input_text) == true){
                        // check password semua charter number
                        if(/^\d+$/.test(input_text) == true){
                            data.message = 'Password terlalu lemah, tidak boleh angka semua!'
                            data.valid = false
                            return data
                        }else{
                            data.message = 'Password diperbolehkan!'
                            data.valid = true
                            return data
                        }

                    }else{
                        data.message = 'Password harus berisi 1 angka atau lebih'
                        data.valid = false
                        return data
                    }
                }
                else
                { 
                    data.message = 'Password niminum 8 character ya!'
                    data.valid = false
                    return data;
                }
            }
            
        }

        function resetPassword(){
            form.password1 = document.getElementById("password1").value
            form.password2 = document.getElementById("password2").value
            form.token_key = `${route.params.id}`
            var data_check = checkpass(document.getElementById("password1").value, document.getElementById("password2").value)
            if(data_check.valid == true){
                axios.post('forget-password/new-password', form)
                .then(response => {
                    console.log(response)
                    sukses.value = false;
                    if(response.data.status == 'success') {
                        sukses.value = true;
                        console.log('Respon reset password: ', response)
                        createToast(response.data.message,
                        {
                            type: 'success',
                            timeout: 5000,
                            showIcon: 'true',
                            transition: 'bounce'
                        })
                        setTimeout(() => {
                            return router.push({
                                path: '/'
                            })
                        }, 5000)
                        
                    }else if(response.data.status == 'error'){
                        // console.log('FAIL LOGIN : ', response)
                        sukses.value = false;
                        createToast('Terjadi Kesalahan, Respon Status : ' + response.data.status,
                        {
                            type: 'warning',
                            timeout: 5000,
                            showIcon: 'true',
                            transition: 'bounce'
                        })
                    }
                    
                }).catch(error => {
                    sukses.value = false;
                    createToast('ERROR: ' + error.message,
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                    console.log('ERROR LOGIN : ', error.response)
                })
            }else{
                createToast(data_check.message,
                {
                    type: 'danger',
                    timeout: 5000,
                })
            }
            
        }

        
        return{
            form,
            router,
            failed,
            sukses,
            mulai,
            intro,
            resetPassword,
            checkpass
        }
    }
}
</script>

<style>
    /* ==== Login ===== */
    .login-header{
        height: 300px;
        position: relative;
        background: linear-gradient(180deg, rgba(255,255,255,0.9) 0%, rgba(241,241,241,1) 100%);
    }
    .login-header img.log-bg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0px;
        z-index: -1;
    }
    .login-header img.log-decor{
        /* width: 100px; */
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        bottom: 0px;
        z-index: 1;
    }
    .login-header img.log-decor-c{
        /* width: 100%; */
        height: 100%;
        object-fit: cover;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
    }
    .login-header .log-inner{
        position: relative;
        z-index: 2;
    }
    .login-header .log-inner .brand{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login-header .log-inner .brand img{
        width: 200px;
    }

    .intro{
        position: fixed;
        background: white;
        z-index: 999;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
    }
    .intro figure img{
        width: 22%;
    }
    .intro .slider{
        padding: 30px 0;
    }
    .intro .slider .swiper-pagination{
        margin: 0px 0;
    }
    .intro .slider .swiper-pagination .swiper-pagination-bullet-active{
        background: var(--primaryColor);
    }
    .intro .slider .b-next button.btn__primary{
        width: 20%;
        padding: 10px;
        margin: 20px 0;
    }

    .box-login{
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        margin-top: -150px;
        position: relative;
        z-index: 5;
        background: white;
        border-radius: 10px;
    }
    .nav-tabs .nav-item hr{
        display: none;
    }
    .nav-tabs .nav-item{
        width: 50%;
    }
    .nav-tabs .nav-item .nav-link.left{
        border-radius: 10px 0 0 0;
    }
    .nav-tabs .nav-item .nav-link.right{
        border-radius: 0 10px 0 0;
    }
    .nav-tabs .nav-item .nav-link{
        width: 100%;
        color: var(--darkGrey);
        font-weight: 600;
        text-transform: uppercase;
        padding: 15px 0;
        border: none;
        border-radius: 0;
    }
    .nav-tabs .nav-item .nav-link:hover{
        background: var(--blueGradient);
        color: var(--secondaryColor);
        font-weight: 600;
        text-transform: uppercase;
        padding: 15px 0;
        border: none;
    }
    .nav-tabs .nav-item .nav-link.active{
        background: var(--blueGradient);
        color: var(--secondaryColor);
        font-weight: 600;
        text-transform: uppercase;
        padding: 15px 0;
        border: none;
    }

    .tab-content{
        border: 1px solid #cfcfcf;
        border-radius: 0 0 10px 10px;
        margin-top: -1px;
        padding: 15px;
        transition: all 0.3s ease;
    }
    .tab-content:hover{
        border: 1px solid var(--primaryColor);
    }
    .form-group{
        position: relative;
    }
    .form-group h6{
        font-size: 10pt;
    }
    .form-group:focus-within .form-label{
        color: var(--primaryColor);
    }
    .form-label{
        font-size: 10pt;
        position: absolute;
        left: 15px;
        top: -10px;
    }
    .form-label span{
        background: white;
        padding: 0 5px;
    }
    .form-control{
        border-radius: 5px;
        padding: 10px;
    }
    .form-control:focus{
        box-shadow: unset !important;
        background: white;
        border: 1px solid var(--primaryColor) !important;
    }
    .form-control:-webkit-autofill{
        background: white;
        /* -webkit-box-shadow: 0 0 0 30px white inset !important; */
    }
    form .form-text{
        font-size: .675em;
    }
    form button.btn-primary{
        padding: 15px;
        width: 50%;
        border-radius: 10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 10pt;
        box-shadow: 0 5px 15px rgba(162, 36, 144, 0.3);
    }
    form .btn__primary{
        padding: 15px;
        width: 50%;
        border-radius: 10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 10pt;
        box-shadow: 0 5px 15px rgba(162, 36, 144, 0.3);
    }
    .error__text{
        font-size: 9pt;
    }
    /* ===== Login ===== */

    @media (max-width: 767.98px){
        .intro figure img{
            width: 80% !important;
        }
        .intro .slider{
            height: 85%;
        }
        .intro .slider .b-next button.btn__primary{
            width: 90% !important;
        }
    }
    @media (max-width: 575.98px){
        .login-header .log-inner .brand{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .login-header .log-inner .brand img{
            width: 70%;
        }

        .intro .slider{
            height: 97vh;
        }
        .intro figure img{
            width: 80% !important;
            margin: 30px 0;
        }
        .intro .slider swiper-slide{
            position: relative;
        }
        .intro .slider .b-next button.btn__primary{
            width: 90% !important;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }
        
        .box-login{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            max-height: 70%;
            height: 70%;
            border-radius: 30px;
        }
        .nav-tabs{
            border-bottom: unset !important;
        }
        .nav-tabs .nav-item .nav-link.left{
            border-radius: 50px 0 0 0;
        }
        .nav-tabs .nav-item .nav-link.right{
            border-radius: 0 50px 0 0;
        }
        .nav-tabs .nav-item .nav-link:hover{
            background: white;
            color: var(--primaryColor);
            font-weight: 600;
            text-transform: uppercase;
            padding: 15px 0;
            border: none;
        }
        .nav-tabs .nav-item hr{
            height: 4px;
            width: 20px;
            border-radius: 5px;
            background: white;
            display: block;
            margin: 0 auto 20px;
        }
        .nav-tabs .nav-item .nav-link:hover + hr,
        .nav-tabs .nav-item .nav-link.active + hr
        {
            background: var(--primaryColor);
        }
        .nav-tabs .nav-item .nav-link.active{
            background: white;
            color: var(--primaryColor);
            font-weight: 600;
            text-transform: uppercase;
            padding: 15px 0;
            border: none;
        }

        .tab-content,
        .tab-content:hover{
            border: none;
            max-height: 90%;
            overflow-y: scroll;
        }
        .tab-content .tab-pane{
            padding: 10px 0 30px;
        }
        form .btn__primary{
            width: 100%;
        }
    }
</style>