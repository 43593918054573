<template>
<Navbarc></Navbarc>
    <div class="">
        <div class="container">
            <h3 class="mt-5 mb-4 title"><b>Booking</b></h3>

            <div class="row loker-group">
                <div v-for="list in lokerList" :key="list.id" class="loker__link d-flex justify-content-center align-items-center">
                    <router-link :to="'/booking/loker-detail/'+list.locker_number_id" @click="storeDataLockerLaundry(list.number_code, list.locker_number_id, list.laundry_id)" :class="{'active':list.status=='Booking' || list.status=='Expired'}" class="loker__list d-flex justify-content-center align-items-center">
                        <div>
                            <h3 class="loker__text">
                                {{ list.number_code}}
                            </h3>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from "vue-router";

export default {
    name: 'Loker',
    components: {
        Navbarc,
    },
    setup(){
        const token = localStorage.getItem('token')
        const lokerList = ref([])
        const router = useRouter();
        const route = useRoute()
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        localStorage.setItem('schema_id', 1)

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`locker-number/${route.params.id}`)
			.then((result) => {
                console.log(result)
				lokerList.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        function storeDataLockerLaundry(id_loker, locker_number_id, id_laundry){
			localStorage.setItem('id_loker_scan', id_loker)
			localStorage.setItem('locker_number_id', locker_number_id)
			localStorage.setItem('laundry_id', id_laundry)
		}

        return{
			lokerList,
            token,
            storeDataLockerLaundry,
            router,
            role,
		}
    }
}
</script>

<style scoped>
    /* ===== Loker List ===== */
    .loker-group{
        margin: 0;
        padding: 0;
    }
    .loker-group .loker__link{
        text-decoration: none;
        color: white;
        width: 16.6%;
        height: 150px;
        padding: 5px;
        transition: all 0.3s ease;
    }
    .loker-group .loker__link:hover{
        opacity: 0.8;
        color: white;
    }
    .loker-group .loker__link .loker__list{
        width: 100%;
        height: 100%;
        background: url('../../assets/images/bg-locker-img.svg'), var(--blueGradient);
        background-size: cover;
        background-position-y: bottom;
        color: white;
        text-decoration: none;

    }
    .loker-group .loker__link .loker__list h3{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: bold;
        text-shadow: 0px 3px 10px rgba(0,0,0,0.2);
    }
    .loker-group .loker__link .loker__list.active{
        width: 100%;
        height: 100%;
        background: url('../../assets/images/icon/bubble.svg'), var(--darkGrey);
        background-size: cover;
        background-position-y: bottom; 

    }
    /* ===== Loker List ===== */
    @media (max-width: 768px){
        
        .loker-group .loker__link{
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100px;
            padding: 5px;
        }
    }
    @media (max-width: 576px){
        .loker-group .loker__link{
            text-decoration: none;
            color: white;
            width: 33%;
            height: 100px;
            padding: 5px;
        }
    }
</style>