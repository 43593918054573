<template>
<!-- <Navbarc></Navbarc> -->
    <div class="rating">
        <div class="container">
            <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
			<div class="head-box">
                <div class="row">
                    <div class="col-lg-6 last">
                        <h3 class="title">Hai, {{username}}!</h3>

                        <p class="desc">Beri tahu kami seberapa puas anda,</p>
                        <p class="desc">menggunakan aplikasi ini!</p>

                        <form id="form_rating">
                            <div class="rate">
                                <input type="radio" id="star5" name="rate" value="5" @click="showDescription()"/>
                                <label for="star5" title="text">5 stars</label>
                                <input type="radio" id="star4" name="rate" value="4" @click="showDescription()"/>
                                <label for="star4" title="text">4 stars</label>
                                <input type="radio" id="star3" name="rate" value="3" @click="showDescription()"/>
                                <label for="star3" title="text">3 stars</label>
                                <input type="radio" id="star2" name="rate" value="2" @click="showDescription()"/>
                                <label for="star2" title="text">2 stars</label>
                                <input type="radio" id="star1" name="rate" value="1" @click="showDescription()"/>
                                <label for="star1" title="text">1 star</label>
                            </div>
                            <br>
                            <div class="form-group" id="kotak_pesan" style="display: none;">
                                <textarea class="form-control" id="description" name="description" rows="3" placeholder="Masukan pesan anda"></textarea>
                            </div>
                        </form>
                        <br>
                        <div class="tombol">
                            <button class="btn btn__primary tbtn" @click="store()" id="button_rating">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-text me-2" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                                Kasi Rating
                            </button>
                        </div>
                        <div class="tombol">
                            <button class="btn btn__grey tbbtn" @click="gotoHome()">
                                Lain kali aja
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-6 first">
                        <figure class="image-rating">
                            <img src="../../assets/images/image-rating.svg" alt="">
                        </figure>
                    </div>
                </div>
            </div>
		</div>
    </div>
</template>

<script>
import axios from 'axios'
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from "vue-router";
// import Navbarc from '@/components/Navbarc.vue'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'Rating',
    components: {
        // Navbarc
    },
    setup(){
        const router = useRouter();
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const user = localStorage.getItem('user') ? localStorage.getItem('user') : 'invalid'
        const laundry_id = localStorage.getItem('laundry_id') ? localStorage.getItem('laundry_id') : 'invalid'
        const code = localStorage.getItem('locker_number_id') ? localStorage.getItem('locker_number_id') : 'invalid'
        const storeRating = reactive({
            laundry_id: laundry_id,
            rate: '',
            user_id: user,
            description: '-',
        });
        const isLoading = ref(false);

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            // isLoading.value = true;
            // Pengecekan apakah user udah pernah kasi rating apa belum kalau sudah redirect ke home
            // Disable dulu ya gan karena tiap kali selesai user diminta isi review
            // axios.defaults.headers.common.Authorization = `Bearer ${token}`
            // axios.get(`review/check/${user}`)
			// .then(result => {
            //     isLoading.value = false;
			// 	console.log(result)
            //     if (result.data.message != 'User belum memberikan ulasan.'){
            //         return router.push({
            //             name: "dashboard",
            //         });
            //     }
			// }).catch((err) => {
            //     isLoading.value = false;
			// 	console.log(err.response)
			// })
            // isLoading.value = false;
        })

        function showDescription() {
            var x = document.getElementById("kotak_pesan");
            if (x.style.display === "none") {
                x.style.display = "block";
            }
        }

        function gotoHome(){
            isLoading.value = false;
            var redirect_to = localStorage.getItem('redirect_after_rating') ? localStorage.getItem('redirect_after_rating') : '/'
            if(redirect_to == '/'){
                console.log("Laundry ID: ", laundry_id)
                console.log("redirect_to: ", redirect_to)
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.get(`booking/update-status-sudah-diambil-customer/${laundry_id}`)
                .then(result => {
                    console.log('Respon Update: ', result)
                    console.log('Respon perubahan status jadi sudah diambil: ', result.data.data)
                    return router.push({
                        name: 'dashboard',
                    })
                }).catch((err) => {
                    createToast('Ops.. Terjadi Kesalahan saat update status by customer. ERR MSG: ' + err.message,
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                    console.log(err.response)
                }) 
            }else{
                return router.push({
                    path: localStorage.getItem('redirect_after_rating') ? localStorage.getItem('redirect_after_rating') : '/',
                        // params: localStorage.getItem('redirect_after_rating') != '' ? code : ''
                });
            }
        }

        function store() {
            isLoading.value = true;
            document.getElementById("button_rating").disabled = true
            const rbs = document.querySelectorAll('input[name="rate"]');
            let selectedValue;
            for (const rb of rbs) {
                if (rb.checked) {
                    selectedValue = parseInt(rb.value);
                    break;
                }
            }
            selectedValue = selectedValue ? selectedValue : 0
            if(selectedValue > 0){
                // Apakah ada value yang di passing jika ada store ke BE
                storeRating.rate = selectedValue.toString()
                if(document.getElementById('description').value !== '')
                {
                    storeRating.description = document.getElementById('description').value
                }else{
                    storeRating.description = '-'
                }
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                axios.post("review/store", storeRating).then((response) => {
                    console.log('Response Store Rating: ', response);
                    if (response.data.status == "success") {
                        createToast('Terimakasih atas reviewnya, anda akan di alihkan ke halaman selanjutnya.',
                        {
                            type: 'success',
                            timeout: 5000,
                            showIcon: 'true',
                            transition: 'zoom'
                        })

                        setTimeout(function(){ 
                            isLoading.value = false;
                            var redirect_to = localStorage.getItem('redirect_after_rating') ? localStorage.getItem('redirect_after_rating') : '/'
                            if(redirect_to == '/'){
                                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                                axios.get(`booking/update-status-sudah-diambil-customer/${laundry_id}`)
                                .then(result => {
                                    console.log('Respon Update: ', response)
                                    console.log('Respon perubahan status jadi sudah diambil: ', result.data.data)
                                    return router.push({
                                        name: 'dashboard',
                                    })
                                }).catch((err) => {
                                    createToast('Ops.. Terjadi Kesalahan saat update status by customer. ERR MSG: ' + err.message,
                                    {
                                        type: 'warning',
                                        timeout: 5000,
                                        showIcon: 'true',
                                        transition: 'bounce'
                                    })
                                    console.log(err.response)
                                }) 
                            }else{
                                return router.push({
                                    path: localStorage.getItem('redirect_after_rating') ? localStorage.getItem('redirect_after_rating') : '/',
                                // params: localStorage.getItem('redirect_after_rating') != '' ? code : ''
                                });
                            }
                            
                        }, 7000);
                    }else{
                        isLoading.value = false;
                        createToast('Terjadi Kesalahan Sistem, ERR Code : ' + response.data.code,
                        {
                            type: 'warning',
                            timeout: 5000,
                            showIcon: 'true',
                            transition: 'zoom'
                        })
                    }
                })
                .catch((err) => {
                    isLoading.value = false;
                    console.log(err.response);
                    createToast('Terjadi Kesalahan Sistem, ERR: ' + err.message,
                    {
                        type: 'danger',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    }) 
                });
            }else{
                // kalau tidak ada value rating yang dikasi kirimkan notifikasi
                isLoading.value = false;
                createToast('Ops.. anda belum memilih rating!',
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
            }
            isLoading.value = false;
            document.getElementById("button_rating").disabled = false
            
        }

        return{
            token,
            username,
            store,
            storeRating,
            showDescription,
            isLoading,
            router,
            code,
            role,
            gotoHome,
        }
    }
}
</script>

<style scoped>
    h1.title{
		font-weight: 600;
		margin-bottom: 30px;
	}

	/* ===== Hello Text ====== */
	.rating .head-box{
		position: relative;
		background: #f2f2f2;
		border-radius: 10px;
		padding: 20px;
		margin: 50px 0 60px;
	}
	.rating .head-box .title{
		color: var(--darkBlue);
		font-weight: 800;
		margin-bottom: 20px;
        margin-top: 20px;
	}
	.rating .head-box .desc{
		color: var(--darkGrey);
		font-weight: 400;
		margin-bottom: 0;
	}
    .rating .head-box form{
        margin-top: 10px;
    }
    .rating .head-box form #kotak_pesan{
        margin-top: 60px;
        margin-bottom: -20px;
    }
    .rating .head-box .tombol{
        margin-top: 30px;
        display: block;
    }
    .rating .head-box .tombol button{
        width: 50%;
    }
    .rating .head-box .text{
        width: 50%;
    }
    .rating .head-box .text .text_link{
        
        color: var(--darkGrey);
        font-weight: 600;
        text-decoration: none;
        text-align: center !important;
        display: block;
        margin: 10px auto;
    }
    .rating .head-box .tombol .text_link:hover{
        color: var(--secondaryColor);
    }
    .rating .head-box figure.image-rating img{
        width: 80%;
        display: block;
        margin: 0 auto;
    }
	/* .rating .head-box figure{
		position: absolute;
		top: 5px;
		right: -20px;
	}
	.rating .head-box figure img{
		width: 250px;
		height: auto;
        object-position: right top;
	} */
	/* ===== Welcome Text ====== */
    *{
        margin: 0;
        padding: 0;
    }
    .rate {
        float: left;
        height: 46px;
        padding: 0 10px;
    }
    .rate:not(:checked) > input {
        position:absolute;
        top:-9999px;
    }
    .rate:not(:checked) > label {
        float:right;
        width:1em;
        overflow:hidden;
        white-space:nowrap;
        cursor:pointer;
        font-size:35pt;
        color:#ccc;
    }
    .rate:not(:checked) > label:before {
        content: '★ ';
    }
    .rate > input:checked ~ label {
        color: #ffad50;    
    }
    .rate:not(:checked) > label:hover,
    .rate:not(:checked) > label:hover ~ label {
        color: #ffad50; 
    }
    .rate > input:checked + label:hover,
    .rate > input:checked + label:hover ~ label,
    .rate > input:checked ~ label:hover,
    .rate > input:checked ~ label:hover ~ label,
    .rate > label:hover ~ input:checked ~ label {
        color: #ffad50;
    }
    .rating .head-box .first{
        order: 2;
    }
    .rating .head-box .last{
        order: 1;
    }

    @media (max-width: 767.98px){
        .rating .head-box .first{
            order: 1;
        }
        .rating .head-box .last{
            order: 2;
        }

        .rating .head-box .title{
            text-align: center;
            margin: 30px 0 20px;
        }
        .rating .head-box .desc{
            text-align: center;
        }

        .rating .head-box form{
            position: relative;
        }
        .rate{
            float: unset;
            position: absolute;
            top: 0;
            left: 40%;
            transform: translateX(-50%);
            width: 100%;
        }

        .rating .head-box .tombol button{
            width: 100%;
        }
        .rating .head-box .text{
            width: 100%;
        }
    }

</style>