<template>
    <div class="verifikasi">
        <div class="login-header p-3">
            <img src="../assets/images/top-right-l.svg" class="log-decor-c" alt="">
            <img src="../assets/images/bottom-left-l.svg" class="log-decor" alt="">
            <img src="../assets/images/bg-login.jpg" class="log-bg" alt="">
            <div class="container log-inner">
                <div class="brand">
                    <img src="../assets/images/WABO_Laundry_Tagline.png" class="img-log me-2 my-2" alt="">
                </div>
            </div>
        </div>

        <div class="container">
            <div class="card d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
                <div class="pt-3 pb-3 p-5">
                    <img v-if="type == 'berhasil'" src="../assets/images/verifikasi-berhasil.svg" alt="" class="img-verifikasi">
                    <img v-else src="../assets/images/verifikasi-gagal.svg" alt="" class="img-verifikasi">
                </div>
                <div class="title my-3" v-if="type == 'berhasil'">
                    <h5 class="text-center fw-bold">Verifikasi Berhasil</h5>
                    <h5 class="text-center">Hore, email kamu sudah berhasil terverifikasi~</h5>
                </div>

                <div class="title my-3" v-else>
                    <h5 class="text-center fw-bold">Verifikasi Gagal</h5>
                    <h5 class="text-center">Waduh, email kamu tidak berhasil terverifikasi, <br>silahkan melakukan verifikasi ulang~</h5>
                </div>
                <router-link :to="{name: 'auth'}" class="btn btn__primary">Kembali ke Aplikasi</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
    setup(){
        const route = useRoute();
        const type = ref(route.params.type);

        return {
            type
        }
    }
}
</script>
<style scoped>
    .verifikasi{
        position: relative;
        top: 0;
        left: 0;
        z-index: 1000 !important;
    }
    .verifikasi .card{
        border: none;
        margin-top: -120px;
        position: relative;
        z-index: 20;
    }
    .img-verifikasi{
        display: block;
        margin: 0 auto;
        max-width: 300px;
    }
    .login-header{
        height: 300px;
        position: relative;
        background: linear-gradient(180deg, rgba(255,255,255,0.9) 0%, rgba(241,241,241,1) 100%);
    }
    .login-header img.log-bg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0px;
        z-index: -1;
    }
    .login-header img.log-decor{
        /* width: 100px; */
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        bottom: 0px;
        z-index: 1;
    }
    .login-header img.log-decor-c{
        /* width: 100%; */
        height: 100%;
        object-fit: cover;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
    }
    .login-header .log-inner{
        position: relative;
        z-index: 2;
    }
    .login-header .log-inner .brand{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login-header .log-inner .brand img{
        width: 200px;
    }

    @media (max-width: 768px){
        .img-verifikasi{
            display: block;
            margin: 0 auto;
            max-width: 80%;
        }
    }
    @media (max-width: 575.98px){
        .login-header .log-inner .brand{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .login-header .log-inner .brand img{
            width: 70%;
        }
    }
</style>