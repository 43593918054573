<template>
<Navbarc></Navbarc>
	<div class="home">
		<div class="container">
			<div class="wellcome">
				<h3 class="title">Selamat Datang, {{profile.fullname}}!</h3>
				<p class="desc">Nikmati One Stop Laundry dalam genggaman anda!</p>
			</div>
			<!-- <div class="head-box">
				<h3 class="title">Selamat Datang, {{name}}!</h3>

				<p class="desc">Nikmati One Stop Laundry dalam genggaman anda!</p>

				<figure>
					<img src="../../assets/images/welcome-icon.svg" alt="">
				</figure>
			</div> -->

			<div class="banner">
				<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item" v-for="(banner, index) in banners" :key="index" :class="{ 'active': index === 0}">
							<img :src="imageUrl+banner.image" class="d-block w-100">
						</div>
					</div>
					<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
					</div>
			</div>

			<div class="row services">
				<label v-for="(service, index) in services" :key="index" class="service__link d-flex justify-content-center align-items-center">
					<!-- <form @submit.prevent="cartService()"> -->
						<!-- <input type="hidden" class="form-control" v-model="storeService.user_id" /> -->
						<!-- <input type="hidden" class="form-control" :value="service[index]" v-model="storeService.service_id"/> -->
						<input type="checkbox" name="service" :id="service[index]" :value="service.service_id" class="input_service">
						<span class="service__list" :for="service[index]">
							<figure class="icon">
								<img :src="imageUrl+service.image">
								<!-- <img src="../../assets/images/icon/service-cuci.svg" v-show="service.name.toLowerCase() == 'laundry' || service.name.toLowerCase() == 'cuci'" alt="">
								<img src="../../assets/images/icon/service-setrika.svg" v-show="service.name.toLowerCase() == 'setrika'" alt="">
								<img src="../../assets/images/icon/service-sepatu.svg" v-show="service.name.toLowerCase() == 'sepatu'" class="sepatu" alt="">
								<img src="../../assets/images/icon/service-tas.svg" v-show="service.name.toLowerCase() == 'tas'" alt="">
								<img src="../../assets/images/icon/service-helm.svg" v-show="service.name.toLowerCase() == 'helm'" alt="">
								<img src="../../assets/images/icon/service-boneka.svg" v-show="service.name.toLowerCase() == 'boneka'" alt=""> -->
							</figure>
							<div class="text">
								<h4 class="title">{{service.name}}</h4>
								<p class="sub_title" v-if="service.unit_name == 'kg' && service.price != null">Rp. {{formatPrice(service.price)}}/{{service.unit_name}}</p>
								<!-- <p class="sub_title" v-else-if="formatPrice(service.price)">Rp. {{formatPrice(service.price)}}/{{service.unit_name}}</p> -->
								<p class="sub_title" v-else>{{service.description}}</p>
								<!-- <p class="sub_title" v-else>checked</p> -->
							</div>
						</span>
					<!-- </form> -->
				</label>
			</div>

			<div class="row">
				<div class="sub_service">
					<button class="btn btn__primary option-btn" data_service="antar_jemput" @click="processService" :disabled="!services">
						Antar Jemput
					</button>
					<div class="dropdown">
						<button class="btn btn__primary dropdown-toggle" :disabled="!services" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
							Bawa Sendiri
						</button>
						<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
							<li><router-link class="dropdown-item" to="/coming-soon">
								<img src="../../assets/images/icon/locker.svg" alt="" class="loker">
								Loker
								</router-link>
							</li>
							<!-- <li><a class="dropdown-item" href="javascript:void(0)" data_service="loker" @click="processService">
								<img src="../../assets/images/icon/locker.svg" alt="" class="loker">
								Loker
								</a>
							</li> -->
							<li><a class="dropdown-item" href="javascript:void(0)" data_service="drop_point" @click="processService">
								<img src="../../assets/images/icon/droppoint.svg" alt="" class="drop_point">
								Drop Point
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="histories">
				<div class="h_header mb-2">
					<h5 class="h_title">Order On Going</h5>
					<div class="tombol">
						<button class="btn btn__primary refresh-btn" @click="refresh()">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
								<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
								<path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
							</svg>
							Refresh
						</button>
					</div>
				</div>
				<div class="h_body">
					<div class="table-responsive">
						<table class="table table-hover table-sm" id="histories">
							<thead>
								<tr class="align-middle">
									<th class="text-center">Aksi</th>
									<th class="text-center">Status</th>
									<th class="text-center">Layanan</th>
									<th class="text-center">Kode Nomor</th>
									<th class="text-center">Kode Laundry</th>
									<th class="text-center">Harga</th>
									<th class="text-center">Status Pembayaran</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="history == ''">
									<td colspan="7" class="text-center">Belum Ada Order Apapun</td>
								</tr>
								<tr v-for="riwayat in history" :key="riwayat.id">
									
									<td>
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											<button class="btn text-dark btnAmbil" @click="storeDataLockerLaundry(riwayat.number_code, riwayat.locker_number_id, riwayat.laundry_id, riwayat.laundry_code); modal.show()" :disabled="riwayat.payment_status == 'Belum Lunas'" v-if="riwayat.status === 'Siap Diambil'">
											<!-- <button class="btn text-dark btnAmbil" @click="storeDataLockerLaundry(riwayat.number_code, riwayat.locker_number_id, riwayat.laundry_id, riwayat.laundry_code); modal.show()" :disabled="riwayat.status !== 'Siap Diambil'" v-if="riwayat.status === 'Siap Diambil'"> -->
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
													<path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
												</svg>
												Ambil
											</button>
		
											<div v-if="riwayat.locker_number_id.toString() === '0'">
												<button class="btn btn__outline_secondary tbtn" :id="riwayat.qr_code" :disabled="riwayat.status !== 'Booking'" @click="storeDataLockerLaundry(riwayat.number_code, riwayat.locker_number_id, riwayat.laundry_id, riwayat.laundry_code); showQR($event)" v-if="riwayat.status === 'Booking'">
													<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" width="24" height="24" viewBox="0 0 20 20" fill="currentColor">
														<path fill-rule="evenodd" d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z" clip-rule="evenodd" />
														<path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z" />
													</svg>
													QR Code
												</button>
												<button class="btn btn__primary tbtn" @click="ambilAJLaundry()" v-if="riwayat.status === 'On The Way'">
													<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
														<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
													</svg>
													Scan
												</button>
											</div>
											<div v-else>
												<button class="btn btn__primary tbtn" @click="storeDataLockerLaundry(riwayat.number_code, riwayat.locker_number_id, riwayat.laundry_id, riwayat.laundry_code); modal2.show()" v-if="riwayat.status === 'Booking' || riwayat.status === 'Booked'">
													<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
														<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
													</svg>
													Scan
												</button>
											</div>
		
											<router-link :to="'/invoice/'+riwayat.laundry_id" v-if="riwayat.status === 'Packed' || riwayat.status === 'Selesai'" class="btn btn__primary tbtn">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
													<path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
													<path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
												</svg>
												Invoice
											</router-link>

											<router-link :to="'/laundry/detail/'+riwayat.laundry_id" class="btn btn__outline_primary tbtn">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
													<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
													<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
												</svg>
												Detail
											</router-link>

										</div>
									</td>
									<td class="text-center">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											<span class="badge bg-primary" v-if="riwayat.status == 'Process'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-warning text-dark" v-else-if="riwayat.status == 'Belum Lunas'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg__purple" v-else-if="riwayat.status == 'Selesai'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-warning text-dark" v-else-if="riwayat.status == 'Booking'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-success" v-else-if="riwayat.status == 'Booked'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-danger" v-else-if="riwayat.status == 'Cancel'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-danger" v-else-if="riwayat.status == 'Expired'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-info text-dark" v-else-if="riwayat.status == 'Pickup by Driver'">{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
											<span class="badge bg-success" v-else>{{riwayat.status}} {{riwayat.name ? riwayat.name : ''}}</span>
										</div>
									</td>
									<td class="text-center">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											{{riwayat.locker_name}}
										</div>
									</td>
									<td class="text-center">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											{{riwayat.number_code}}
										</div>
									</td>
									<td class="text-center">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											{{riwayat.laundry_code}}
										</div>
									</td>
									<td v-if="riwayat.price == null" class="text-center">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											-
										</div>
									</td>
									<td v-else-if="riwayat.price !== null" class="text-center">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											Rp. {{formatPrice(countPrice(riwayat.price, riwayat.delivery_cost ? riwayat.delivery_cost : 0))}}
										</div>
									</td>
									<td class="text-center" v-if="riwayat.payment_status == null">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											-
										</div>
									</td>
                                    <td class="text-center" v-else-if="riwayat.payment_status == 'Belum Lunas'">
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											<span class="badge bg-warning text-dark">{{riwayat.payment_status}}</span>
										</div>
									</td>
                                    <td class="text-center" v-else>
										<div v-if="riwayat.status !== 'Cancel' && riwayat.status !== 'Sudah Diambil'">
											<span class="badge bg-success">{{riwayat.payment_status}}</span>
										</div>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr class="align-middle">
									<th class="text-center">Aksi</th>
									<th class="text-center">Status</th>
									<th class="text-center">Layanan</th>
									<th class="text-center">Kode Nomor</th>
									<th class="text-center">Kode Laundry</th>
									<th class="text-center">Harga</th>
									<th class="text-center">Status Pembayaran</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>

			<div class="visit" v-for="item in visit" :key="item.id">
				<div class="visit_header">
					<div class="v_title">
						<p>Layanan Visit</p>
						<h5>{{item.name}}</h5>
					</div>
					<div class="link_wa">
						<a :href="'https://wa.me/'+item.no_phone" class="btn btn__outline_primary">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp me-2" viewBox="0 0 16 16">
								<path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
							</svg>
							Hubungi
						</a>
					</div>
				</div>
				<div class="visit_body">
					<p class="desc" v-html="item.desc"></p>
				</div>
			</div>
			
			<br>
			<!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="modalAmbil" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Apakah anda ingin ambil laundry sekarang?</h5>
                        <div class="text-center">
                            <button type="button" class="btn btn__primary px-5 mt-3" @click="scan()" v-on:click="modal.hide()">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
									<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
								</svg>
								Scan QrCode
							</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			<!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="modalPemberitahuan" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Silahkan menuju loker yang anda pesan.</h5>
                        <div class="text-center">
							<router-link :to="{name: 'scan', params: { number_code: id_loker_want_to_scan }}" @click="modal2.hide()" para class="btn btn__primary tbtn">
								Oke Mengerti
							</router-link>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			<!-- Modal QR -->
			<div class="modal fade" id="staticBackdrop" ref="modalQR" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-body">
							<div class="text-end">
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div class="text-center my-3">
								<figure>
									<img :src="imageUrl+qr_code" class="img__ambil" alt="">
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Modal Waiting -->
			<div class="modal fade" id="staticBackdrop" ref="modalWaiting" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-body">
							<!-- <div class="text-end">
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div> -->
							<div class="text-center my-3">
								<figure class="img__wait">
									<img src="../../assets/images/loading.gif" class="" alt="">
								</figure>
								<h5>Menunggu Kurir...</h5>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<!-- <div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="history">
						<div class="card">
						<div class="card-header">
							<h5 class="card-title">Riwayat booking Order</h5>
						</div>
						<div class="card-body">
							<div class="table-responsive">
								<table class="table table-hover table-sm" id="histories">
									<thead class="text-dark">
										<tr class="align-middle">
											<th class="text-center">Aksi</th>
											<th class="text-center">Status</th>
											<th class="text-center">Nama Loker</th>
											<th class="text-center">Kode Nomor</th>
											<th class="text-center">Kode Laundry</th>
											<th class="text-center">Harga</th>
											<th class="text-center">Status Pembayaran</th>
											
											
											
										</tr>
									</thead>
									<tbody>
										<tr v-for="riwayat in history" :key="riwayat.id">
											<td class="text-center">
												<div class="d-flex align-items-center">
													<button class="btn btn-success btnAmbil" @click="storeDataLockerLaundry(riwayat.number_code, riwayat.locker_number_id, riwayat.laundry_id, riwayat.laundry_code); modal.show()" :disabled="riwayat.status !== 'Selesai'" v-if="riwayat.status === 'Selesai'">
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
															<path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
														</svg>
														Ambil
													</button>

													<button class="btn btn__primary tbtn" @click="storeDataLockerLaundry(riwayat.number_code, riwayat.locker_number_id, riwayat.laundry_id, riwayat.laundry_code); modal2.show()" v-if="riwayat.status === 'Booking' || riwayat.status ==='Booked'">
														<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
															<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
														</svg>
														Scan
													</button>
													<router-link :to="'/invoice/'+riwayat.laundry_id" class="btn btn__primary tbtn" v-if="riwayat.status === 'Packed'">
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
															<path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
															<path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
														</svg>
														Invoice
													</router-link>
												</div>
											</td>

											<td class="text-center" v-if="riwayat.status == 'Proses'">
												<span class="badge bg-primary">{{riwayat.status}}</span>
											</td>
											<td class="text-center" v-else-if="riwayat.status == 'Belum Lunas'">
												<span class="badge bg-warning text-dark">{{riwayat.status}}</span>
											</td>
											<td class="text-center" v-else-if="riwayat.status == 'Selesai'">
												<span class="badge bg-light text-dark">{{riwayat.status}}</span>
											</td>
											<td class="text-center" v-else-if="riwayat.status == 'Booking'">
												<span class="badge bg-secondary">{{riwayat.status}}</span>
											</td>
											<td class="text-center" v-else-if="riwayat.status == 'Cancel'">
												<span class="badge bg-danger text-dark">{{riwayat.status}}</span>
											</td>
											<td class="text-center" v-else-if="riwayat.status == 'Expired'">
												<span class="badge bg-warning text-dark">{{riwayat.status}}</span>
											</td>
											<td class="text-center" v-else>
												<span class="badge bg-success">{{riwayat.status}}</span>
											</td>

											<td class="text-center">{{riwayat.locker_name}}</td>
											
											<td class="text-center">{{riwayat.number_code}}</td>

											<td class="text-center">{{riwayat.laundry_code}}</td>

											<td v-if="riwayat.price == null" class="text-center">-</td>
											<td v-else class="text-center">{{riwayat.price}}</td>

											

                                            <td class="text-center" v-if="riwayat.payment_status == null">-</td>
                                            <td class="text-center" v-else>{{riwayat.payment_status}}</td>


										</tr>
									</tbody>
									<tfoot class="text-dark">
										<tr class="align-middle">
											<th class="text-center">Aksi</th>
											<th class="text-center">Status</th>
											<th class="text-center">Nama Loker</th>
											<th class="text-center">Kode Nomor</th>
											<th class="text-center">Kode Laundry</th>
											<th class="text-center">Harga</th>
											<th class="text-center">Pembayaran</th>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			
            <div class="modal fade" id="staticBackdrop" ref="modalAmbil" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Apakah anda ingin ambil laundry sekarang?</h5>
                        <div class="text-center">
                            <button type="button" class="btn btn__primary px-5 mt-3" @click="scan()" v-on:click="modal.hide()">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
									<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
								</svg>
								Scan Loker
							</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			
            <div class="modal fade" id="staticBackdrop" ref="modalPemberitahuan" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Silahkan menuju loker yang anda pesan.</h5>
                        <div class="text-center">
							<router-link :to="{name: 'scan', params: { number_code: id_loker_want_to_scan }}" @click="modal2.hide()" para class="btn btn__primary tbtn">
								Oke Mengerti
							</router-link>
                        </div>
                    </div>
                </div>
                </div>
            </div>
		</div> -->
		
		<!-- Floating Button -->
		<div class="wa-btn">
			<a href="https://wa.me/+6285339086280" target="_blank">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
					<path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
				</svg>
			</a>
			<span class="tooltiptext">Hubungi kami</span>
		</div>
		<!-- Floating Button -->
	</div>
	<div v-for="item in history" :key="item.id">
		<div class="popup" :class="{ 'showPopup': item.courier_id == null && item.type_location == 'Antar Jemput' && item.status == 'Booking'}">
			<div class="inner">
				<div class="popup__content">
					<div class="text-center my-3">
						<figure class="img__wait">
							<img src="../../assets/images/loading.gif" class="" alt="">
						</figure>
						<h5>Menunggu Kurir...</h5>
						<h5>{{item.laundry_code}}</h5>
						<h5>{{item.locker_name}}</h5>

						
					</div>
					<div class="button text-center">
						<div class="alert alert-primary" role="alert">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
								<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
								<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
							</svg>
							Harap <b @click="refresh()"><u>refresh</u></b> setiap saat atau sebelum melakukan cancel booking
						</div>
						<button class="btn btn__outline_primary btn-cancel" @click="confirmCancelOrder(item.laundry_id)">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle me-2" viewBox="0 0 16 16">
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
							</svg>
							Cancel Order
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Navbarc from '@/components/Navbarc.vue'
import { onMounted, nextTick ,ref, reactive } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import 'jquery/dist/jquery.min.js'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

//Datatable Modules
import "datatables.net-bs5/js/dataTables.bootstrap5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import $ from 'jquery'

export default {
	name: 'Home',
	components: {
		Navbarc,
	},
	methods: {
		processService: function (event) {
			var jenis_service = event.currentTarget.getAttribute('data_service')
			localStorage.setItem('service_type', jenis_service)
			console.log("jenis_service: ", jenis_service)
			var list_data = []
			var markedCheckbox = document.getElementsByName('service');  
			for (var checkbox of markedCheckbox) {  
				if (checkbox.checked){
					list_data.push(checkbox.value);
				}				  
			}
			console.log("Data service yang di pilih: ", list_data);
			if(list_data.length > 0){
				this.storeService.service_id = list_data
				axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
				axios.post("cart-store", this.storeService)
				.then((response) => {
					console.log('Response Store Service: ',response);
					// jika sukses atau mereturn response uncheck pilihannya
					for (var checkbox of markedCheckbox) {  
						checkbox.checked = false;			  
					}
					// jika sukses juga maka masuk ke halaman lokasi loker dll (nanti isi disini)
					if(jenis_service == 'drop_point'){
						this.gotoBookingDropPoint()
					}else if(jenis_service == 'loker'){
						this.gotoBooking()
					}else{
						// Ke antar jemput ngab
						// console.log("goto antarjemput page")
						this.gotoAntarJemput()
					}
				})
				.catch((err) => {
					console.log(err.response);
					createToast('Terjadi kesalahan, ERR MSG: ' + err.response,
					{
						type: 'danger',
						timeout: 5000,
						showIcon: 'true',
						transition: 'zoom'
					})
				});
			}else{
				createToast('Ops sepertinya anda belum memilih layanan deh..',
				{
					type: 'warning',
					timeout: 5000,
					showIcon: 'true',
					transition: 'zoom'
				})
			}
			
		},
	},
	data: () => ({
        modal: null,
        modal2: null,
        modalW: null
    }),
	computed: {
		qrDrop() {
			// let that = this
			return this.history.filter(p => p.laundry_id == this.idLaundry);
			// return this.products.filter(p => p.id_kategori == that.active);
		}
	},
    mounted() {
        this.modal = new Modal(this.$refs.modalAmbil)
        this.modal2 = new Modal(this.$refs.modalPemberitahuan)
        this.modalW = new Modal(this.$refs.modalWaiting)
		localStorage.setItem('redirect_after_rating', '/')
		localStorage.removeItem('laundry_id')
		localStorage.removeItem('laundry_code')
		localStorage.removeItem('id_drop_point')
		localStorage.removeItem('name_drop_point')
		localStorage.removeItem('address_drop_point')
		localStorage.removeItem('locker_number_id')
		localStorage.removeItem('data_drop_point')
		localStorage.removeItem('service_type')
    },
	setup(){
		const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
		const name = localStorage.getItem('name')
		const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
		const history = ref([])
		const router = useRouter()
		const services = ref([])
		const checked = ref(false)
		const visit = ref([])
		const banners = ref([])
		const profile = ref([])
		console.log('ROLE: ', role)
		// pengecekan role jika yng mencoba akses adalah driver maka redirect ke halaman home utk driver
		
		const storeService = reactive({
			user_id: user,
			checked: 'checked',
			service_id: []
		})

		
		
		const qr_code = ref(null)
		
		const modal = ref(null);
		const modalQR = ref(null);
		// const modalW = ref(null);
		// const modalWaiting = ref(null);

		onMounted(() => {
			modal.value = new Modal(modalQR.value);
			// modalW.value = new Modal(modalWaiting.value);
			// modalW.value.show();
		})

		// const invoices = ref([])
		var id_loker_want_to_scan = localStorage.getItem('id_loker_scan') ? localStorage.getItem('id_loker_scan') : ''

		onMounted(() => {
			if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}else if(role == 'courier'){
				return router.push({
					path: '/home-kurir'
				})
			}

			axios.get('profile/'+(user))
            .then(response => {
                console.log('GET Profile', response.data.data)
                profile.value = response.data.data
                
            }).catch((err) => {
				if(err.response.data.status_code == 401)
				{
					localStorage.clear()
					router.push({ name: 'auth' });
				}
				console.log("Error Profile", err.response)
			})

			// if()

            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`cart/${user}`)
			.then((result) => {
                console.log('List Services: ',result)
				services.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})
		onMounted(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get('layanan-visit/all')
			.then((result) => {
                console.log('List Services Visit: ',result)
				visit.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})
		onMounted(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get('banner/all')
			.then((result) => {
                console.log('Banners: ',result)
				banners.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        axios.defaults.headers.common.Authorization = `Bearer ${token}`
		onMounted(() => {
			axios.get(`history-lockers/${user}`)
			// axios.get('history-lockers')
			.then(result => {
				console.log(result)
				history.value = result.data.data2
				getHistory()
			}).catch((err) => {
				console.log(err.response)
			})
		})

		function showQR(event){
			var targetId = event.currentTarget.id;
			qr_code.value = targetId
			modal.value.show()
		}

		function refresh(){
			window.location.reload();
		}

		function cartService(){
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
			axios.post("cart-store", storeService)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				console.log(err.response);
			});
		}

		const cancel = reactive({
			user_id: user,
			laundry_id: ''
		})

		function confirmCancelOrder(laundry_id){
			let formData = new FormData()
			formData.append('user_id', user)
			formData.append('laundry_id', laundry_id)
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
			axios.post("cancel-pickup", formData)
			.then((response) => {
				if(response.data.status == 'success')
				{
					console.log(response);
					createToast("Order Sudah berhasil dicancel!",
					{
						type: 'success',
						timeout: 5000,
						showIcon: 'true',
						transition: 'zoom'
					})
					window.location.reload()
				}else{
					createToast("Orderan anda mungkin sudah mendapatkan kurir, harap refresh lagi!",
					{
						type: 'success',
						timeout: 5000,
						showIcon: 'true',
						transition: 'zoom'
					})
				}
			})
			.catch((err) => {
				console.log(err.response);
				createToast("Orderan anda mungkin sudah mendapatkan kurir, harap refresh lagi!" + err.response.message,
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
			});
		}

		function getHistory(){
			nextTick(() => {
				$('#histories').DataTable({
					ordering:  false
				})
			})
		}

		function formatPrice(value){
			
			let val = (value/1)
			if(!isNaN(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))){
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			}
        }

		function countPrice(price, ongkir){
			var harga_utama = parseInt(price)
			var harga_ongkir = parseInt(ongkir)
			var total = harga_utama + harga_ongkir
			return total.toString()
		}
		

		// const price = computed(() => {
		// 	return formatPrice()
		// })
		// onMounted(() => {
		// 	axios.defaults.headers.common.Authorization = `Bearer ${token}`
		// 	axios.get(`invoice`)
		// 	// axios.get('history-lockers')
		// 	.then(result => {
		// 		console.log(result)
		// 		invoice.value = result.data.data
		// 	}).catch((err) => {
		// 		console.log(err.response)
		// 	})
		// })
		function scan(){
			return router.push({
				name: 'scanAmbil'
			})
		}
		function ambilAJLaundry(){
			return router.push({
				name: 'AntarJemputAmbil'
			})
		}

		function gotoBooking(){
			localStorage.setItem('schema_id', 1)
			return router.push({
				name: 'booking',
			})
		}

		function gotoBookingDropPoint(){
			localStorage.setItem('schema_id', 2)
			return router.push({
				name: 'bookingDropPoint',
			})
		}

		function gotoAntarJemput(){
			localStorage.setItem('schema_id', 2)
			return router.push({
				name: 'AntarJemput',
			})
		}

		function storeDataLockerLaundry(id_loker, locker_number_id, id_laundry, laundry_code){
			localStorage.setItem('id_loker_scan', id_loker)
			localStorage.setItem('locker_number_id', locker_number_id)
			localStorage.setItem('laundry_id', id_laundry)
			localStorage.setItem('laundry_code', laundry_code)
			localStorage.setItem('schema_id', 1)
			id_loker_want_to_scan = id_loker
		}

        const handlePayment = (event, laundry_id) => {
            const file = event.target.files;
            if(file == null && file.length == 0){
                return false;
            }
            
            const data = new FormData();
            data.append('payment_attachment', file[0]);
            data.append('laundry_id', laundry_id);
            
            axios.post('booking/payment-confirmation', data, {
                'Content-Type': 'multipart/form-data'
            }).then((response) => {
                const { data } = response;

                alert(data.message);
            }).catch((err) => {
                const { data } = err.response;

                alert(data.message);
            })
        };

		onMounted(() => {
			axios.interceptors.response.use( (response) => {
				// Return a successful response back to the calling service
				return response;
			}, (error) => {
				// Return any error which is not due to authentication back to the calling service
				// if (error.response.status == 401) {
				// 	// return new Promise((resolve, reject) => {
				// 	// 	reject(error);
				// 	// });
				// 	localStorage.removeItem('token')
				// 	// window.location.reload()
				// 	// return Promise.reject(error);
				// 	return router.push({
				// 		name: 'auth'
				// 	})
				// }
				if (error.response.status !== 401) {
					return new Promise((resolve, reject) => {
						reject(error);
					});
				}
				if (error.config.url == '/api/token/refresh' || error.response.message == 'Account is disabled.') {
      
					localStorage.clear();
					router.push({ name: 'auth' });

					return new Promise((resolve, reject) => {
						reject(error);
					});
				}
			})
		})

		return{
			history,
			router,
			token,
			user,
			name,
			services,
			// invoice,
			scan,
			gotoBooking,
			gotoBookingDropPoint,
			gotoAntarJemput,
			handlePayment,
			storeDataLockerLaundry,
			id_loker_want_to_scan,
			formatPrice,
			storeService,
			cartService,
			checked,
			showQR,
			modalQR,
			qr_code,
			role,
			refresh,
			visit,
			ambilAJLaundry,
			countPrice,
			banners,
			// cancelOrder,
			confirmCancelOrder,
			cancel,
			profile
			// modalW,
			// modalWaiting
		}
	}
}
</script>

<style scoped>
	.wa-btn{
		position: fixed;
		bottom: 18px;
		right: 18px;
		width: 60px;
		height: 60px;
		background: rgb(55, 146, 90);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
		transition: all ease-in-out 0.3s;
		z-index: 99;
	}
	.wa-btn .tooltiptext {
		visibility: none;
		width: 130px;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
		color: var(--secondaryColor);
		text-align: center;
		font-weight: 600;
		border-radius: 6px;
		padding: 5px;
		position: absolute;
		z-index: 1;
		top: 50%;
		right: 110%;
		transform: translateY(-50%);
	}
	.wa-btn .tooltiptext::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 100%;
		margin-top: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent transparent white;
	}
	.wa-btn:hover{
		background: rgb(48, 126, 77);
	}
	.wa-btn a{
		color: white;
	}
	.wa-btn a svg{
		width: 28px;
		height: 28px;
	}

	h1.title{
		font-weight: 600;
		margin-bottom: 30px;
		color: var(--secondaryColor);
		text-align: left;
	}

	/* ===== Popup Waiting ====== */
	.popup.showPopup{
		display: block;
	}
	.popup{
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		z-index: 9999;
		background: rgba(0, 0, 0, 0.2);
		overflow: hidden;
	}
	.popup .inner{
		background: white;
		position: absolute;
		bottom: -5px;
		left: 50%;
		width: 100%;
		height: 70vh;
		border-radius: 30px 30px 0 0;
		transform: translateX(-50%);
		box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.02);
	}
	.popup .inner .popup__content{
		margin-top: 50px;
	}
	.popup .inner .popup__content .button{
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		width: 80%;
		z-index: 3;
	}
	.popup .inner .popup__content .button .alert{
		padding: 5px;
		font-size: 10pt;
	}
	.popup .inner .popup__content .button .btn-cancel{
		width: 100%;
		/* color: white; */
		font-weight: 600;
		border-radius: 10px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.popup .inner .popup__content .img__wait{
		display: block;
		width: 100%;
		margin: 0 auto;
	}
	.popup .inner .popup__content .img__wait img{
		width: 100%;
	}
	/* ===== Popup Waiting ====== */

	/* ===== Welcome Text ====== */
	.home .wellcome{
		margin: 30px 10px 0;
	}
	.home .wellcome .title{
		color: var(--secondaryColor) !important;
		font-weight: 800;
		margin-bottom: 10px;
		text-align: left !important;
		font-size: 14pt;
	}
	.home .wellcome .desc{
		color: var(--darkGrey);
		font-weight: 400;
		text-align: left;
		font-size: 10pt;
	}

	.home .head-box{
		position: relative;
		background: #f2f2f2;
		border-radius: 10px;
		padding: 20px;
		margin: 50px 0 10px;
	}
	.home .head-box .title{
		color: var(--secondaryColor) !important;
		font-weight: 800;
		margin-bottom: 20px;
		margin-right: 260px;
		text-align: left !important;
	}
	.home .head-box .desc{
		color: var(--darkGrey);
		font-weight: 400;
		margin-bottom: 0;
		/* margin-right: 260px; */
	}
	.home .head-box figure{
		position: absolute;
		bottom: -15px;
		right: -20px;
	}
	.home .head-box figure img{
		width: 250px;
		height: auto;
	}
	/* ===== Welcome Text ====== */

	/* ===== Banner Promo ====== */
	/* ===== Banner Promo ====== */

	/* ===== Layanan ====== */
	.home .services{
        margin: 0 0 30px;
        padding: 0;
    }
    .home .services .service__link{
        text-decoration: none;
        color: white;
        width: 16.6%;
        height: 150px;
        padding: 10px;
        transition: all 0.3s ease;
		position: relative;
    }

	/* .home .services .service__link form{
		position: absolute;
		top: 0;
		left: 0;
	} */
	.home .services .service__link  input{
		opacity: 0;
		width: 1em;
		height: 1em;
		position: absolute;
		top: 0;
		left: 0;
	}
	/* .home .services .service__link  input:hover + .service__list{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(162, 36, 144, 0.6) !important;
	}
	.home .services .service__link  input:hover + .service__list figure.icon img{
		filter: brightness(100);
	}
	.home .services .service__link  input:hover + .service__list .text .title,
	.home .services .service__link  input:hover + .service__list .text .sub_title{
		color: white;
	} */
	.home .services .service__link  input:checked + .service__list{
		background: var(--darkBlueG) !important;
		color: white;
		box-shadow: 0 5px 15px rgba(162, 36, 144, 0.8) !important;
	}
	.home .services .service__link  input:checked + .service__list figure.icon img{
		filter: brightness(100);
	}
	.home .services .service__link  input:checked + .service__list .text .title,
	.home .services .service__link  input:checked + .service__list .text .sub_title{
		color: white;
	}
	
	/* .home .services .service__link:hover > .service__list{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.6);
	} */
	/* .home .services .service__link:hover > .service__list figure.icon img{
		filter: brightness(100);
	} */
	.home .services .service__link .service__list.checked{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(162, 36, 144, 0.8);
	}
    .home .services .service__link .service__list{
        width: 100%;
        height: 100%;
        color: white;
        text-decoration: none;
		background: var(--blueGradient);
		border-radius: 10px;
		box-shadow: 0 5px 15px rgba(162, 36, 144, 0.3) !important;
		position: relative;
		outline: unset;
		border: unset;
    }
	.home .services .service__link .service__list figure.icon{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60%;
		margin: 0;
	}
	/* .home .services .service__link .service__list{
		background: transparent;
		color: var(--secondaryColor);
		outline: unset;
		border: unset;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
	} */
	.home .services .service__link .service__list figure.icon img{
		height: 60%;
		width: auto;
		filter: brightness(100);
	}
	.home .services .service__link .service__list figure.icon img.sepatu{
		/* height: 60%; */
		width: 70%;
	}
	.home .services .service__link .service__list .text{
		text-align: center;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		padding: 5px;
		background: unset !important;
	}
	.home .services .service__link .service__list .text .title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 10pt;
		margin-bottom: 0;
		color: white !important;
	}
	.home .services .service__link .service__list .text .sub_title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 7pt;
		margin-bottom: 0;
		color: white !important;
	}
	/* .home .services{
		margin: 0 0 30px;
		display: flex;
		align-items: center;
	}
	.home .services .service-box{
		background: var(--blueGradient);
		height: 130px;
		width: 16.6%;
		border-radius: 10px;
		text-decoration: none;
		color: var(--secondaryColor);
		font-weight: 800;
		text-transform: uppercase;
		margin: 10px;
		position: relative;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.3);
		-moz-transition: all .3s ease-in-out;
		-webkit-transition: all .3s ease-in-out;
		-ms-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	.home .services .service-box:hover{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.6);
	}
	.home .services .service-box:hover > figure.icon img{
		filter: brightness(100);
	}
	.home .services .service-box figure.icon{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60%;
		margin: 0;
	}
	.home .services .service-box figure.icon img{
		height: 50%;
		width: auto;
		-moz-transition: all .2s ease-in-out;
		-webkit-transition: all .2s ease-in-out;
		-ms-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
	.home .services .service-box .text{
		text-align: center;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		padding: 5px;
	}
	.home .services .service-box .text .title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 12pt;
		margin-bottom: 0;
	}
	.home .services .service-box .text .sub_title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 9pt;
		margin-bottom: 0;
	} */
	/* ===== Layanan ====== */


	/* ===== Sub Layanan ====== */
	.sub_service{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-top: 1px solid var(--lightGrey);
		padding: 30px 0;
	}
	.sub_service .option-btn:disabled, .dropdown .btn:disabled{
		background: var(--lightGrey);
		color: var(--dark);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	}
	.sub_service .option-btn{
		margin: 10px;
		width: 48%;
	}
	.sub_service .dropdown{
		margin: 10px;
		width: 48%;
	}
	.sub_service .dropdown .btn{
		width: 100%;
	}
	.sub_service .dropdown .dropdown-menu{
		padding: 0;
		box-shadow: 0 5px 15px rgba(162, 36, 144, 0.3) !important;
	}
	.sub_service .dropdown .dropdown-menu li:focus .dropdown-item,
	.sub_service .dropdown .dropdown-menu li:hover .dropdown-item
	{
		background: var(--blueGradient) !important;
	}
	.sub_service .dropdown ul li .dropdown-item{
		display: flex;
		align-items: center;
		color: var(--secondaryColor);
		font-weight: 600;
		padding: 10px 20px;
	}
	.sub_service .dropdown ul li .dropdown-item img{
		width: 50px;
		margin-right: 10px;
	}
	.sub_service .dropdown ul li .dropdown-item img.drop_point{
		height: 40px;
	}
	/* ===== Sub Layanan ====== */


	/* ===== Layanan ====== */
	.histories{
		margin-bottom: 50px;
	}
	.histories .h_header{
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.histories .h_header .refresh-btn{
		padding: 5px 20px;
	}
	.histories .h_header .h_title{
		font-weight: 800;
		color: var(--secondaryColor);
	}

	.histories .h_body{
		position: relative;
	}
	.histories .h_body table thead tr th, 
	.histories .h_body table tfoot tr th,
	.histories .h_body table.dataTable thead tr th, 
	.histories .h_body table.dataTable tfoot tr th{
		border-bottom: 2px solid var(--primaryColor) !important;
		border-top: 2px solid var(--primaryColor) !important;
		/* background: var(--blueGradient); */
	}
	.histories .h_body table.dataTable tbody, thead,td, tfoot, th, tr{
		border-style: none !important;
	}
	.histories .h_body .table thead tr th,
	.histories .h_body .table tfoot tr th{
		color: var(--secondaryColor);
		padding: 10px 5px;
	}
	.histories .h_body .table tr td .tbtn{
		padding: 5px;
		font-size: 10pt;
		border-radius: 7px;
		margin: 5px;
		font-weight: 600;
	}
	.histories .h_body .table tr td .btnAmbil{
		font-size: 9pt;
		padding: 5px;
		margin: 5px;
		border-radius: 7px;
		background: #ffad50;
		font-weight: 600;
	}
	.dataTables_paginate .pagination .page-item.active .page-link{
		background: var(--blueGradient) !important;
		border-color: var(--primaryColor) !important;
		color: var(--secondaryColor) !important;
	}
	.dataTables_paginate .pagination .page-item .page-link{
		color: var(--primaryColor);
	}
	.dataTables_paginate .pagination .disabled .page-link{
		color: var(--darkGrey);
	}
	/* ===== Layanan ====== */


	/* ===== Layanan Visit ====== */
	.visit{
		margin-bottom: 30px;
	}
	.visit .visit_header{
		padding: 5px;
		/* border-bottom: 1px solid var(--lightGrey); */
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.visit .visit_header .v_title{
		color: var(--secondaryColor);
	}
	.visit .visit_header .v_title p{
		font-weight: 400;
		margin-bottom: 0;
		font-size: 11pt;
	}
	.visit .visit_header .v_title h5{
		font-weight: 800;
	}
	.visit .visit_header .link_wa .btn{
		background: white;
		color: var(--secondaryColor);
		border-color: var(--secondaryColor);
		padding: 8px;
		border-radius: 5px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10pt;
		margin-bottom: 5px;
		box-shadow: unset;
	}
	.visit .visit_header .link_wa .btn:hover{
		background: var(--secondaryColor);
		color: white;
		border-color: var(--secondaryColor);
	}

	.visit .visit_body{
		border: 1px solid var(--lightGrey);
		padding: 10px 15px;
		max-height: 100px;
		overflow-y: scroll;
	}
	.visit .visit_body .desc{
		font-size: 10pt;
	}

	/* .visit .visit_body .table{
		width: 100%;
	}
	.visit .visit_body .table tbody tr.visitor{
		width: 100%;
		border-bottom: 1px solid var(--lightGrey) !important;
	}
	.visit .visit_body .table tbody tr.visitor td.number{
		width: 10%;
		align-items: center;
	}
	.visit .visit_body .table tbody tr.visitor td{
		width: 50%;
		font-weight: 800;
	}
	.visit .visit_body .table tbody tr.visitor td .name{
		font-weight: 800;
	}
	.visit .visit_body .table tbody tr.visitor td .desc{
		font-weight: 300;
		font-size: 8pt;
		max-height: 60px;
		overflow-y: scroll;
	}
	.visit .visit_body .table tbody tr.visitor td.number .bg{
		background: var(--yellowG);
		color: var(--darkBlue);
		font-weight: 800;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		font-size: 10pt;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.visit .visit_body .table tbody tr.visitor td.link_wa{
		width: 35%;
	}
	.visit .visit_body .table tbody tr.visitor td.link_wa .btn{
		background: white;
		color: var(--secondaryColor);
		border-color: var(--secondaryColor);
		padding: 8px;
		border-radius: 5px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10pt;
		margin-bottom: 5px;
		box-shadow: unset;
	}
	.visit .visit_body .table tbody tr.visitor td.link_wa .btn:hover{
		background: var(--secondaryColor);
		color: white;
		border-color: var(--secondaryColor);
	} */
	/* ===== Layanan Visit ====== */

	/* ===== Popup Waiting ====== */
	.modal .img__wait{
		display: block;
		width: 80%;
		margin: 0 auto;
	}
	.modal .img__wait img{
		width: 100%;
	}
	/* ===== Popup Waiting ====== */

	
	@media (max-width: 768px){
		/* ===== Welcome ===== */
		.home .head-box{
			margin-left: 5px;
			margin-right: 5px;
			margin-bottom: 10px;
			padding: 10px;
		}
		.home .head-box .title{
			font-size: 12pt;
			margin-right: 110px;
		}
		.home .head-box .desc{
			font-size: 8pt;
			margin-right: 110px;
		}
		.home .head-box figure{
			width: 40%;
		}
		.home .head-box figure img{
			width: 100%;
			height: auto;
		}
		/* ===== Welcome ===== */

		/* ===== Banner ===== */
		.home .banner{
			margin: 20px 5px 30px;
		}
		.home .banner .carousel .carousel-inner{
			border-radius: 10px;
		}
		/* ===== Banner ===== */
		
		/* ===== Services ===== */
		.home .services .service__link{
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100px;
            padding: 5px;
        }
		.home .services .service__link  input:hover + .service__list{
			background: var(--blueGradient) !important;
			color: white;
			box-shadow: 0 5px 15px rgba(162, 36, 144, 0.3) !important;
		}
		.home .services .service__link  input:checked + .service__list{
			background: var(--darkBlueG) !important;
			color: white;
			box-shadow: 0 5px 15px rgba(162, 36, 144, 0.8) !important;
		}
		/* ===== Services ===== */

		/* ====== DataTables ======= */
		.histories table.dataTable{
			margin: 50px 0 !important;
		}
		.histories .dataTables_length{
			position: absolute;
			top: 0;
			left: 0;
		}
		.histories .dataTables_filter{
			position: absolute;
			top: 0;
			right: 0;
		}
		.histories .dataTables_info{
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.histories .dataTables_paginate{
			position: absolute;
			bottom: 0;
			right: 0;
		}
		/* ====== DataTables ======= */
    }
	@media (max-width: 576px){
		/* ===== Services ===== */
        .home .services .service__link{
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100px;
            padding: 5px;
        }
		.home .services .service__link .service__list .text{
			bottom: 5px !important;
		}
		.home .services .service__link .service__list .text .title{
			font-weight: 800;
			text-transform: uppercase;
			font-size: 7pt !important;
			margin-bottom: 0;
		}
		.home .services .service__link .service__list .text .sub_title{
			font-weight: 800;
			text-transform: uppercase;
			font-size: 5pt !important;
			margin-bottom: 0;
		}
		/* ===== Services ===== */

		/* ====== DataTables ======= */
		.histories table.dataTable{
			margin: 100px 0 !important;
		}
		.histories .dataTables_length{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		.histories .dataTables_filter{
			position: absolute;
			top: 50px;
			right: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		.histories .dataTables_info{
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		.histories .dataTables_paginate{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		/* ====== DataTables ======= */
    }
</style>
