<template>
  <Navbarc></Navbarc>
  <div class="loker-detail">
      <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="container">
      <h3 class="mt-5 mb-4 title">
         <center><b id="heading_name">ANTAR JEMPUT</b></center>
      </h3>
      
      <div class="loker__detail">
        <h6 class="text__title">Alamat Antar:</h6>
        <div class="loc">
          <p>{{ data_antar_jemput.address ? data_antar_jemput.address : '-' }}</p>
        </div>

        <h6 class="text__title">Jenis Layanan :</h6>
        <h3 class="text__name"><span v-for="service in services" :key="service.id"><b v-if="service.service_id == services[0].service_id">{{ service.service_name }}</b><b v-else>,&nbsp;{{ service.service_name }}</b></span></h3>
        <br />

        <div class="detail-cloth">
          <h5>Pakaian yang di Laundry</h5>

          <div class="table-responsive">
            <table class="table table-hover table-sm book-table">
              <thead>
                <tr>
                  <th class="jlayanan">Jenis Layanan</th>
                  <th class="jbarang">Jenis Barang</th>
                  <th class="text-center qty">Qty</th>
                  <!-- <th class="text-center berat">Berat</th> -->
                  <th class="text-center tambah">Tambah</th>
                </tr>
              </thead>
              <tbody id="list_service_data">
                
              </tbody>
              <hr>
            </table>
          </div>

          <div class="table-responsive">
            <table class="table table-hover table-sm book-table">
                <tbody id="data-order" class="align-middle"></tbody>
            </table>
          </div>
        <div class="mb-3">
            <label for="note" class="fw-bold">Catatan: </label>
            <textarea rows="2" class="form-control" id="note" placeholder="Masukan Catatan Anda untuk kami..."></textarea>
        </div>
            <div class="clothes">
            <div class="clothes-list">
              <div class="list">
                <h6>Ongkir (Rp.)</h6>
              </div>
              <div class="list-input">
                <input
                  type="number"
                  class="form-control estimasi"
                  id="ongkir-estimate"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="clothes">
            <div class="clothes-list">
              <div class="list">
                <h6>Estimasi Harga (Rp.)</h6>
              </div>
              <div class="list-input">
                <input
                  type="number"
                  class="form-control estimasi"
                  id="price-estimate"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>

        <div class="term">
          <h5 class="term__title text-center">Syarat & Ketentuan</h5>
          <p class="term__desc" v-html="termConditions.termsconditions"></p>
        </div>
        
        <!-- <button class="btn btn-primary" @click="showModal()">Modal</button> -->
        <div class="form-check text-start mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="checked"
            id="agree"
          />
          <label class="form-check-label" for="agree">
            Centang Persetujuan
          </label>
        </div>
        <div class="text-start">
          <!-- <router-link to="/scan" class="btn btn__primary">Booking</router-link> -->
          <form @submit.prevent="store()">
            <div class="mb-3">
              <input
                type="hidden"
                class="form-control"
                v-model="storeBook.locker_number_id"
              />
              <input
                type="hidden"
                class="form-control"
                v-model="storeBook.user_id"
              />
              <input type="hidden" class="form-control" v-model="storeBook.kg">
            </div>
            <button class="btn btn__primary" :disabled="!checked" id="button_booking">
              Booking
            </button>
          </form>
        </div>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="staticBackdrop"
        ref="modalScan"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <h2 class="text-center">
                Drop Point QR Code
              </h2>
              <h5 class="text-center">
                Berikut adalah QR Code Drop Point anda!
              </h5>
              <div class="text-center my-3">
                <img
                  :src="imageUrl+qr_code"
                  class="img__ambil"
                  alt=""
                />
              </div>
              
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn__primary px-5 mt-3"
                  @click="goToHome()"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbarc from "@/components/Navbarc.vue";

import axios from "axios";
import { onMounted, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/
export default {
  name: "AntarJemputDetail",
  components: {
    Navbarc,
  },
  
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(false);
    const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer' 
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const qr_code = localStorage.getItem("qr_code_d");
    localStorage.setItem('schema_id', 2);
    // const service_id = localStorage.getItem("service_id");
    let service_name = localStorage.getItem("service_name");
    const data_antar_jemput = {
          'latitude' : localStorage.getItem("antar_lat"),
          'longitude' : localStorage.getItem("antar_lng"),
          'ongkir' : localStorage.getItem("antar_ongkir"),
          'address' : localStorage.getItem("antar_address")
    }
    let list_service_selected = [];
    const locker_id = route.params.id;
    const storeBook = reactive({
    //   droppoint_id: localStorage.getItem("id_drop_point"),
      user_id: user,
      cloth_id: [],
      cloth_name: [],
      qty: [],
      unit_name: [],
      price: [],
      kg: 0,
      service_name: [],
      latitude: data_antar_jemput.latitude,
      longitude: data_antar_jemput.longitude,
      address: data_antar_jemput.address,
      phone_number: '',
      email: '',
      fullname: '',
      note: '',
      note_service : [],
      parfum : [],
    });

    const clothNewAdd = reactive({
      id: [],
      name: [],
      // max_qty: [],
      weight: [],
      price: [],
      qty: [],
      note_service : [],
      parfum : [],
    });

    const serviceNewAdd = reactive({
      unit_name: [],
      service_name: [],
      note_service : [],
      parfum : [],
    })

    const listOrderClothes = reactive({
      id: [],
      name: [],
      // max_qty: [],
      price: [],
      sub_price: [],
      sub_weight: [],
      qty: [],
      unit_name: [],
      service_name: [],
      service_id: [],
      note_service : [],
      parfum : [],
    });

    const booking = ref([]);
    const clothes = ref([]);
    const services = ref([]);
    const subservices = ref([]);
    const termConditions = ref([]);

    const checked = ref(false);
    const lockerFull = ref(false);
    const modal = ref(null);
    const modalScan = ref(null);

    onMounted(() => {
        isLoading.value = true
      if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
      modal.value = new Modal(modalScan.value);
    })

    onMounted(() => {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      axios.get('profile/'+(user))
      .then(response => {
          console.log('Response Profile',response)
          if(response.data.status == 'success'){
              var data = response.data.data
              Object.assign(storeBook,{
                  phone_number: data.phone_number ? data.phone_number : '0000000000',
                  email: data.email ? data.email : 'example@gmail.com',
                  fullname: data.fullname ? data.fullname : 'seseorang'
              });
          }else{
              try {
                  
                  Object.assign(storeBook,{
                      phone_number: '',
                      email: '',
                  });
              } catch (error) {
                  console.log("DATA DIRI ERROR MSG: ", error)
              }
          }
                
      }).catch((err) => {
        console.log(err.response)
      })
    })

    onMounted(() => {
        document.getElementById('ongkir-estimate').value = data_antar_jemput.ongkir
        var estimasi_harga = document.getElementById('price-estimate').value
        if(estimasi_harga == ''){
            document.getElementById('price-estimate').value = data_antar_jemput.ongkir
        }

      // Object.assign(storeBook,{
      //   // droppoint_id : localStorage.getItem("id_drop_point"),
      //   cloth_id : [],
      //   qty : [],
      //   price : [],
      //   unit_name : [],
      //   service_name : [],
      //   note_service : [],
      //   parfum : [],
      //   cloth_name : [],
      //   kg : 0,
      // });

      // Object.assign(clothNewAdd,{
      //   id : [],
      //   name : [],
      //   weight : [],
      //   price : [],
      //   qty : [],
      // });

      // Object.assign(serviceNewAdd,{
      //   unit_namen : [],
      //   service_name : [],
      //   note_service : [],
      //   parfum : [],
      // });

      // Object.assign(listOrderClothes, {
      //   id : [],
      //   name : [],
      //   price : [],
      //   sub_price : [],
      //   sub_weight : [],
      //   qty : [],
      //   unit_name : [],
      //   service_name : [],
      //   service_id : [],
      //   note_service : [],
      //   parfum : [],
      // });
      // console.log("MASUK ke clearing, listOrderClothes: ", listOrderClothes.qty)
      
    });

    onMounted(() => {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios
        .get("terms-conditions")
        .then((result) => {
          termConditions.value = result.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
    onMounted(() => {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios
        .get("service")
        .then((result) => {
          console.log(result);
          // termConditions.value = result.data.data
        })
        .catch((err) => {
          console.log(err.response);
        });
    });

    onMounted(() => {
      var html_list_service = ''
      // var data_clothes = []
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axios
        .get(`list-service-customer/${user}/3`)
        .then((result) => {
          if(result.data.data.length <= 0){
            createToast('Ops sepertinya anda belum memilih layanan deh..',
            {
              type: 'danger',
              timeout: 5000,
              showIcon: 'true',
              transition: 'zoom'
            })
          }
          console.log('Result List Service customer: ', result);
          services.value = result.data.data;
          var data = result.data.data
          console.log('Respon list service: ', data)
          var id_service_kilogram = 0;
          for(var i=0; i<data.length; i++){
            clothNewAdd.id[i] = "-"
            clothNewAdd.name[i] = "empty"
            // clothNewAdd.max_qty[i] = "-"
            clothNewAdd.price[i] = 0
            clothNewAdd.qty[i] = 0
            clothNewAdd.weight[i] = 0
            clothNewAdd.parfum[i] = "null"
            clothNewAdd.note_service[i] = "kosong"
            serviceNewAdd.unit_name[i] = "-"
            serviceNewAdd.service_name[i] = ""
            serviceNewAdd.note_service[i] = "tidak_ada"
            serviceNewAdd.parfum[i] = "null"
            list_service_selected.push(data[i].service_name)
            // var detail_service = data[i].service_detail
            if(data[i].is_active == 'active')
            {
              html_list_service += '<tr><td>'+data[i].service_name+'</td>'
              html_list_service += '<td colspan="1"><textarea type="text" class="form-control required input_nte" id="input-clothes['+data[i].service_id+']" placeholder="Cth : Baju 15 pcs , Celana 2 pcs, dst" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" data-jenisorder="kilogram" data-servicename="'+data[i].service_name+'" data-clothid="'+ id_service_kilogram +'" data-notes=""></textarea></td>'
              // id_service_kilogram = id_service_kilogram + 1;
              
              if(data[i].parfum == 'active'){
                html_list_service += '<td><select class="form-select" id="data_clothes_['+data[i].service_id+']">'
                // html_list_service += '<option value="-" selected disabled>Pilih Parfum</option>'
                html_list_service += '<option value="Tanpa Parfum" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" data-jenisorder="kilogram" data-servicename="'+data[i].service_name+'" data-clothid="'+ id_service_kilogram +'">Tanpa Parfum</option>'
                html_list_service += '<option value="Parfum Sedikit" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" data-jenisorder="kilogram" data-servicename="'+data[i].service_name+'" data-clothid="'+ id_service_kilogram +'">Parfum Sedikit</option>'
                html_list_service += '<option value="Parfum Banyak" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" data-jenisorder="kilogram" data-servicename="'+data[i].service_name+'" data-clothid="'+ id_service_kilogram +'">Parfum Banyak</option>'
                html_list_service += '</select></td>'
              }else{
                html_list_service += '<td><select class="form-select" id="data_clothes_['+data[i].service_id+']" style="display:none;">'
                html_list_service += '<option value="include" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" data-jenisorder="kilogram" data-servicename="'+data[i].service_name+'" data-clothid="'+ id_service_kilogram +'">Parfum Sedikit</option>'
                html_list_service += '</select></td>'
                // var hidden = document.getElementById('data_clothes_['+data[i].service_id+']');
                // hidden.style.visibility = 'hidden';
              }
            //   html_list_service += '<td><input type="hidden" class="form-control input_qty" id="input-qty['+data[i].service_id+']" :max="1" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" value="0"/></td>'
            } else{
              // html_list_service += '<tr><td>'+data[i].service_name+'</td><td><select id="input-clothes['+data[i].service_id+']" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" class="form-select select_clothes"><option selected value="">Pilih Barang</option>'
              html_list_service += '<tr><td>'+data[i].service_name+'</td><td><input id="input-clothes['+data[i].service_id+']" placeholder="Pilih Barang" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" class="form-control select_clothes" list="data_clothes_'+data[i].service_id+'" data-jenisorder="nonkilogram"><datalist id="data_clothes_'+data[i].service_id+'">'
              var service_detail = data[i].service_detail
              for(var j=0; j<service_detail.length; j++){
                html_list_service += '<option value="'+ service_detail[j].cloth_name +'" data-clothid="'+ service_detail[j].cloth_id +'" data-price="'+ service_detail[j].price +'" data-weight="'+ service_detail[j].weight +'" data-maxQty="'+ service_detail[j].limitation +'" data-unitname="'+ service_detail[j].unit_name +'" data-servicename="'+data[i].service_name+'" data-service="'+data[i].service_id+'" data-idxservice="'+i+'">' + service_detail[j].cloth_name + '</option>'
              }
              // html_list_service += '</select></td>'
              html_list_service += '</datalist></td>'
              html_list_service += '<td><input type="number" class="form-control input_qty" id="input-qty['+data[i].service_id+']" :max="1" data-service="'+data[i].service_id+'" data-idxservice="'+i+'"/></td>'
            }
            id_service_kilogram = id_service_kilogram + 1;
            html_list_service += '<td class="text-center"><button class="btn btn__primary button_add" id="button-tambah['+data[i].service_id+']" data-service="'+data[i].service_id+'" data-idxservice="'+i+'"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle button_add" viewBox="0 0 16 16" data-service="'+data[i].service_id+'" data-idxservice="'+i+'"><path class="button_add" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" data-service="'+data[i].service_id+'" data-idxservice="'+i+'"/><path class="button_add" d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" data-service="'+data[i].service_id+'" data-idxservice="'+i+'" /></svg></button></td></tr>'
          }
          document.getElementById('list_service_data').innerHTML = html_list_service
          console.log("All service: ",list_service_selected)
        })
        .catch((err) => {
          console.log('Error saat mengambil list service user: ', err.message);
          createToast('Ops terjadi kesalahan sistem ERROR: ' + err.message,
            {
              type: 'danger',
              timeout: 5000,
              showIcon: 'true',
              transition: 'zoom'
            })
        });
    });

    onMounted(() => {
      document.addEventListener("click", function (e) {
        if (e.target && e.target.classList.contains("remove-order")) {
          console.log(e);
          var id_cloth = e.target.getAttribute("data-id");
          var id_service = e.target.getAttribute("data-service");
          // this.removeClothes.bind(e,id_cloth);
          deleteData(id_cloth, id_service);
        }
      });

      document.addEventListener("change", function(e) {
        if (e.target && e.target.classList.contains('select_clothes')){
          var id_service = e.target.getAttribute("data-service")
          selectClothes(id_service);
        }
      });

      document.addEventListener("change", function(e) {
        if (e.target && e.target.classList.contains('input_qty')){
          var id_service = e.target.getAttribute("data-service")
          qtyClothes(id_service);
        }
      });

      document.addEventListener("keyup", function(e) {
        if (e.target && e.target.classList.contains('input_nte')){
          // var id_service = e.target.getAttribute("data-service")
          e.target.innerHTML = e.target.value
          e.target.setAttribute('data-notes', e.target.value);
          console.log("Keyup note: ", e.target.value)
        }
      });

      document.addEventListener("click", function(e) {
        if (e.target && e.target.classList.contains('button_add')){
          var id_service = e.target.getAttribute("data-service")
          addClothes(id_service);
        }
      });
      isLoading.value = false
    });

    function getUnique(array){
        var uniqueArray = [];
        
        // Loop through array values
        for(var i=0; i < array.length; i++){
            if(uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    function formatPrice(value){
        let val = (value/1)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    function draw_chart(list_ordernya){
      var service_list = getUnique(list_ordernya.service_name)
      console.log("service_list: ", service_list)
      console.log("list order note: ", list_ordernya.note_service)
      console.log("length id list order cloth: ", list_ordernya.id.length)
      console.log("ISI listOrderClothes: ", list_ordernya)
      var html_table = '';
      var i = 0;
      var index_service_kilogram = [];
      for (i = 0; i< service_list.length; i++){
          // html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[i]+'</center></h5></th></tr><tr><th style="text-align: center; border: 1px solid black;">Jenis Barang</th><th class="text-center" style="border: 1px solid black;">Qty</th><th class="text-center" style="border: 1px solid black;">Harga/pcs</th><th class="text-center" style="border: 1px solid black;">Total Harga</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
        // if(list_ordernya.note_service[j] == '-' && service_list[i]){
        //   html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[i]+'</center></h5></th></tr><tr><th style="text-align: center; border: 1px solid black;">Jenis Barang</th><th class="text-center" style="border: 1px solid black;">Qty</th><th class="text-center" style="border: 1px solid black;">Harga/pcs</th><th class="text-center" style="border: 1px solid black;">Total Harga</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
        // }
        // else{
        //   html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[i]+'</center></h5></th></tr><tr><th style="text-align: center; border: 1px solid black;" colspan="4">Catatan</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
        // }
        for (var j = 0; j<list_ordernya.id.length; j++){
          console.log("NOTE SERVICE: ", list_ordernya.note_service[j])
          if(list_ordernya.note_service[j] == "kosong" || list_ordernya.note_service[j] == "tidak_ada"){
            if(service_list[i] == list_ordernya.service_name[j]){
              html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[i]+'</center></h5></th></tr><tr><th style="text-align: center; border: 1px solid black;">Jenis Barang</th><th class="text-center" style="border: 1px solid black;">Qty</th><th class="text-center" style="border: 1px solid black;">Harga/pcs</th><th class="text-center" style="border: 1px solid black;">Total Harga</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
              html_table += '<tr><td style="text-align: center; border: 1px solid black;">' +
              list_ordernya.name[j] +
              '</td><td style="text-align: center; border: 1px solid black;">' +
              list_ordernya.qty[j] +
              '</td><td style="text-align: center; border: 1px solid black;">Rp. ' +
              formatPrice(list_ordernya.price[j]) +
              '</td><td style="text-align: center; border: 1px solid black;">Rp. ' +
              formatPrice(list_ordernya.price[j] * list_ordernya.qty[j]) +
              '</td><td style="text-align: center; vertical-align: middle; border: 1px solid black;"><button class="btn btn__danger remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'"><svg class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" xmlns="http://www.w3.org/2000/svg" width="17" height="18.667" viewBox="0 0 17 18.667"><g class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" id="icon-trash" transform="translate(-3.5 -2)"><path class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" id="Path_962" data-name="Path 962" d="M4.5,9h15" transform="translate(0 -2.667)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" id="Path_963" data-name="Path 963" d="M19.167,6.333V18A1.667,1.667,0,0,1,17.5,19.667H9.167A1.667,1.667,0,0,1,7.5,18V6.333m2.5,0V4.667A1.667,1.667,0,0,1,11.667,3H15a1.667,1.667,0,0,1,1.667,1.667V6.333" transform="translate(-1.333)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg></button></td></tr>';
              }
          }else{
            if(index_service_kilogram.includes(j) == false){
              console.log("Layanan Kilo: ", service_list[j])
              html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[j]+'</center></h5></th></tr><tr><th colspan="3" style="text-align: center; border: 1px solid black;">Note</th><th class="text-center" style="border: 1px solid black;">Parfum</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
              index_service_kilogram.push(j);
              html_table += '<tr><td style="text-align: center; border: 1px solid black;" colspan="3">' + list_ordernya.note_service[j] + '</td><td style="text-align: center; border: 1px solid black;">'+ list_ordernya.parfum[j] +'</td><td style="text-align: center; vertical-align: middle; border: 1px solid black;"><button class="btn btn__danger remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'"><svg class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" xmlns="http://www.w3.org/2000/svg" width="17" height="18.667" viewBox="0 0 17 18.667"><g class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" id="icon-trash" transform="translate(-3.5 -2)"><path class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" id="Path_962" data-name="Path 962" d="M4.5,9h15" transform="translate(0 -2.667)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path class="remove-order" data-id="' + list_ordernya.id[j] +'" data-service="'+list_ordernya.service_id[j]+'" id="Path_963" data-name="Path 963" d="M19.167,6.333V18A1.667,1.667,0,0,1,17.5,19.667H9.167A1.667,1.667,0,0,1,7.5,18V6.333m2.5,0V4.667A1.667,1.667,0,0,1,11.667,3H15a1.667,1.667,0,0,1,1.667,1.667V6.333" transform="translate(-1.333)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg></button></td></tr>';
            }
          }
          
        }
        html_table += '</tbody></table></div>'
      }
      // for (var h = 0; h< service_list.length; h++){
      //   html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[h]+'</center></h5></th></tr><tr><th style="text-align: center; border: 1px solid black;" colspan="4">Catatan</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
      //   for (var k = 0; k<listOrderClothes.id.length; k++){
      //     console.log("NOTE SERVICE: ", listOrderClothes.note_service[k])
      //     if(index_service_kilogram.includes(k) == false && listOrderClothes.note_service[k] !== "-"){
      //       console.log("Layanan Kilo: ", service_list[k])
      //       index_service_kilogram.push(k);
      //       // html_table += '<div class="table-responsive"><table class="table table-hover table-sm book-table" style="border-collapse: collapse; border: 1px solid black;"><hr><thead><tr><th colspan="5" style="border: 1px solid black;"><center><h5>'+service_list[j]+'</center></h5></th></tr><tr><th colspan="3" style="text-align: center; border: 1px solid black;">Note</th><th class="text-center" style="border: 1px solid black;">Parfum</th><th class="text-center" style="border: 1px solid black;">Hapus</th></tr></thead><tbody>'
      //       html_table += '<tr><td style="text-align: center; border: 1px solid black;" colspan="3">' + listOrderClothes.note_service[k] + '</td><td style="text-align: center; border: 1px solid black;">'+ listOrderClothes.parfum[k] +'</td><td style="text-align: center; vertical-align: middle; border: 1px solid black;"><button class="btn btn__danger remove-order" data-id="' + listOrderClothes.id[k] +'" data-service="'+listOrderClothes.service_id[k]+'"><svg class="remove-order" data-id="' + listOrderClothes.id[k] +'" data-service="'+listOrderClothes.service_id[k]+'" xmlns="http://www.w3.org/2000/svg" width="17" height="18.667" viewBox="0 0 17 18.667"><g class="remove-order" data-id="' + listOrderClothes.id[k] +'" data-service="'+listOrderClothes.service_id[k]+'" id="icon-trash" transform="translate(-3.5 -2)"><path class="remove-order" data-id="' + listOrderClothes.id[k] +'" data-service="'+listOrderClothes.service_id[k]+'" id="Path_962" data-name="Path 962" d="M4.5,9h15" transform="translate(0 -2.667)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path class="remove-order" data-id="' + listOrderClothes.id[k] +'" data-service="'+listOrderClothes.service_id[k]+'" id="Path_963" data-name="Path 963" d="M19.167,6.333V18A1.667,1.667,0,0,1,17.5,19.667H9.167A1.667,1.667,0,0,1,7.5,18V6.333m2.5,0V4.667A1.667,1.667,0,0,1,11.667,3H15a1.667,1.667,0,0,1,1.667,1.667V6.333" transform="translate(-1.333)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg></button></td></tr>';
      //     }
      //   }
      //   html_table += '</tbody></table></div>'
      // }
      return html_table
    }

    function addClothes(id) {
      console.log("Masuk Function!")
      const reducer = (accumulator, curr) => (accumulator ? accumulator : 0)  + (curr ? curr : 0);
      var elementButtonAdd = document.getElementById('button-tambah['+id+']');
      var elementQty = document.getElementById('input-qty['+id+']');
      // var elementNote = document.getElementById('input-note['+id+']');
      var elementCloth = document.getElementById('input-clothes['+id+']');
      if(elementCloth){
        console.log("Element Cloth: ", elementCloth)
        console.log("Value : ", elementCloth.innerHTML)
      }else{
        console.log("Element Cloth: tidak ditemukan")
        console.log("Value : ", elementCloth.innerHTML)
      }
      var elementParfum = document.getElementById('data_clothes_['+id+']');
      var jenis_order = elementCloth.getAttribute("data-jenisorder");
      var data_note_kilo = elementCloth.getAttribute("data-notes");
      var idx_service = parseInt(elementButtonAdd.getAttribute("data-idxservice")) ? parseInt(elementButtonAdd.getAttribute("data-idxservice")) : 0;
      console.log("IDX SERVICENYA: ", idx_service)
    //   jika jenis order kilogram maka byepass utk tiap" field yang seharusnya pakai input qty dan input item
      if(jenis_order === 'kilogram'){
        console.log("data-clothid di jenis order kilogram : ", elementCloth.getAttribute("data-clothid"))
        clothNewAdd.id[idx_service] = elementCloth.getAttribute("data-clothid") ? ("K" + elementCloth.getAttribute("data-clothid")) : "0";
        clothNewAdd.price[idx_service] = 0;
        clothNewAdd.weight[idx_service] = 0;
        clothNewAdd.qty[idx_service] = 1;
        clothNewAdd.name[idx_service] = "empty";
        clothNewAdd.note_service[idx_service] = data_note_kilo;
        clothNewAdd.parfum[idx_service] = elementParfum.value;
        
        // clothNewAdd.max_qty[idx_service] = cloth_maxqty;
        // clothNewAdd.name[idx_service] = elementCloth.getAttribute("data-servicename") ? elementCloth.getAttribute("data-servicename") : "";
        
        serviceNewAdd.unit_name[idx_service] = "Kilogram";
        serviceNewAdd.service_name[idx_service] = elementCloth.getAttribute("data-servicename") ? elementCloth.getAttribute("data-servicename") : "Service Kilogram";
        console.log("Jenis order kilogram, value note: ", data_note_kilo);
        serviceNewAdd.note_service[idx_service] = data_note_kilo;
        serviceNewAdd.parfum[idx_service] = elementParfum.value;
        console.log("Jenis order kilogram, value Parfum: ", elementParfum.value);

      }else{
        console.log("Jenis Order bukan kilogram")
        const dataList = document.getElementById('data_clothes_'+id);
        const textInput = document.getElementById('input-clothes['+id+']');
        var cloth_price = 0;
        var cloth_id = "";
        var cloth_unit_name = ""
        // var service_id = ""
        var service_name = ""
        var weight = 0

        const getSelecteOptionLocation = () => {
          for (let i = 0; i < dataList.options.length; i++) {
            if (dataList.options[i].value === textInput.value) {
              return dataList.options[i];
            }
          }
        }
        const selectedOption = getSelecteOptionLocation();

        // var elementSelect = document.getElementById('input-clothes['+id+']');
        var value_select = textInput.value;

        if (selectedOption) {
          // cloth_maxqty = elementSelect.options[elementSelect.selectedIndex].getAttribute("data-maxqty") ? elementSelect.options[elementSelect.selectedIndex].getAttribute("data-maxqty") : 0;
          cloth_price = selectedOption.getAttribute("data-price") ? selectedOption.getAttribute("data-price") : 0;
          cloth_id = selectedOption.getAttribute("data-clothid") ? selectedOption.getAttribute("data-clothid") : "";
          cloth_unit_name = selectedOption.getAttribute("data-unitname") ? selectedOption.getAttribute("data-unitname") : "";
          // service_id = elementSelect.options[elementSelect.selectedIndex].getAttribute("data-service") ? elementSelect.options[elementSelect.selectedIndex].getAttribute("data-service") : "";
          service_name = selectedOption.getAttribute("data-servicename") ? selectedOption.getAttribute("data-servicename") : "";
          weight = selectedOption.getAttribute("data-weight") ? parseFloat(selectedOption.getAttribute("data-weight")) : 0;
          
          clothNewAdd.id[idx_service] = cloth_id;
          // clothNewAdd.max_qty[idx_service] = cloth_maxqty;
          clothNewAdd.price[idx_service] = cloth_price;
          clothNewAdd.name[idx_service] = value_select;
          clothNewAdd.weight[idx_service] = weight;
          
          serviceNewAdd.unit_name[idx_service] = cloth_unit_name;
          serviceNewAdd.service_name[idx_service] = service_name;

          serviceNewAdd.note_service[idx_service] = "tidak_ada";
          serviceNewAdd.parfum[idx_service] = "null";
          clothNewAdd.note_service[idx_service] = "kosong";
          clothNewAdd.parfum[idx_service] = "null";
          console.log("ISI clothNewAdd @@@@: ", clothNewAdd);
          console.log("data cloth["+idx_service+"] => ", value_select, cloth_price, cloth_id);
        }else{
          console.log("Oh, no.., selectedIndex is <= 0 ");
        }


      }
      if(id){
        console.log("Masuk IF #1!")
        // listOrderClothes.note_service.push(elementNote.value);

        // console.log("Note Value is: ",listOrderClothes.note_service)
        console.log("Service Name: ",serviceNewAdd.service_name[idx_service])
        // Cek data apakah data sudah benar di input atau tidak
        if (clothNewAdd.qty[idx_service] > 0 && serviceNewAdd.service_name[idx_service] && serviceNewAdd.service_name[idx_service] !== '-' && serviceNewAdd.unit_name[idx_service]) {
          var data_list_html = "";
          console.log("Masuk IF #2!")
          // cek jika data yang dikirim sudah ada di dalam data list order, jika ada maka update datanya
          if (listOrderClothes.id.includes(clothNewAdd.id[idx_service])) {
            console.log("Masuk ke Update Data ke listOrderClothes")
            var index_update = listOrderClothes.id.indexOf(
              clothNewAdd.id[idx_service]
            );
            listOrderClothes.qty[index_update] = listOrderClothes.qty[index_update] + clothNewAdd.qty[idx_service];
            listOrderClothes.sub_price[index_update] = clothNewAdd.price[idx_service] * listOrderClothes.qty[index_update];
            listOrderClothes.sub_weight[index_update] = clothNewAdd.weight[idx_service] * listOrderClothes.qty[index_update];
            listOrderClothes.unit_name[index_update] = serviceNewAdd.unit_name[idx_service]
            listOrderClothes.service_name[index_update] = serviceNewAdd.service_name[idx_service]
            listOrderClothes.note_service[index_update] = serviceNewAdd.note_service[idx_service]
            listOrderClothes.parfum[index_update] = serviceNewAdd.parfum[idx_service]
            listOrderClothes.service_id[index_update] = id
            console.log("Price clothNewAdd : ",clothNewAdd.price[idx_service])
            console.log("Qty clothNewAdd : ",clothNewAdd.qty[idx_service])
            console.log("Price list order idx update: ",listOrderClothes.sub_price[index_update])
            console.log("Qty list order idx update: ",listOrderClothes.qty[index_update])
          } else {
            console.log("Masuk ke insert Data ke listOrderClothes")
            console.log("ISI clothNewAdd: ", clothNewAdd)
            console.log("ISI NAME Before ALL: ", listOrderClothes.name)
            console.log("ISI NAME push: ", clothNewAdd.name[idx_service])
            console.log("ISI listOrderClothes before: ", listOrderClothes)
            listOrderClothes.id.push(clothNewAdd.id[idx_service]);
            listOrderClothes.name.push(clothNewAdd.name[idx_service] != "kosong" ? clothNewAdd.name[idx_service] : "kosong");
            // listOrderClothes.max_qty.push(clothNewAdd.max_qty[idx_service]);
            listOrderClothes.price.push((jenis_order == 'kilogram') ? 'no_data' : clothNewAdd.price[idx_service]);
            listOrderClothes.qty.push(clothNewAdd.qty[idx_service]);
            listOrderClothes.sub_price.push(clothNewAdd.price[idx_service] * clothNewAdd.qty[idx_service]);
            listOrderClothes.sub_weight.push(clothNewAdd.weight[idx_service] * clothNewAdd.qty[idx_service]);
            listOrderClothes.unit_name.push(serviceNewAdd.unit_name[idx_service]);
            listOrderClothes.service_name.push(serviceNewAdd.service_name[idx_service]);
            console.log("Data note_service sebelum: ", listOrderClothes.note_service)
            console.log("PUSH note_service: ", serviceNewAdd.note_service[idx_service])
            listOrderClothes.note_service.push(serviceNewAdd.note_service[idx_service] !== "tidak_ada" ? serviceNewAdd.note_service[idx_service] : "tidak_ada");
            console.log("=> Data Parfum sebelum: ", listOrderClothes.parfum)
            console.log("=> PUSH Parfum: ", serviceNewAdd.parfum[idx_service])
            listOrderClothes.parfum.push(serviceNewAdd.parfum[idx_service]);
            listOrderClothes.service_id.push(id);
            console.log("Data note_service sesudah: ", listOrderClothes.note_service)
            console.log("=> Data Parfum sesudah: ", listOrderClothes.parfum)
            elementCloth.value = "";
            console.log("ISI listOrderClothes after: ", listOrderClothes)
          }
          if (listOrderClothes.id.length) {
            data_list_html = draw_chart(listOrderClothes);
            // data_list_html = draw_chart2(listOrderClothes);
            // console.log("data_list_html: ", data_list_html);
            
            // if(listOrderClothes.note_service !== ""){
              for (var j = 0; j < storeBook.cloth_id.length; j++) {
                if(storeBook.cloth_id[j] <= 0){
                  storeBook.cloth_id.splice(j,1)
                  console.log("masuk")
                }
              }
              for (var i = 0; i < storeBook.cloth_name.length; i++) {
                if(storeBook.cloth_name[i] == "kosong"){
                  storeBook.cloth_name.splice(i,1)
                  console.log("masuk")
                }
              }
              // for (var h = 0; h < storeBook.unit_name.length; h++) {
              //   if(storeBook.unit_name[h] == "-"){
              //     storeBook.unit_name.splice(h,1)
              //     console.log("masuk")
              //   }
              // }
              for (var k = 0; k < storeBook.note_service.length; k++) {
                if(storeBook.note_service[k] == "kosong"){
                  storeBook.note_service.splice(k,1)
                  console.log("masuk")
                }
              }
              for (var l = 0; l < storeBook.parfum.length; l++) {
                if(storeBook.parfum[l] == "kosong"){
                  storeBook.parfum.splice(l,1)
                  console.log("masuk")
                }
              }
              for (var m = 0; m < storeBook.price.length; m++) {
                if(storeBook.price[m] == 0){
                  storeBook.price.splice(m,1)
                  console.log("masuk")
                }
              }
              for (var n = 0; n < storeBook.qty.length; n++) {
                if(storeBook.qty[n] == 0){
                  storeBook.qty.splice(n,1)
                  console.log("masuk")
                }
              }

              storeBook.note_service = listOrderClothes.note_service;
              storeBook.parfum = listOrderClothes.parfum;
              storeBook.service_name = listOrderClothes.service_name;
            // }else{
              storeBook.cloth_id = listOrderClothes.id;
              storeBook.qty = listOrderClothes.qty;
              storeBook.price = listOrderClothes.price;
              storeBook.unit_name = listOrderClothes.unit_name;
              storeBook.cloth_name = listOrderClothes.name;
              storeBook.kg = listOrderClothes.sub_weight.reduce(reducer)/1000;
            // }
            // delete data add new
            clothNewAdd.id[idx_service] = "-";
            clothNewAdd.name[idx_service] = "empty";
            clothNewAdd.price[idx_service] = 0;
            clothNewAdd.weight[idx_service] = 0;
            clothNewAdd.qty[idx_service] = 0;
            clothNewAdd.note_service[idx_service] = "kosong";
            clothNewAdd.parfum[idx_service] = "null";
            serviceNewAdd.unit_name[idx_service] = "";
            serviceNewAdd.service_name[idx_service] = "-";
            serviceNewAdd.note_service[idx_service] = "tidak_ada";
            serviceNewAdd.parfum[idx_service] = "null";
            // serviceNewAdd.note_service[idx_service] = "";
          }else{
            console.log("hemm..., listOrderClothes is empty, can't show list in chart!")
          }
          if (data_list_html !== "") {
            if(jenis_order == 'kilogram'){
              document.getElementById("data-order").innerHTML = data_list_html;
            }else{
              document.getElementById("data-order").innerHTML = data_list_html;

              // Kalau orderan bukan kilogram maka perlu diset semuanya jadi 0 utk input qty dan select item, dan juga estimasi harganya di kalkulasi, kalau layanan yg kilogram, gak perlu.
              document.getElementById("input-qty["+id+"]").value = 0;
              document.getElementById("input-clothes["+id+"]").selectedIndex = 0;
              document.getElementById("price-estimate").value = listOrderClothes.sub_price.reduce(reducer);
              // document.getElementById("weight-estimate").value = listOrderClothes.sub_weight.reduce(reducer)/1000;
            }
            
          }else{
            console.log("Look, data_lis_html is empty, can't show list hehehe... check this variable!")
          }
          try {
              elementQty.value = 0;
          } catch (error) {
              console.log("Berarti dia orderan kilogram bro!");
          }
          
        }else{
          try {
              elementQty.value = 0;
          } catch (error) {
              console.log("Berarti dia orderan kilogram bro!");
          }
          console.log("Hey looks, clothNewAdd and serviceNewAdd its look empty data, can't show or add item right now!");
        }
      }else{
        try {
          elementQty.value = 0;
        } catch (error) {
          console.log("Berarti dia orderan kilogram bro!");
        }
        console.log("hey check your qty or cloth, its look empty !")
      }
      // Berfungsi untuk menghilangkan cliking fokus pada button add (menfix bug clik double baru mau nambah barangnya)
      // document.getElementById("heading_name").click();
      elementCloth.value = "";
    }

    function qtyClothes(id) {
      const reducer = (accumulator, curr) => accumulator + curr;
      var elementQty = document.getElementById('input-qty['+id+']');
      var elementCloth = document.getElementById('input-clothes['+id+']');
      console.log("value element cloth: ", elementCloth.value)
      if (elementCloth.value != ""){
        var cloth_qty = parseInt(elementQty.value) ? parseInt(elementQty.value) : 0;
        var idx_service = parseInt(elementQty.getAttribute("data-idxservice")) ? parseInt(elementQty.getAttribute("data-idxservice")) : 0;
        // var service_price = parseInt(document.getElementById("service").value) ? parseInt(document.getElementById("service").value) : 0;
        // bagian untuk membatasi input jika menginputkan lebih dari batas qty maximum
        if(cloth_qty < 0){
          elementQty.value = 0
          cloth_qty = 0
        }
        var price_before = listOrderClothes.sub_price[0] > 0 ? listOrderClothes.sub_price.reduce(reducer) : 0
        var weight_before = listOrderClothes.sub_weight[0] > 0 ? listOrderClothes.sub_weight.reduce(reducer) : 0
        var price_this_clothes = parseInt(clothNewAdd.price[idx_service]) * cloth_qty
        var weight_this_clothes = parseFloat(clothNewAdd.weight[idx_service]) * cloth_qty
        if(price_before >= 0 && price_this_clothes >= 0){
          document.getElementById("price-estimate").value = price_before + price_this_clothes;
        }else{
          document.getElementById("price-estimate").value = price_before;
          console.log("price before < 0 and price_this_clothes < 0")
        }

        console.log("weight_before: ", weight_before)
        console.log("Weight_this_clothes: ", weight_this_clothes)

        // if(weight_before >= 0 && weight_this_clothes >= 0){
        //   document.getElementById("weight-estimate").value = (weight_before + weight_this_clothes) / 1000;
        // }else{
        //   document.getElementById("weight-estimate").value = weight_before / 1000;
        //   console.log("weight before < 0 and weight_this_clothes < 0")
        // }
        // storeBook.kg = (weight_before + weight_this_clothes) / 1000
        clothNewAdd.qty[idx_service] = cloth_qty;
        serviceNewAdd.note_service[idx_service] = "tidak_ada";
        serviceNewAdd.parfum[idx_service] = "null";
        clothNewAdd.note_service[idx_service] = "kosong";
        clothNewAdd.parfum[idx_service] = "null";
        console.log("QTY Input is : ", cloth_qty);
      }else{
        elementQty.value = 0;
      }
      console.log("ISI clothNewAdd saat Input Qty: ", clothNewAdd);
      
    }


    function selectClothes(id) {
      console.log("masuk function!")
      const dataList = document.getElementById('data_clothes_'+id);
      const textInput = document.getElementById('input-clothes['+id+']');

      const getSelecteOptionLocation = () => {
        for (let i = 0; i < dataList.options.length; i++) {
          if (dataList.options[i].value === textInput.value) {
            return dataList.options[i];
          }
        }
      }
      const selectedOption = getSelecteOptionLocation();

      // var elementSelect = document.getElementById('input-clothes['+id+']');
      var value_select = textInput.value;
      // var cloth_maxqty = 0;
      var cloth_price = 0;
      var cloth_id = "";
      var cloth_unit_name = ""
      // var service_id = ""
      var idx_service = ""
      var service_name = ""
      var weight = 0
      if (selectedOption) {
        // cloth_maxqty = elementSelect.options[elementSelect.selectedIndex].getAttribute("data-maxqty") ? elementSelect.options[elementSelect.selectedIndex].getAttribute("data-maxqty") : 0;
        cloth_price = selectedOption.getAttribute("data-price") ? selectedOption.getAttribute("data-price") : 0;
        cloth_id = selectedOption.getAttribute("data-clothid") ? selectedOption.getAttribute("data-clothid") : "";
        cloth_unit_name = selectedOption.getAttribute("data-unitname") ? selectedOption.getAttribute("data-unitname") : "";
        // service_id = elementSelect.options[elementSelect.selectedIndex].getAttribute("data-service") ? elementSelect.options[elementSelect.selectedIndex].getAttribute("data-service") : "";
        idx_service = selectedOption.getAttribute("data-idxservice") ? selectedOption.getAttribute("data-idxservice") : "";
        service_name = selectedOption.getAttribute("data-servicename") ? selectedOption.getAttribute("data-servicename") : "";
        weight = selectedOption.getAttribute("data-weight") ? parseFloat(selectedOption.getAttribute("data-weight")) : 0;
        
        clothNewAdd.id[idx_service] = cloth_id;
        // clothNewAdd.max_qty[idx_service] = cloth_maxqty;
        clothNewAdd.price[idx_service] = cloth_price;
        clothNewAdd.name[idx_service] = value_select;
        clothNewAdd.weight[idx_service] = weight;
        console.log("ISI clothNewAdd saat select cloth: ", clothNewAdd);
        serviceNewAdd.unit_name[idx_service] = cloth_unit_name;
        serviceNewAdd.service_name[idx_service] = service_name;

        serviceNewAdd.note_service[idx_service] = "tidak_ada";
        serviceNewAdd.parfum[idx_service] = "null";
        clothNewAdd.note_service[idx_service] = "kosong";
        clothNewAdd.parfum[idx_service] = "null";
        console.log("data cloth["+idx_service+"] => ", value_select, cloth_price, cloth_id);
      }else{
        console.log("Oh, no.., selectedIndex is <= 0 ");
      }
      // document.getElementById("input-qty["+service_id+"]").setAttribute("max", cloth_maxqty);
      
    }

    function deleteData(id_delete, id_service) {
      const reducer = (accumulator, curr) => accumulator + curr;
      var id_cloth = id_delete;
      var index_delete = -1;
      var elementCloth = document.getElementById('input-clothes['+id_service+']');
      var jenis_order = elementCloth.getAttribute("data-jenisorder");

      if (id_cloth) {
        var data_list_html = "";
        console.log(listOrderClothes);
        // cek jika data yang dikirim sudah ada di dalam data list order, jika ada maka delete datanya
        if (listOrderClothes.id.includes(id_cloth)) {
          index_delete = listOrderClothes.id.indexOf(id_cloth);
          if (index_delete > -1) {
            listOrderClothes.id.splice(index_delete, 1);
            listOrderClothes.name.splice(index_delete, 1);
            listOrderClothes.qty.splice(index_delete, 1);
            // listOrderClothes.max_qty.splice(index_delete, 1);
            listOrderClothes.price.splice(index_delete, 1);
            listOrderClothes.sub_price.splice(index_delete, 1);
            listOrderClothes.sub_weight.splice(index_delete, 1);
            listOrderClothes.unit_name.splice(index_delete, 1);
            listOrderClothes.service_name.splice(index_delete, 1);
            listOrderClothes.service_id.splice(index_delete, 1);
            listOrderClothes.note_service.splice(index_delete, 1);
            listOrderClothes.parfum.splice(index_delete, 1);
          }else{
            console.log("Oh no, index_delete <= -1");
          }
        } else {
          console.log("Can't Delete, Cause id_clothes not in list!");
        }
        if (listOrderClothes.id.length > 0) {
          data_list_html = draw_chart(listOrderClothes);
          storeBook.cloth_id = listOrderClothes.id;
          storeBook.cloth_name = listOrderClothes.name;
          storeBook.qty = listOrderClothes.qty;
          storeBook.price = listOrderClothes.sub_price;
          storeBook.unit_name = listOrderClothes.unit_name;
          storeBook.service_name = listOrderClothes.service_name;
          storeBook.note_service = listOrderClothes.note_service;
          storeBook.parfum = listOrderClothes.parfum;
          storeBook.kg = listOrderClothes.sub_weight.reduce(reducer)/1000;
          // delete data add new
          clothNewAdd.id[id_service] = "-";
          clothNewAdd.name[id_service] = "empty";
          clothNewAdd.price[id_service] = 0;
          clothNewAdd.weight[id_service] = 0;
          clothNewAdd.qty[id_service] = 0;
          clothNewAdd.note_service[id_service] = "kosong";
          serviceNewAdd.unit_name[id_service] = "-";
          serviceNewAdd.service_name[id_service] = "";
          serviceNewAdd.note_service[id_service] = "tidak_ada";
          serviceNewAdd.parfum[id_service] = "null";
        } else {
          storeBook.cloth_id = listOrderClothes.id;
          storeBook.cloth_name = listOrderClothes.name;
          storeBook.qty = listOrderClothes.qty;
          storeBook.price = listOrderClothes.sub_price;
          storeBook.unit_name = listOrderClothes.unit_name;
          storeBook.service_name = listOrderClothes.service_name;
          storeBook.note_service = listOrderClothes.note_service;
          storeBook.parfum = listOrderClothes.parfum;
          storeBook.kg = 0

          document.getElementById("data-order").innerHTML = "";
          if(jenis_order !== 'kilogram'){
            document.getElementById("data-order").innerHTML = data_list_html;

            // Kalau orderan bukan kilogram maka perlu diset semuanya jadi 0 utk input qty dan select item, dan juga estimasi harganya di kalkulasi, kalau layanan yg kilogram, gak perlu.
            document.getElementById("input-qty["+id_service+"]").value = 0;
            document.getElementById("input-clothes["+id_service+"]").selectedIndex = 0;
            document.getElementById("price-estimate").value = 0;
          }

          // document.getElementById("weight-estimate").value = 0;
        }
        if (data_list_html !== "") {
          console.log("deleted!");
          document.getElementById("data-order").innerHTML = data_list_html;
          if(jenis_order !== 'kilogram'){
            document.getElementById("input-qty["+id_service+"]").value = 0;
            document.getElementById("input-clothes["+id_service+"]").selectedIndex = 0;
            document.getElementById("price-estimate").value = listOrderClothes.sub_price.reduce(reducer);
          }
          
          
          // document.getElementById("weight-estimate").value = listOrderClothes.sub_weight.reduce(reducer)/1000;
        }else{
          console.log("Can't showing result, data_list_html is empty!")
        }
      }else{
        console.log("Can't Delete, Invalid ID Delete!")
      }
    }

    function goToScan() {
      modal.value.hide();
      return router.push({
        name: "scan",
      });
    }

    function goToHome() {
      modal.value.hide();
      return router.push({
        name: "dashboard",
      });
    }

    // function showModal() {
    //   modal.value.show();
    // }

    // function store_old() {
    //     // https://adminlaundry.bithouse.id/api/droppoint/booking
    //   var data_service_store = []
    //   if(storeBook.cloth_id.length > 0){
    //     for(var i=0; i<storeBook.service_name.length; i++){
    //       data_service_store.push(storeBook.service_name[i])
    //     }
        
    //     // pengecekan jika ada layanan yang belum di pilih 
    //     const found = list_service_selected.every(r=> data_service_store.includes(r))
    //     console.log("Nilai Found: ", found)
    //     if(found){
    //       document.getElementById("agree").checked = false;
    //       document.getElementById("button_booking").disabled = true;
    //       axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    //       axios
    //         .post("droppoint/booking", storeBook)
    //         .then((response) => {
    //           console.log(response);
    //           if (response.data.status == "success") {
    //             localStorage.setItem('laundry_id', response.data.data.laundry_id)
    //             localStorage.setItem('qr_code_d', response.data.data.qr_code)
    //             // modal.value.show();
    //             return router.push({
    //               name: 'droppoint-finish'
    //             })
    //           }
    //         })
    //         .catch((err) => {
    //           console.log(err.response);
    //           createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
    //           {
    //             type: 'danger',
    //             timeout: 5000,
    //             showIcon: 'true',
    //             transition: 'zoom'
    //           })
    //         });
    //     }else{
    //       if(document.getElementById("agree").checked == true){
    //         document.getElementById("agree").click();
    //       }
    //       var services_not_added = ""
    //       for(var index in list_service_selected){
    //         var valid = data_service_store.indexOf(list_service_selected[index])
    //         // console.log('valid ? : ', valid)
    //         if(valid < 0){
    //           services_not_added = services_not_added + list_service_selected[index] + " "
    //         }
    //       }
    //       createToast('Jenis barang dan quantity pada Layanan ' + services_not_added + ' belum anda isi, Isi dulu ya!',
    //       {
    //         type: 'warning',
    //         timeout: 5000,
    //         showIcon: 'true',
    //         transition: 'zoom'
    //       })
    //     }

    //     console.log('Data storebook: ',typeof storeBook.service_name)
        
    //   }else{
    //     if(document.getElementById("agree").checked == true){
    //       document.getElementById("agree").click();
    //     }
        
    //     // document.getElementById("button_booking").disabled = true;
    //     createToast('Humm... Sepertinya cart anda kosong :"(',
    //     {
    //       type: 'warning',
    //       timeout: 5000,
    //       showIcon: 'true',
    //       transition: 'zoom'
    //     })
    //   }
      
    // }

    function store() {
      if(storeBook.cloth_id.length > 0){
        isLoading.value = true
        document.getElementById("agree").checked = false;
        document.getElementById("button_booking").disabled = true;
        var noted = document.getElementById("note").value
        if(noted){
            Object.assign(storeBook,{
                note: noted ? noted : '-',
            });
        }
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        axios
          .post("antar-jemput/booking", storeBook)
          .then((response) => {
            console.log('RESPONSE STORE ANTAR JEMPUT!', response);
            if (response.data.status == "success") {
            //   localStorage.setItem('laundry_id', response.data.data.laundry_id)
              localStorage.setItem('qr_code_d', response.data.data.qr_code)
              isLoading.value = false
              // modal.value.show();
              return router.push({
                name: 'dashboard'
              })
            }
          })
          .catch((err) => {
              isLoading.value = false
            console.log(err.response);
            createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
            {
              type: 'danger',
              timeout: 5000,
              showIcon: 'true',
              transition: 'zoom'
            })
          });
        if(document.getElementById("agree").checked == true){
          document.getElementById("agree").click();
        }
        console.log('Data storebook: ',typeof storeBook.service_name)
      }else{
          isLoading.value = false
        if(document.getElementById("agree").checked == true){
          document.getElementById("agree").click();
        }
        // document.getElementById("button_booking").disabled = true;
        createToast('Humm... Sepertinya cart anda kosong :"(',
        {
          type: 'warning',
          timeout: 5000,
          showIcon: 'true',
          transition: 'zoom'
        })
      }
    }

    return {
      booking,
      data_antar_jemput,
      token,
      termConditions,
      checked,
      storeBook,
      clothNewAdd,
      listOrderClothes,
      user,
      store,
      deleteData,
      locker_id,
      route,
      router,
      lockerFull,
      clothes,
      services,
      subservices,
      serviceNewAdd,
      service_name,
      goToScan,
      goToHome,
      modalScan,
      list_service_selected,
      qr_code,
      role,
      selectClothes,
      qtyClothes,
      addClothes,
      getUnique,
      draw_chart,
      formatPrice,
      isLoading,
      // showModal,
    };
  },
};
</script>

<style>
/* ===== Loker Detail ===== */
.loker__detail .text__title {
  margin-bottom: 5px;
  text-align: left;
}
.loker__detail .text__name {
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
}

.modal img.img__ambil {
  width: 60%;
}

.loker-detail .locker__full img {
  margin: 30px 20px;
  width: 30%;
}
.loker-detail .locker__full .text {
  font-weight: 600;
}
.loker-detail .locker__full h2 {
  color: red;
}

.loker__detail .loc {
  background: #eeeeee;
  border-left: 3px solid var(--primaryColor);
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: left;
}
.loker__detail .loc p {
  margin-bottom: 0;
  text-align: left;
}

.loker__detail .term {
  border: 1px solid var(--lightGrey);
  padding: 10px;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 30px;
}
.loker__detail .term .term__title {
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
}
.loker__detail .detail-cloth h5{
  font-weight: 800;
}
.loker__detail .detail-cloth h6{
  font-weight: 800;
}
.loker__detail .detail-cloth .clothes-list {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.loker__detail .detail-cloth .clothes-list .list {
  width: 200px;
}

.loker__detail .detail-cloth .book-table tbody tr{
  border-bottom: 1px solid #d3d3d3 !important;
}
.loker__detail .detail-cloth .book-table thead tr th.jbarang{
  width: 40%;
}
.loker__detail .detail-cloth .book-table thead tr th.jlayanan{
  width: 20%;
}
.loker__detail .detail-cloth .book-table thead tr th.qty,
.loker__detail .detail-cloth .book-table thead tr th.tambah{
  width: 20%;
}
.loker__detail .detail-cloth .book-table tbody tr td button{
  width: 100%;
  border-radius: 5px;
  padding: 11px;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loker__detail .detail-cloth .book-table tbody tr td select, input
{
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.loker__detail .clothes .list-input input.estimasi{
  font-weight: 800;
  font-size: 14pt;
  color: var(--dark);
  background: transparent;
  margin-left: -20px;
}
.loker__detail .btn {
  width: 30%;
  margin-bottom: 50px;
}
/* ===== Loker Detail ===== */
@media (max-width: 768px) {
  .loker__detail .btn {
    width: 50%;
    margin-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .loker__detail .btn {
    width: 100%;
    margin-bottom: 50px;
  }
  .loker__detail .detail-cloth .clothes-list{
    display: block;
  }
  .loker__detail .clothes .list-input input.estimasi{
    margin-left: 0;
    font-size: 25pt;
    border: unset;
    padding: 0;
  }
  .loker__detail .clothes .list-input input.estimasi:focus{
    box-shadow: unset;
  }
  .loker__detail .clothes .list-input .form-select option{
    font-size: 10pt;
  }
  .loker__detail .book-table th{
    font-size: 8pt;
  }
}
</style>
