<template>
    <Navbarc></Navbarc>
    <div class="invoice">
        <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <div class="container">
			<h1 class="mt-3 text-start title fw-bold">
				Invoice
			</h1>
		</div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="wrapper">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="invoice-header">
                                            <h6 class="card-text c-title">From :</h6>
                                            <h6 class="card-title"><b>{{invoice.name ? invoice.name : 'WABO LAUNDRY'}}</b></h6>
                                            <h6 class="card-text">{{invoice.address_locker ? invoice.address_locker : 'Jalan Plawa, Gg XIII, No.8 , Sumerta Kelod, Denpasar Timur.'}}</h6>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="invoice-header">
                                            <h6 class="card-text c-title">To :</h6>
                                            <h6 class="card-title"><b>{{invoice.fullname}}</b></h6>
                                            <h6 class="card-text">{{invoice.address}}</h6>
                                            <h6 class="card-text">Phone : {{ invoice.phone_number }}</h6>
                                            <h6 class="card-text">Email : {{ invoice.email }}</h6>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="invoice-header status_laundry">
                                            <h6 class="card-title"><span class="title">Date</span>: {{ dateTime(invoice.updated_at) }}</h6>
                                            <h6 class="card-title"><span class="title">Laundry Code</span>: <b class="ms-2">{{ invoice.laundry_code }}</b></h6>
                                            <h6 class="card-text"><span class="title">Laundry Status</span>: <span class="badge bg-primary ms-2">{{ invoice.status }}</span></h6>
                                            <h6 class="card-text"><span class="title">No. Loker</span>: <b class="ms-2">{{ invoice.number_code ? invoice.number_code : '-' }}</b></h6>
                                            <!-- <h6 class="card-text"><span class="title">Jenis Layanan</span>: <b class="ms-2">{{ service_name }}</b></h6> -->
                                        </div>
                                    </div>
                                </div>

                                <!-- <div id="table_list_service"></div>
                                <div id="table_list_service_kilo"></div> -->

                                <div class="row mt-5 mb-3">
                                    <div class="col-sm-12">
                                        <div class="table-responsive" v-for="item in invDetail" :key="item.id">
                                            <table class="table table-hover table-striped mb-5">
                                                <thead>
                                                    <tr>
                                                        <th colspan="5" class="text-center">{{ item.service_name }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Jenis Barang</th>
                                                        <th>Harga</th>
                                                        <th>Jumlah</th>
                                                        <th>Deskripsi</th>
                                                        <th>Total Harga</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ item.name }}</td>
                                                        <td>Rp. {{ formatPrice(item.price) }}</td>
                                                        <td>{{ item.qty }}</td>
                                                        <td>{{ item.description}}</td>
                                                        <td>Rp. {{ formatPrice(item.sub_total)}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="table-responsive" v-for="itemKilo in detailKilo" :key="itemKilo.id">
                                            <table class="table table-hover table-striped mb-5">
                                                <thead>
                                                    <tr>
                                                        <th colspan="7" class="text-center">{{ itemKilo.name }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Jenis Layanan</th>
                                                        <th>Berat</th>
                                                        <th>Harga Kg</th>
                                                        <th>Note Service</th>
                                                        <th>Note Admin</th>
                                                        <th>Parfum</th>
                                                        <th>Total Harga</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ itemKilo.name }}</td>
                                                        <td>{{ itemKilo.berat }}</td>
                                                        <td>{{ formatPrice(itemKilo.harga_kg) }}</td>
                                                        <td>{{ itemKilo.note_service }}</td>
                                                        <td>{{ itemKilo.note_admin }}</td>
                                                        <td>{{ itemKilo.parfum }}</td>
                                                        <td>{{ formatPrice(itemKilo.total_harga) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="card-text">Status Pembayaran :</h6>
                                        <h1>
                                            <b>{{ payment.payment_status }}</b>
                                        </h1>
                                        <!-- <form @submit.prevent="uploadImage()">
                                            <div class="mb-3">
                                                <label for="description">Upload Bukti</label>
                                                <input type="file" class="form-control" @change="onHandlePayment()">
                                            </div>

                                            <button class="btn btn__primary">Upload</button>
                                        </form> -->
                                        <img :src="imageUrl+invoice.payment_attachment" class="img-payment">
                                        <!-- <p v-for="(image, index) in formatImage(invoice.admin_attachment)" :key="index">
                                            {{image}}
                                        </p> -->
                                        <!-- <img :src="formatImage(imageUrl+invoice.admin_attachment)" class="img-payment"> -->
                                        <div v-if="payment.payment_status === 'Lunas'">
                                            <h6 class="card-text mt-3">Metode Pembayaran :</h6>
                                            <div v-if="invoice.payment_attachment !== null">
                                                <span class="badge bg-success mb-2">Transfer Bank</span> <br>
                                            </div>
                                            <div v-else>
                                                <span class="badge bg-success mb-2">Cash</span>
                                            </div>
                                            <!-- <form @submit.prevent="uploadImage()" class="mb-3">
                                                <label>Ganti Gambar
                                                    <input type="file" id="file" class="form-control" @change="onHandlePayment($event)"/>
                                                </label>
                                                <button class="btn btn__primary">Upload</button>
                                            </form> -->
                                        </div>
                                        <div class="payment-method" v-else>
                                            <div v-if="invoice.payment_attachment == null">
                                                <h6 class="card-text mt-3">Pilih Metode Pembayaran :</h6>
                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link left active" id="trf-tab" data-bs-toggle="tab" data-bs-target="#trf" type="button" role="tab" aria-controls="trf" aria-selected="true">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank me-2" viewBox="0 0 16 16">
                                                                <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z"/>
                                                            </svg>
                                                            Transfer
                                                        </button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link right" id="cash-tab" data-bs-toggle="tab" data-bs-target="#cash" type="button" role="tab" aria-controls="cash" aria-selected="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack me-2" viewBox="0 0 16 16">
                                                                <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                                                                <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
                                                            </svg>
                                                            Cash
                                                        </button>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="trf" role="tabpanel" aria-labelledby="trf-tab">
                                                        <div class="alert alert-info mt-2 mb-3 rek_info" role="alert">
                                                            No. Rekening WABO <br>
                                                            <b>6690643888 an PT. BALI SWAKA KANDI</b>
                                                        </div>
                                                        <form @submit.prevent="uploadImage()">
                                                            <div class="form-floating mb-3">
                                                                <input type="text" class="form-control" id="name" placeholder="Nama" v-model="paymentState.nama_pengirim">
                                                                <label for="name">Nama</label>
                                                                <div v-if="error" class="mt-2 text-start text-danger error__text">
                                                                    Field is required!
                                                                </div>
                                                            </div>
                                                            <div class="form-floating mb-3">
                                                                <Select2 :options="myOptions" :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Pilih Bank" id="bank_name"/>
                                                                <!-- <label for="rek">Nama Bank</label> -->
                                                                <div v-if="error" class="mt-2 text-start text-danger error__text">
                                                                    Field is required!
                                                                </div>
                                                            </div>
                                                            <div class="form-floating mb-3">
                                                                <input type="text" class="form-control" id="rek" placeholder="No. Rekening" v-model="paymentState.no_rekening" @keyup="allowOnlyNumber($event)">
                                                                <label for="rek">No. Rekening</label>
                                                                <div v-if="error" class="mt-2 text-start text-danger error__text">
                                                                    Field is required!
                                                                </div>
                                                            </div>
                                                            <label>Upload Gambar
                                                                <input type="file" id="file" class="form-control" @change="onHandlePayment($event)"/>
                                                                <div v-if="error" class="mt-2 text-start text-danger error__text">
                                                                    Field is required!
                                                                </div>
                                                            </label>
                                                            <button class="btn btn__primary" :disabled="invoice.status !== 'Selesai'">Upload</button>
                                                        </form>
                                                        <!-- <div class="btn__upload">
                                                            <button type="button" class="btn btn__primary btn-sm">
                                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                                                                    <g><rect fill="none" height="24" width="24"/></g><g><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z"/></g>
                                                                </svg>
                                                                {{ invoice.payment_attachment ? 'Upload Ulang' : 'Upload' }}
                                                            </button>
                                                            <input type="file" accept="image/*" capture="user" @change="handlePayment($event, invoice.laundry_id)"/>
                                                            <input type="file" accept="image/*" capture="environment" directory="true" @change="handlePayment($event, invoice.laundry_id)"/>
                                                        </div> -->
                                                    </div>
                                                    <div class="tab-pane fade" id="cash" role="tabpanel" aria-labelledby="cash-tab">
                                                        <div v-if="invoice.type_location == 'Droppoint'" class="alert alert-info mt-2 mb-3 rek_info" role="alert">
                                                            Silahkan melakukan pembayaran ke drop point yang anda pilih.
                                                        </div>
                                                        <div v-else-if="invoice.type_location == 'Antar Jemput'" class="alert alert-info mt-2 mb-3 rek_info" role="alert">
                                                            Silahkan melakukan pembayaran ke Kurir.
                                                        </div>
                                                        <div v-else class="alert alert-info mt-2 mb-3 rek_info" role="alert">
                                                            Silahkan melakukan pembayaran ke tempat kami.
                                                        </div>
                                                        <form @submit.prevent="cashPayment()">
                                                            <div class="row">
                                                                <div class="col-4">
                                                                    <input type="hidden" class="form-control" v-model="storeCash.laundry_id">
                                                                </div>
                                                                <div class="col-4">
                                                                    <input type="hidden" class="form-control" v-model="storeCash.payment_method">
                                                                </div>
                                                                <div class="col-4">
                                                                    <input type="hidden" class="form-control" v-model="storeCash.payment_attachment">
                                                                </div>
                                                            </div>
                                                            <button class="btn btn__primary p_btn d-flex align-items-center justify-content-center" :disabled="invoice.status !== 'Selesai'">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack me-2" viewBox="0 0 16 16">
                                                                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                                                                    <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
                                                                </svg>
                                                                Bayar Sekarang
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <h6 class="badge_validasi" v-if="payment.payment_status === 'Belum Lunas'">
                                                    <span class="badge bg-warning">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                                        </svg>
                                                        Menunggu Validasi Pembayaran
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="card-text">Biaya Ongkir :</h6>
                                        <h3>
                                            <b>Rp. {{ formatPrice(totalOngkir.ongkir) }}</b>
                                        </h3>
                                        <h6 class="card-text">Total Pembayaran :</h6>
                                        <h1>
                                            <!-- <b>{{total_harga}}</b> -->
                                            <b>Rp. {{ formatPrice(countPrice(total_harga, totalOngkir.ongkir ? totalOngkir.ongkir : '0')) }}</b>
                                        </h1>

                                        <!-- <h6 class="card-text mt-3"><b>Catatan :</b></h6>
                                        <h6 class="card-text mt-2" v-if="invoice.note !== null">{{invoice.note}}</h6>
                                        <h6 class="card-text mt-2" v-else>-</h6> -->
                                        <!-- <img :src="imageUrl+invoice.admin_attachment" v-if="invoice.admin_attachment" class="img-payment"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import { onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useRouter } from "vue-router";

import moment from 'moment'
import bankData from "./bank.json";

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

import Select2 from 'vue3-select2-component';
// Dokumentasi untuk loader => https://vue-preloader.netlify.app/

export default {
    name: 'Invoice',
	components: {
		Navbarc,
        Select2
	},
    setup(){
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        var invoice = ref([])
        var invDetail = ref([])
        var total_harga = ref([])
        var service_name = ref([])
        var service_name_kilo = ref([])
        const detailKilo = ref([])
        const route = useRoute()
        const router = useRouter();
        const isLoading = ref(false);
        var list_services = new Array()
        var list_services_kilo = new Array()
        var invoice_detail_list = []
        var invoice_detail_list_kilo = ref([])
        const payment = ref([])
        var per_service = []
        var per_service_kilo = []
        var total_weight = []
        // var estimate_weight = []
        const selectedImage = ref(null)
        const paymentState = reactive({
            nama_pengirim: '',
            no_rekening: '',
            nama_bank: ''
        })
        const error = ref(false);
        // console.log("bank data: ", bankData)

        const myOptions = ref([
        ])

        var activeItem = ref('')
        var totalOngkir = reactive({
            ongkir: ''
        })
        const storeCash = reactive({
            laundry_id: route.params.laundry_id,
            payment_method: 'Cash',
            payment_attachment: null,
        })

        function myChangeEvent(val){
            console.log(val);
        }

        function mySelectEvent({id, text}){
            console.log({id, text})
            paymentState.nama_bank = text
        }
        
        onMounted(() => {
            bankData.forEach((data) => myOptions.value.push(data.name));
        })
        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice/${route.params.laundry_id}`)
			.then(result => {
				console.log('invoice data: ', result)
				invoice.value = result.data.data
                totalOngkir.ongkir = result.data.data.delivery_cost
                console.log('ongkir: ', totalOngkir.ongkir)
			}).catch((err) => {
				console.log(err.response)
			})
        })
        
        onMounted(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice-detail/${route.params.laundry_id}`)
			.then(result => {
				console.log("Detail Invoice: ",result.data.data)
                console.log("Detail Invoice Kilo : ",result.data.data2)
				invDetail.value = result.data.data
                total_harga.value = (result.data.data.length > 0 ) ? result.data.data[0].total_price : result.data.data2[0].total_payment
                console.log("total_harga: ", total_harga)
                invoice_detail_list = result.data.data
                invoice_detail_list_kilo.value = result.data.data2

                payment.value = (result.data.data.length > 0) ? result.data.data[0] : result.data.data2[0]
                // laundry detail
                for(var i = 0; i<result.data.data.length; i++){
                    if(i == (result.data.data.length - 1)){
                        // check apakah 
                        if(list_services.indexOf(result.data.data[i].service_name) == -1){
                            service_name.value = service_name.value + result.data.data[i].service_name + ", "
                            per_service.push({'name': result.data.data[i].service_name})
                        }
                    }else{
                        if(list_services.indexOf(result.data.data[i].service_name) == -1){
                            service_name.value = service_name.value + result.data.data[i].service_name + ", "
                            per_service.push({'name': result.data.data[i].service_name})
                        }
                    }
                    list_services.push(result.data.data[i].service_name)
                }
                // laundry kilo
                 for(var h = 0; h<result.data.data2.length; h++){
                    if(h == (result.data.data2.length - 1)){
                        // check apakah 
                        if(list_services_kilo.indexOf(result.data.data2[h].name) == -1){
                            service_name_kilo.value = service_name_kilo.value + result.data.data2[h].name + ", "
                            per_service_kilo.push({'name': result.data.data2[h].name})
                        }
                    }else{
                        if(list_services_kilo.indexOf(result.data.data2[h].name) == -1){
                            service_name_kilo.value = service_name_kilo.value + result.data.data2[h].name + ", "
                            per_service_kilo.push({'name': result.data.data2[h].name})
                        }
                    }
                    list_services_kilo.push(result.data.data2[h].name)
                    console.log('list service kilo : ',list_services_kilo)
                }
                var k = 0
                var j = 0
                // var l = 0
                // var m = 0
                var html_table_service = ""
                
                var total_price_in_service = 0
                
                console.log('per_service: ', per_service)
                console.log('per_service length: ', per_service.length)
                for(k = 0; k<per_service.length; k++){
                    console.log("valuenya: ", per_service[k].name)

                    html_table_service += '<div class="row mt-5 mb-3"><div class="col-sm-12"><div class="table-responsive"><table class="table table-hover table-striped" style="border: 1px solid black; border-collapse: collapse;">'
                    +'<thead><tr><th colspan="6" style="border: 1px solid black;"> <center><b>'+per_service[k].name+'<br><hr></b></center> </th></tr><tr><th style="border: 1px solid black;">Jenis Barang</th><th style="border: 1px solid black;">Harga</th><th style="border: 1px solid black;">Jumlah</th><th style="border: 1px solid black;">Description</th><th style="border: 1px solid black;">Total Harga</th></tr></thead>'
                    html_table_service += '<tbody>'
                    total_price_in_service = 0
                    for(j = 0; j<invoice_detail_list.length; j++){
                        if(per_service[k].name === invoice_detail_list[j].service_name){
                            html_table_service += '<tr><td style="border: 1px solid black;">'+invoice_detail_list[j].name+'</td><td style="border: 1px solid black;">Rp. '+formatPrice(invoice_detail_list[j].price)+'</td><td style="border: 1px solid black;">'+invoice_detail_list[j].qty+'</td><td style="border: 1px solid black;">'+invoice_detail_list[j].description+'</td><td style="border: 1px solid black;">Rp. '+formatPrice(parseInt(invoice_detail_list[j].qty)*parseInt(invoice_detail_list[j].price))+'</td></tr>'
                            total_price_in_service += parseInt(invoice_detail_list[j].qty)*parseInt(invoice_detail_list[j].price)
                        }
                    }
                    html_table_service += '<tr><td style="border: 1px solid black;" colspan="4"><center><b>Total</b></center></td><td style="border: 1px solid black;"><b>Rp. '+ formatPrice(total_price_in_service)+'</b></td></tr>'
                    html_table_service += '</tbody>'
                    html_table_service += '</tbody></table></div></div></div>'
                }
                // console.log("MOUNTED invoice_detail_list: ", invoice_detail_list)
                // console.log("MOUNTED list_services: ", list_services)
                document.getElementById("table_list_service").innerHTML = html_table_service
                // var invoice_kilo = invoice_detail_list_kilo.value
                var html_table_service_kilo = ""
                // console.log('per_service_kilo: ', per_service_kilo)
                // console.log('per_service_kilo length: ', per_service_kilo.length)
                for(var l = 0; l<per_service_kilo.length; l++){
                    console.log("valuenya kilo: ", per_service_kilo[l].name)
                    html_table_service_kilo += '<div class="row mt-5 mb-3"><div class="col-sm-12"><div class="table-responsive"><table class="table table-hover table-striped" style="border: 1px solid black; border-collapse: collapse;">'
                    +'<thead><tr><th colspan="8" style="border: 1px solid black;"> <center><b>'+per_service_kilo[l].name+'<br><hr></b></center> </th></tr><tr><th style="border: 1px solid black;">Jenis Barang</th><th style="border: 1px solid black;">Harga Kg</th><th style="border: 1px solid black;">Note Service</th><th style="border: 1px solid black;">Note Admin</th><th style="border: 1px solid black;">Parfum</th><th style="border: 1px solid black;">Total Harga</th></tr></thead>'
                    html_table_service_kilo += '<tbody>'
                    // total_price_in_service = 0
                    // var invoice_kilo = invoice_detail_list_kilo.value
                    // console("masuk : ", invoice_kilo)
                    // for(m = 0; m<invoice_kilo.length; m++){
                    //     console("masuk : ", invoice_kilo[m])
                    //     if(per_service_kilo[m].name === invoice_detail_list_kilo[m].name){
                    //         html_table_service_kilo += '<tr><td style="border: 1px solid black;">'+invoice_detail_list_kilo[m].name+'</td><td style="border: 1px solid black;">'+invoice_detail_list_kilo[m].berat+' Kg</td><td style="border: 1px solid black;">Rp. '+formatPrice(invoice_detail_list_kilo[m].harga_kg)+'</td><td style="border: 1px solid black;">'+invoice_detail_list_kilo[m].note_service+'</td><td style="border: 1px solid black;">'+invoice_detail_list_kilo[m].note_admin+'</td><td style="border: 1px solid black;">'+invoice_detail_list_kilo[m].parfum+'</td><td style="border: 1px solid black;">Rp. '+formatPrice(parseInt(invoice_detail_list[m].total_harga))+'</td></tr>'
                    //         // total_price_in_service += parseInt(invoice_detail_list[j].qty)*parseInt(invoice_detail_list[j].price)
                    //     }
                    // }
                    // html_table_service_kilo += '<tr><td style="border: 1px solid black;" colspan="4"><center><b>Total</b></center></td><td style="border: 1px solid black;"><b>Rp. '+ formatPrice(total_price_in_service)+'</b></td></tr>'
                    // html_table_service_kilo += '</tbody>'
                    // html_table_service_kilo += '</tbody></table></div></div></div>'
                }
                document.getElementById("table_list_service_kilo").innerHTML = html_table_service_kilo

			}).catch((err) => {
				console.log(err.response)
			})

            axios.get(`invoice-detail/${route.params.laundry_id}`)
            .then((response) => {
                detailKilo.value = response.data.data2
                console.log("Detail Kilo Nih Boss : ",detailKilo.value)
            }).catch((err) => {
                console.log(err.response)
            })
        })

        function cashPayment(){
            isLoading.value = true
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.post('booking/payment-confirmation', storeCash)
            .then(response => {
                console.log(response)
                createToast('Berhasil',
                {
                    type: 'success',
                    timeout: 3000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                isLoading.value = false;
                window.location.reload();
            }).catch((err) => {
                console.log(err.response)
                createToast('Metode Pembayaran Tidak Berhasil',
                {
                    type: 'danger',
                    timeout: 3000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                setTimeout(() => {
                    isLoading.value = false;
                }, 2000)
            })
			
        }

        function onHandlePayment(event){
            selectedImage.value = event.target.files[0]
            console.log(selectedImage.value)
            if(selectedImage.value == null && selectedImage.value.length == 0){
                createToast('Ops.. File kosong!',
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                return false;
            }
        }

        function allowOnlyNumber(event){
            var input_value = event.target.value
            if (/\D/g.test(input_value))
            {
                // Filter non-digits from input value.
                event.target.value = input_value.replace(/\D/g, '');
            }
        }

        function formatImage(value){
            // value.replace(/\D/g)
            return JSON.parse(value)
        }

        function uploadImage(){
            isLoading.value = true;
            let formData = new FormData();
            formData.append('payment_attachment', selectedImage.value);
            formData.append('laundry_id', route.params.laundry_id);
            formData.append('nama_pengirim', paymentState.nama_pengirim);
            formData.append('no_rekening', paymentState.no_rekening);
            formData.append('bank', paymentState.nama_bank);
            formData.append('payment_method', 'Transfer');
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.post('booking/payment-confirmation', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if(paymentState.nama_pengirim == '' || paymentState.no_rekening == '' || paymentState.nama_bank == '' || selectedImage.value == null)
                {
                    error.value = true
                }else{
                    if(response.data.status == 'success')
                    {
                        const data = response;
                        console.log(data)
                        createToast("Upload bukti pembayaran berhasil, menunggu admin untuk memverifikasi pembayaran!",
                        {
                            type: 'success',
                            timeout: 5000,
                            showIcon: 'true',
                            transition: 'zoom'
                        })
                    }
                }
                // get update data , untuk mengupdate tampilan image ketika upload ulang!
                // axios.defaults.headers.common.Authorization = `Bearer ${token}`
                // axios.get(`invoice/${route.params.laundry_id}`)
                // .then(result => {
                //     console.log(result)
                //     invoice.value = result.data.data
                // }).catch((err) => {
                //     console.log(err.response)
                //     createToast('Ops.. Gagal mengambil data invoice!',
                //     {
                //         type: 'warning',
                //         timeout: 5000,
                //         showIcon: 'true',
                //         transition: 'zoom'
                //     })
                // })
                setTimeout(() => {
                    isLoading.value = false;
                }, 5000)
                window.location.reload()
            }).catch((err) => {
                const { data } = err.response;
                // validation.value = err.response
                createToast(data.message,
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                setTimeout(() => {
                    isLoading.value = false;
                }, 5000)
            })
        }

        const handlePayment = (event, laundry_id) => {
            isLoading.value = true;
            const file = event.target.files;
            console.log(file)
            if(file == null && file.length == 0){
                createToast('Ops.. File kosong!',
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                isLoading.value = false;
                return false;
            }
            
            const data = new FormData();
            data.append('payment_attachment', file[0]);
            data.append('laundry_id', laundry_id);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.post('booking/payment-confirmation', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                const data = response;
                console.log(data)
                createToast("Upload bukti pembayaran berhasil, menunggu admin untuk memverifikasi pembayaran!",
                {
                    type: 'success',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                // get update data , untuk mengupdate tampilan image ketika upload ulang!
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.get(`invoice/${route.params.laundry_id}`)
                .then(result => {
                    console.log(result)
                    invoice.value = result.data.data
                }).catch((err) => {
                    console.log(err.response)
                    createToast('Ops.. Gagal mengambil data invoice!',
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                })
                setTimeout(() => {
                    isLoading.value = false;
                }, 5000)
            }).catch((err) => {
                const { data } = err.response;
                createToast(data.message,
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
                setTimeout(() => {
                    isLoading.value = false;
                }, 5000)
            })
        }

        function isActive (menuItem) {
            return activeItem.value === menuItem
        }
        function setActive (menuItem) {
            activeItem.value = menuItem
        }

        function formatPrice(value){
            let val = (value/1)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }

        function dateTime(value) {
			return moment(value).format("DD-MM-YYYY h:mm A");
		}

        function countPrice(price, ongkir){
			var harga_utama = parseInt(price)
			var harga_ongkir = parseInt(ongkir)
			var total = harga_utama + harga_ongkir
			return total.toString()
		}


        return{
            invoice,
            route,
            invDetail,
            isLoading,
            formatPrice,
            dateTime,
            handlePayment,
            router,
            role,
            service_name,
            // service_name_kilo,
            list_services,
            // list_services_kilo,
            cashPayment,
            storeCash,
            payment,
            selectedImage,
            onHandlePayment,
            uploadImage,
            isActive,
            setActive,
            total_weight,
            paymentState,
            allowOnlyNumber,
            myOptions,
            myChangeEvent,
            mySelectEvent,
            bankData,
            error,
            totalOngkir,
            countPrice,
            detailKilo,
            total_harga,
            formatImage,
            // estimate_weight
        }
    }
}
</script>

<style scoped>
.invoice .card .invoice-header h6{
    display: flex;
}
.invoice .card .invoice-header h6.c-title{
    color: var(--primaryColor);
    font-weight: 600;
}
.invoice .card .invoice-header h6 span.title{
    width: 130px;
}

.invoice .card .card-body .table thead{
    border-bottom: 2px solid var(--primaryColor) !important;
    border-top: 2px solid var(--primaryColor) !important;
}
.invoice .card .card-body .table thead tr th{
    color: var(--primaryColor);
}

.btn__upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 30px;
}
.btn__upload input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.btn__upload .btn-sm{
    padding: 0.2em 0.3em;
    font-size: 0.9em;
    font-weight: normal;
}
.img-payment{
    max-width: 130px;
    display: block;
    /* margin: 0 auto; */
    margin-bottom: 10px;
}

.invoice .n-payment{
    border: none;
}
.invoice .n-payment .nav-item a:hover{
    color: white;
}
.invoice .n-payment .nav-item a.active{
    color: white;
}
.invoice .tab-content-payment{
    position: relative;
}
.invoice .tab-content-payment #cash{
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 0;
    width: 100%;
}

.invoice .tab-content form label{
    font-size: 10pt;
}
.invoice .badge_validasi{
    width: 100%;
}

.invoice .tab-content .rek_info{
    padding: 5px 10px;
}


@media (max-width: 767.98px){
    .invoice .card .invoice-header{
        margin-bottom: 30px;
    }
    .invoice .n-payment .nav-item a.active{
        color: white;
        background: var(--blueGradient);
    }
    .invoice .tab-content-payment #cash form button{
        width: 100%;
    }
}
</style>