<template>
<Navbark></Navbark>
	<div class="home">
		<div class="container">
			<div class="head-box">
				<h3 class="title">Selamat Datang, {{name}}!</h3>

				<p class="desc">Berikut adalah list tujuan yang harus kamu pick up!</p>
				<p class="desc">Kamu bisa pilih daerah tujuan yang sudah admin WABO pilihkan!</p>

				<figure>
					<img src="../../assets/images/welcome-icon.svg" alt="">
				</figure>
			</div>

			<div class="courier">
				<div class="c-header">
					<h5 class="c-title">List Order Antar Jemput</h5>
				</div>
                <div class="c-body">
                    <div class="table-responsive">
						<table class="table table-hover table-sm table-striped">
							<thead>
								<tr>
									<th class="text-center align-middle">Aksi</th>
									<th class="align-middle">Nama Customer</th>
									<th class="align-middle">Kode Laundry</th>
									<th class="align-middle">No Telepon</th>
									<th class="align-middle">Alamat</th>
									<th class="align-middle">Wilayah Droppoint</th>
									<th class="align-middle">Note</th>
								</tr>
							</thead>
							<tbody v-for="(list, index) in orderAJ" :key="index">
								<!-- <div v-for="list in orderAJ" :key="list.id"> -->
								<tr v-for="(sublist, index) in list.listAntarJemput" :key="index">
									<td>
										<div>
											<button class="btn text-dark btnAmbil" :data-laundry_id="sublist.laundry_id" @click="storeDataLaundry(sublist.laundry_id, sublist.laundry_code, sublist.fullname); modal.show();">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
													<path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
												</svg>
												Ambil
											</button>
										</div>
										<div>
											<!-- <button class="btn btn__primary tbtn" @click="goToScan()">
												<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
													<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
												</svg>
												Scan
											</button> -->
										</div>
									</td>
									<td>{{sublist.fullname}}</td>
									<td>{{sublist.laundry_code}}</td>
									<td>{{sublist.phone_number}}</td>
									<td>{{sublist.address}}</td>
									<td>{{sublist.locker_name}}</td>
									<td>{{sublist.note}}</td>
									<!-- <td v-if="index === 0" colspan="7" class="text-center">Tidak Ada Data</td> -->
								</tr>
								<!-- <tr v-show="index === 0">
									<td colspan="6" v-if="list.listAntarJemput.length == 0" class="text-center">Tidak Ada Data</td>
								</tr> -->
								<!-- </div> -->
							</tbody>
						</table>
					</div>
                </div>

				<div class="c-header mt-3">
					<h5 class="c-title">List Jemput</h5>
				</div>
				<div class="c-body">
                    <div class="table-responsive">
						<table class="table table-hover table-sm table-striped">
							<thead>
								<tr>
									<th class="text-center align-middle">Aksi</th>
									<th class="align-middle">Nama Customer</th>
									<th class="align-middle">Kode Laundry</th>
									<th class="align-middle">No Telepon</th>
									<th class="align-middle">Alamat</th>
									<th class="align-middle">Wilayah Droppoint</th>
									<th class="align-middle">Note</th>
								</tr>
							</thead>
							<tbody v-for="(list, index) in prosesAJ" :key="index">
								<!-- <div v-for="list in orderAJ" :key="list.id"> -->
								<tr v-for="(sublist, index) in list.listAntarJemput" :key="index">
									<td>
										<!-- <div v-if="sublist.courier_id !== null">
											<button class="btn text-dark btnAmbil" :data-laundry_id="sublist.laundry_id" @click="storeDataLaundry(sublist.laundry_id, sublist.laundry_code, sublist.fullname); modal2.show();" v-if="sublist.status == 'Siap Diambil Courier'">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
													<path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
												</svg>
												Ambil
											</button>
										</div> -->
										<div>
											<button class="btn btn__primary tbtn" @click="goToScan()">
												<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
													<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
												</svg>
												Scan
											</button>
										</div>
									</td>
									<td>{{sublist.fullname}}</td>
									<td>{{sublist.laundry_code}}</td>
									<td>{{sublist.phone_number}}</td>
									<td>{{sublist.address}}</td>
									<td>{{sublist.locker_name}}</td>
									<td>{{sublist.note}}</td>
									<!-- <td v-if="index === 0" colspan="7" class="text-center">Tidak Ada Data</td> -->
								</tr>
								<!-- <tr v-show="index === 0">
									<td colspan="6" v-if="list.listAntarJemput.length == 0" class="text-center">Tidak Ada Data</td>
								</tr> -->
								<!-- </div> -->
							</tbody>
						</table>
					</div>
                </div>

            </div>

			<!-- <button class="btn btn__primary" @click="notif()">Click Me!</button> -->
			<!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="modalAmbil" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Apakah anda ingin ambil laundry yang dipesan oleh {{customer_data.name}}, dengan Kode Laundry: {{customer_data.laundry_code}} ? </h5>
                        <div class="text-center">
                            <button type="button" class="btn btn__primary px-5 mt-3" @click="confirmBookingAntarJemput()" v-on:click="modal.hide()">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
									<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
								</svg>
								Ambil sekarang!
							</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			<!-- Modal -->

			<!-- Modal Antar ke Customer -->
            <div class="modal fade" id="staticBackdrop" ref="modalAntar" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Apakah anda ingin ambil dan mengantarkan laundry ke {{customer_data.name}}, dengan Kode Laundry: {{customer_data.laundry_code}} ? </h5>
                        <div class="text-center">
                            <button type="button" class="btn btn__primary px-5 mt-3" @click="confirmBookingAntarJemput()" v-on:click="modal.hide()">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
									<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
								</svg>
								Antar sekarang!
							</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			<!-- Modal Antar ke Customer -->
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Navbark from '@/components/Navbark.vue'
import { onMounted,ref,reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import axios from 'axios'
import push from 'push.js'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
	name: 'Home',
	components: {
		Navbark,
	},
	data: () => ({
        modal: null,
        modal2: null,
    }),
	mounted(){
		this.modal = new Modal(this.$refs.modalAmbil)
		this.modal2 = new Modal(this.$refs.modalAntar)
	},
	setup(){
		const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
		const name = localStorage.getItem('name')
		const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'courier'
		const orderAJ = ref([])
		const profile = ref([])
		const prosesAJ = ref([])
		// const renderDom = ref(0)
		const router = useRouter()
		// pengecekan role jika yng mencoba akses adalah cusgtomer maka redirect ke halaman dashboard utk customer
		
		var customer_data = reactive({
			'name': '',
			'laundry_code': ''
		})

		var dataConfirmBooking = reactive({
			user_id: user,
			laundry_id: '',
		})
		onMounted(() => {
			if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}else if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}

			axios.get('profile/'+(user))
            .then(response => {
                console.log('GET Profile', response.data.data)
                profile.value = response.data.data
                
            }).catch((err) => {
				if(err.response.data.status_code == 401)
				{
					localStorage.clear()
					router.push({ name: 'auth' });
				}
				console.log("Error Profile", err.response)
			})
			
			axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`antar-jemput/courier/list/${user}`)
			.then(result => {
				orderAJ.value = result.data.data
				console.log('List Booking Antar Jemput: ',result.data.data)
				console.log('List Booking Antar Jemput MOUNTED: ',result.data.data.length)
				// push.create('Ada Order Antar Jemput Baru Masuk Nihh!!!')
				
			}).catch((err) => {
				console.log(err.response)
			})
		})

		onMounted(() => {
			axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`antar-jemput/courier/list-proses/${user}`)
			.then(result => {
				console.log('List Jemput: ',result.data.data)
				prosesAJ.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})
		
		// onUpdated(() => {
		// 	console.log('check updated data', renderDom.value)
		// 	// hasilnya 1, this.renderDom berhasil di update
		// 	notif()
		// 	console.log('check updated re-render', document.getElementById('debug'))
		// 	// template id 'debug' di kenali
		// })

		function goToScan(){
			return router.push({
				path: '/order-scan'
			})
		}
		function notif(){
			push.create('Hello World!! ini Test notification ya :)')
		}

		function storeDataLaundry(id_laundry, laundry_code, customer_name){
			localStorage.setItem('laundry_id', id_laundry)
			localStorage.setItem('laundry_code', laundry_code)
			dataConfirmBooking.laundry_id = id_laundry;
			customer_data.name = customer_name;
			customer_data.laundry_code = laundry_code;
		}

		function confirmBookingAntarJemput(){
			axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.post('antar-jemput/courier/confirm', dataConfirmBooking)
			.then(result => {
				console.log('Result Confirm Booking Antar jemput: ',result.data.data)
				if(result.data.status == 'success'){
					
					createToast('Sukses Confirm Antar Jemput',
					{
						type: 'success',
						timeout: 5000,
						showIcon: 'true',
						transition: 'zoom'
					})
					window.location.reload()
				}
				
			}).catch((err) => {
				console.log('ERR data: ', err.response)
				createToast('Terjadi kesalahan, ERR MSG: ' + err.response,
				{
					type: 'danger',
					timeout: 5000,
					showIcon: 'true',
					transition: 'zoom'
				})
			})
		}

		onMounted(() => {
			axios.interceptors.response.use( (response) => {
				// Return a successful response back to the calling service
				return response;
			}, (error) => {
				// Return any error which is not due to authentication back to the calling service
				if (error.response.data.status === 401) {
					// return new Promise((resolve, reject) => {
					// 	reject(error);
					// });
					localStorage.removeItem('token')
					// window.location.reload()
					return router.push({
						name: 'auth'
					})
				}
			})
		})
		return{
			token,
			name,
			user,
			orderAJ,
			role,
			goToScan,
			notif,
			storeDataLaundry,
			customer_data,
			confirmBookingAntarJemput,
			prosesAJ,
			profile
		// 	renderDom,
		// 	reRenderDom,
		// 	removeDom
		}
	}
}
</script>

<style scoped>
	h1.title{
		font-weight: 600;
		margin-bottom: 30px;
		color: var(--secondaryColor);
	}

	/* ===== Welcome Text ====== */
	.home .head-box{
		position: relative;
		background: #f2f2f2;
		border-radius: 10px;
		padding: 20px;
		margin: 50px 0 60px;
	}
	.home .head-box .title{
		color: var(--darkBlue);
		font-weight: 800;
		margin-bottom: 20px;
		margin-right: 260px;
		text-align: left;
	}
	.home .head-box .desc{
		color: var(--darkGrey);
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 260px;
	}
	.home .head-box figure{
		position: absolute;
		bottom: -15px;
		right: -20px;
	}
	.home .head-box figure img{
		width: 250px;
		height: auto;
	}
	/* ===== Welcome Text ====== */
	
	/* ===== Antar Jemput List ====== */
	.courier .c-header .c-title{
		font-weight: 800;
		color: var(--secondaryColor);
	}
	.courier .c-body .table thead{
		/* background: #e9cce5;
		color: var(--darkBlue); */
		border-bottom: 2px solid var(--primaryColor) !important;
		border-top: 2px solid var(--primaryColor) !important;
	}
	.courier .c-body .table tr td .tbtn{
		padding: 5px;
		font-size: 10pt;
		border-radius: 7px;
		margin: 5px;
		font-weight: 600;
	}
	.courier .c-body .table tr td .btnAmbil{
		font-size: 9pt;
		padding: 5px;
		margin: 5px;
		border-radius: 7px;
		background: #ffad50;
		font-weight: 600;
	}
	/* ===== Antar Jemput List ====== */

	@media (max-width: 768px){
		/* ===== Welcome ===== */
		.home .head-box{
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 40px;
			padding: 10px;
		}
		.home .head-box .title{
			font-size: 12pt;
			margin-right: 110px;
		}
		.home .head-box .desc{
			font-size: 8pt;
			margin-right: 110px;
		}
		.home .head-box figure{
			width: 40%;
		}
		.home .head-box figure img{
			width: 100%;
			height: auto;
		}
		/* ===== Welcome ===== */

		.courier .c-body .table thead tr th{
			font-size: 9pt;
		}
	}
</style>
