<template>
    <Navbarc></Navbarc>
    <div class="antar-jemput">
        <!-- <div id="map"></div> -->
        <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <l-map
            ref="map"
            :zoom="zoom"
            :center="[
                userLocation.lat || defaultLocation.lat,
                userLocation.lng || defaultLocation.lng
            ]"
            @click="addMarker"
        >
            <l-tile-layer
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
            />
            <l-marker :lat-lng="markerLatLng" ></l-marker>
            <v-geosearch :options="geosearchOptions" ></v-geosearch>
        </l-map>

        <div class="sheet">
            <div class="container">
                <div class="mb-3">
                    <label for="lokasi" class="fw-bold">Mau antar jemput dimana?</label>
                    <textarea rows="2" class="form-control" id="lokasi" placeholder="Masukan Alamat Detail.."></textarea>
                </div>

                <div class="detail">
                    <div class="list">
                        <p class="sub">Ongkir</p>
                        <p class="sub" id="base_ongkir">Rp 0</p>
                    </div>
                    <div class="list">
                        <p class="sub">Total Diskon Ongkir</p>
                        <p class="sub" id="base_diskon">- Rp 0</p>
                    </div>
                    <div class="list">
                        <h5 class="total">Estimasi Harga</h5>
                        <h5 class="total"><p id="total_ongkir">Rp 0</p></h5>
                    </div>
                    <div class="button">
                        <button class="btn btn__primary" id="button_lanjut" @click="detailAntarJemput()">Lanjut</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Info -->
		<div class="modal fade" id="staticBackdrop" ref="modalNotif" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center">
                            <img src="../../assets/images/icon/info-icon.svg" class="notif-img" alt="">
                        </div>
						<h5 class="text-center info-txt">
                            Silahkan pilih lokasi di map terlebih dahulu untuk melanjutkan proses order!
                        </h5>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import "leaflet-geosearch/assets/css/leaflet.css";
// import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import axios from 'axios'
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";

import { Modal } from 'bootstrap'

export default {
    name: 'AntarJemput',
	components: {
		Navbarc,
        LMap, 
        LTileLayer,
        LMarker,
        VGeosearch
        // OpenStreetMapProvider,
        // GeoSearchControl
	},
    props: {
        defaultLocation: {
        type: Object,
            default: () => ({
                lat: -8.6539493,
                lng: 115.2278344
            })
        } 
    },
    setup() {
        const router = useRouter();
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        const isLoading = ref(false);
        var ongkir = 0;
        const storeLocation = reactive({
            latitude: 0,
            longitude: 0,
        });
        const modal = ref(null);
        const modalNotif = ref(null);

        onMounted(() => {
            document.getElementById('button_lanjut').disabled = true;
            modal.value = new Modal(modalNotif.value);
            modal.value.show();
        });


        function detailAntarJemput(){
            var lat = localStorage.getItem("antar_lat")
            var lng = localStorage.getItem("antar_lng")
            var address = document.getElementById('lokasi').value
            localStorage.setItem('antar_address', address)
            if(lat && lng){
                // pergi ke detail antar jemput
                if(address){
                    goToDetail()
                }else{
                    createToast('Kami memerlukan alamat lengkap, agar driver bisa sampai tujuan dengan cepat!',
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                }
                
            }else{
                createToast('Sepertinya ada yang salah dengan koordinat lokasi anda, coba klik lagi pada peta!',
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
            }
        }

        function goToDetail() {
            return router.push({
                name: "AntarJemputDetail",
            });
        }

        return {
            token,
            user,
            ongkir,
            storeLocation,
            detailAntarJemput,
            goToDetail,
            isLoading,
            modal,
            modalNotif
        }
    },
    data() {
        return {
            userLocation: {},
            tileProvider: {
                attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            },
            zoom: 15,
            markerLatLng: [-8.6539493, 115.2278344],
            setView: [-8.6539493, 115.2278344],
            geosearchOptions: { // Important part Here
                provider: new OpenStreetMapProvider(),
            }
        };
    },
    mounted() {
        this.getUserPosition();
        // const map = this.$refs.map.mapObject;
        // // const provider = new OpenStreetMapProvider()
        // const searchControl = new GeoSearchControl({
        //     provider: new OpenStreetMapProvider(),
        // });
        // map.addControl(searchControl);
    },
    methods: {
        async getUserPosition() {
        // check if API is supported
        if (navigator.geolocation) {
            // get  geolocation
            navigator.geolocation.getCurrentPosition(pos => {
                // set user location
                this.userLocation = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude
                };
            });
        }
        },
        removeMarker(index) {
            this.markers.splice(index, 1);
        },
        addMarker(event) {
            
            if(event.latlng){
                this.isLoading = true
                console.log("hasil klik: ", event.latlng)
                var latitude_data = event.latlng.lat
                var longitude_data = event.latlng.lng
                this.markerLatLng = [latitude_data, longitude_data];
                this.storeLocation.latitude = latitude_data
                this.storeLocation.longitude = longitude_data
                localStorage.setItem('antar_lat', latitude_data)
                localStorage.setItem('antar_lng', longitude_data)
                

                axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
                axios
                .post("antar-jemput/courier/ongkir", this.storeLocation)
                .then((response) => {
                    this.isLoading = false
                    console.log("Response API Ongkir: ", response);
                    if (response.data.status == "success") {
                        document.getElementById('button_lanjut').disabled = false;
                        console.log("sukses!")
                        var base_ongkir = parseInt(response.data.data)
                        var base_diskon = 0
                        localStorage.setItem('antar_ongkir', base_ongkir-base_diskon)
                        this.ongkir = base_ongkir-base_diskon
                        // var total_ongkir = parseInt(base_ongkir - base_diskon)
                        document.getElementById("total_ongkir").innerHTML = 'Rp. ' + base_ongkir
                        document.getElementById("base_ongkir").innerHTML = 'Rp. ' + base_ongkir
                        document.getElementById("base_total").innerHTML = 'Rp. ' + base_diskon > 0 ? base_diskon : '0'
                        
                    }
                })
                .catch((err) => {
                    this.isLoading = false
                    console.log("ERR Response API Ongkir: ",err.response);
                    // createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
                    // {
                    //     type: 'danger',
                    //     timeout: 5000,
                    //     showIcon: 'true',
                    //     transition: 'zoom'
                    // })
                });
            }else{
                this.isLoading = false
                console.log("empty coordinate!")
            }
        }
    }
}
</script>

<style scoped>
    .modal .notif-img{
        width: 150px;
        height: auto;
        margin-bottom: 30px;
    }
    .modal .info-txt{
        font-weight: 600;
    }
    .leaflet-container{
        height: 40vh !important;
        width: 100vw !important;
        position: relative;
        z-index: 1;
    }
    .sheet{
        background: white;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        height: 50vh;
        padding: 20px;
    }
    .sheet .detail .list{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sheet .detail .list .sub{
        font-size: 10pt;
        margin-bottom: 5px;
    }
    .sheet .detail .list .total{
        font-size: 12pt;
        margin-bottom: 5px;
        font-weight: 800;
    }
    .sheet .detail{
        position: relative;
        top: 20px;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }
    .sheet .button{
        margin-top: 10px;
    }
    .sheet .button .btn{
        width: 100%;
    }
</style>