import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap/dist/js/bootstrap.bundle'

import ImageUploader from 'vue-image-upload-resize'

import './assets/css/main.css'
import QrReader from 'vue3-qr-reader'
import VueHtmlToPaper from './vue-html-to-paper'
import loader from "vue-ui-preloader";

import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import Select2 from 'vue3-select2-component'

// import OtpInput from '@bachdgvn/vue-otp-input'

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
        './assets/css/main.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

const app = createApp(App)


import axios from 'axios'
import './registerServiceWorker'

// ======== Admin Staging Laundry =========
axios.defaults.baseURL = 'https://adminlaundry.bithouse.id/api/'

// ==== Global Image Url Staging Laundry BIT House
app.config.globalProperties.imageUrl = 'https://adminlaundry.bithouse.id';

// ==== Global Image Url Live Laundry ====
// app.config.globalProperties.imageUrl = 'https://admin.wabo.co.id';

// ======== Admin live Laundry =========
// axios.defaults.baseURL = 'https://admin.wabo.co.id/api/'

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer' + localStorage.getItem('token')
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Method'] = 'GET', 'POST';


app.use(router).use(QrReader).component('Select2', Select2).use(ImageUploader).use(VueHtmlToPaper, options).use(loader).mount('#app')
