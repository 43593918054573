<template>
    <nav class="navbar navbar-expand-lg navbar-light sticky-top" id="nav">
        <!-- <img src="../assets/images/icon/decor-circle2.svg" class="nav-decor" alt=""> -->
        <img src="../assets/images/icon/decor-circle.svg" class="nav-decor-c" alt="">
        <div class="container nav-inner">
            <router-link class="navbar-brand" :to="role == 'driver' ? '/home' : '/'">
                <img src="../assets/images/WABO_Logo.png" class="me-2 my-2" alt="">
            </router-link>
            <button class="navbar-toggler" type="button" @click.prevent="showOffcanvasMenu()">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="navbar-nav ms-auto">
                    <router-link class="nav-link" to="/home-kurir">Home</router-link>
                    <!-- <router-link class="nav-link" to="/loker-list">Scan Loker</router-link> -->
                    <router-link class="nav-link" to="/riwayat-jemput">Riwayat Antar Jemput</router-link>
					<!-- <router-link class="nav-link" to="/riwayat-antar">Riwayat Antar</router-link> -->
                    <!-- <router-link class="nav-link" to="/profile">Profil</router-link>
                    <router-link class="nav-link" to="/booking">Booking</router-link> -->
                    <a class="nav-link logout" @click.prevent="logout">Logout</a>
                </div>
            </div>

            <div class="offcanvas offcanvas-start" :class="showMenu ? 'show' : ''" tabindex="-1" :style="{ visibility: showMenu ? 'visible' : 'hidden' }">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title d-flex align-items-center me-3" id="offcanvasExampleLabel">
                        <router-link :to="role == 'driver' ? '/home' : '/'">
							<img src="../assets/images/WABO_Logo.png" class="" alt="">
						</router-link>
                    </h5>
                    <button type="button" class="btn-close text-reset" @click.prevent="showOffcanvasMenu()"></button>
                </div>
                <hr class="bg-secondary mx-3">
                <div class="offcanvas-body">
                    <div class="navbar-nav text-start">
                        <router-link class="nav-link" to="/home-kurir" @click.prevent="showOffcanvasMenu()">Home</router-link>
						<!-- <router-link class="nav-link" to="/loker-list" @click.prevent="showOffcanvasMenu()">Scan Loker</router-link> -->
						<router-link class="nav-link" to="/riwayat-jemput" @click.prevent="showOffcanvasMenu()">Riwayat Antar Jemput</router-link>
						<!-- <router-link class="nav-link" to="/riwayat-antar" @click.prevent="showOffcanvasMenu()">Riwayat Antar</router-link> -->
						<!-- <router-link class="nav-link" to="/profile" @click.prevent="showOffcanvasMenu()">Profil</router-link>
						<router-link class="nav-link" to="/booking" @click.prevent="showOffcanvasMenu()">Booking</router-link> -->
						<a class="nav-link logout" @click.prevent="logout">Logout</a>
                    </div>
                </div>
            </div>

        </div>
    </nav>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

export default {
    data(){
		return {
			showMenu: false
		}
	},
	methods: {
		showOffcanvasMenu(){
			this.showMenu ? this.showMenu = false : this.showMenu = true;
		}
	},
	setup(){
		const token = localStorage.getItem('token')
		const router = useRouter()
		const role = ref(localStorage.getItem('role') ? localStorage.getItem('role') : 'driver')

		function logout() {

			axios.interceptors.response.use( (response) => {
				// Return a successful response back to the calling service
				return response;
			}, (error) => {
				// Return any error which is not due to authentication back to the calling service
				if (error.response.data.status !== 401) {
					// return new Promise((resolve, reject) => {
					// 	reject(error);
					// });
					localStorage.removeItem('token')
					window.location.reload()
					return router.push({
						name: 'auth'
					})
				}
			})
			
			axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.post('logout')
            .then(response => {

                if(response.data.status == 'success') {

                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    localStorage.removeItem('username')

                    return router.push({
                        name: 'auth'
                    })

                }

            })
            .catch(error => {
                console.log(error.response.data)
            })

        }

		return{
			logout,
			router,
			token,
			role
		}
	}
}
</script>

<style>
    .logout{
		cursor: pointer;
	}
    #nav .navbar-nav a.router-link-exact-active {
		color: var(--primaryColor) !important;
	}
	.navbar .navbar-brand img{
		height: 70px;
	}
	.navbar{
		height: 100px;
		position: relative;
		background: linear-gradient(149deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
		box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
	}
	.navbar img.nav-decor{
		/* width: 100px; */
		height: 140%;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: -20px;
		z-index: 1;
	}
	.navbar img.nav-decor-c{
		/* width: 100%; */
		height: 100%;
		object-fit: cover;
		position: absolute;
		right: 1px;
		top: -80px;
		z-index: 1;
		margin-top: -20px;
		opacity: 0.8;
	}
	.navbar .nav-inner{
		position: relative;
		z-index: 2;
	}
	.offcanvas .offcanvas-body .navbar-nav a.nav-link{
		color: var(--darkGrey) !important;
		font-weight: 600;
	}
	.offcanvas .offcanvas-body .navbar-nav a.router-link-exact-active{
		color: var(--blueColor) !important;
	}
	
	@media (max-width: 768px){
		.offcanvas{
			width: 80% !important;
		}
		.offcanvas .offcanvas-title img{
			height: 80px;
			width: auto;
		}
		.offcanvas hr{
			margin-top: 0;
		}
	}
</style>