<template>
<Navbars></Navbars>
    <div class="pickup">
        <div class="container">
            <h3 class="mt-5 mb-4 text-secondary"><b>List PickUp</b></h3>

            <div class="table-responsive my-5">
                <table class="table table-bordered table-striped table-hover" id="list_pickup">
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Fullname</th>
                            <th>Kode Laundry</th>
                            <th>Jenis Layanan</th>
                            <th>Alamat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(list, index) in histories" :key="index">
                            <td>
                                <div v-if="list.status == 'Pickup by Driver'">
                                    <span class="badge bg-info">{{list.status}}</span>
                                </div>
                            </td>
                            <td>
                                <div v-if="list.status == 'Pickup by Driver'">
                                    {{list.fullname}}
                                </div>
                            </td>
                            <td>
                                <div v-if="list.status == 'Pickup by Driver'">
                                    {{list.laundry_code}}
                                </div>
                            </td>
                            <td>
                                <div v-if="list.status == 'Pickup by Driver'">
                                    {{list.locker_name}}
                                </div>
                            </td>
                            <td>
                                <div v-if="list.status == 'Pickup by Driver'">
                                    {{list.address}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>
</template>

<script>
import Navbars from '@/components/Navbars.vue'
import { onMounted, nextTick, ref } from 'vue'
import axios from 'axios'

//Datatable Modules
import "datatables.net-bs5/js/dataTables.bootstrap5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import $ from 'jquery'
import { useRouter } from 'vue-router'

export default {
    name: 'Pickup',
    components: {
        Navbars,
    },
    setup(){
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'
        const router = useRouter();
        const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
        const histories = ref([])

        onMounted(() => {
            if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`driver-history-lockers/${user}`)
			.then(result => {
				histories.value = result.data.data
				console.log('List Pickup Driver g: ',result.data.data)
                getHistory()
			}).catch((err) => {
				console.log(err.response)
			})
		})

		function getHistory(){
			nextTick(() => {
				$('#list_pickup').DataTable({
                    ordering:  false
                })
			})
		}
        return{
			getHistory,
            role,
            router,
            histories
		}
    }
}
</script>

<style>

</style>