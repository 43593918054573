<template>
<Navbars></Navbars>
	<div class="home" ref="printcontent">
		<div class="container">
			<div class="head-box">
				<h3 class="title">Selamat Datang, {{name}}!</h3>

				<p class="desc">Berikut adalah list tujuan yang harus kamu pick up!</p>
				<p class="desc">Kamu bisa pilih daerah tujuan yang sudah admin WABO pilihkan!</p>

				<figure>
					<img src="../../assets/images/welcome-icon.svg" alt="">
				</figure>
			</div>

			<div class="row">
                <div class="col-md-12">
                    <div class="boxes-opt">
                        <router-link to="/loker-place" class="box-opt">
                            <figure>
                                <img src="../../assets/images/icon/locker.svg" class="icon_setrika" alt="">
                            </figure>
                            <div class="text-center">
                                <h6>Scan <br> Loker</h6>
                            </div>
                        </router-link>
                        <router-link to="/droppoint-place" class="box-opt">
                            <figure>
                                <img src="../../assets/images/icon/droppoint.svg" class="icon_setrika" alt="">
                            </figure>
                            <div class="text-center">
                                <h6>Scan <br> Drop Point</h6>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

			<!-- <button @click="printThis" class="btn btn__primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 16">
                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                </svg>
                Print
            </button> -->

			<!-- <div class="history">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">Riwayat Scan Laundry</h5>
					</div>
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-hover">
								<thead class="text-dark">
									<tr class="align-middle">
										<th class="text-center">Kode Laundry</th>
										<th class="text-center">Kg</th>
										<th class="text-center">Harga</th>
										<th class="text-center">Metode Pembayaran</th>
										<th class="text-center">status</th>
										<th class="text-center">Nama</th>
										<th class="text-center">Jenis Kelamin</th>
										<th class="text-center">Alamat</th>
										<th class="text-center">Telepon</th>
										<th class="text-center">Email</th>
										<th class="text-center">Nama Loker</th>
										<th class="text-center">Kode Nomor</th>
									</tr>
								</thead>
								<tbody v-if="histories">
									<tr v-for="riwayat in histories" :key="riwayat.id">
										<td class="text-center">{{riwayat.laundry_code}}</td>

										<td v-if="riwayat.kg == null" class="text-center">-</td>
										<td v-else class="text-center">{{riwayat.kg}}</td>

										<td v-if="riwayat.price == null" class="text-center">-</td>
										<td v-else class="text-center">{{riwayat.price}}</td>

										<td v-if="riwayat.payment_method == null" class="text-center">-</td>
										<td v-else class="text-center">{{riwayat.payment_method}}</td>

										<td class="text-center" v-if="riwayat.status == 'Proses'">
											<span class="badge bg-primary">{{riwayat.status}}</span>
										</td>
										<td class="text-center" v-else-if="riwayat.status == 'Belum Lunas'">
											<span class="badge bg-warning text-dark">{{riwayat.status}}</span>
										</td>
										<td class="text-center" v-else-if="riwayat.status == 'Selesai'">
											<span class="badge bg-light text-dark">{{riwayat.status}}</span>
										</td>
										<td class="text-center" v-else-if="riwayat.status == 'Siap Diambil'">
											<span class="badge bg-secondary">{{riwayat.status}}</span>
										</td>
										<td class="text-center" v-else>
											<span class="badge bg-success">{{riwayat.status}}</span>
										</td>

										<td class="text-center">{{riwayat.fullname}}</td>

										<td class="text-center">{{riwayat.gender}}</td>

										<td class="text-center">{{riwayat.address}}</td>

										<td class="text-center">{{riwayat.phone_number}}</td>

										<td class="text-center">{{riwayat.email}}</td>

										<td class="text-center">{{riwayat.locker_name}}</td>

										<td class="text-center">{{riwayat.number_code}}</td>
										
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="12" class="text-center">Belum ada riwayat order</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="card-footer">
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Navbars from '@/components/Navbars.vue'
import { onMounted,ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import html2canvas from 'html2canvas'

export default {
	name: 'Home',
	components: {
		Navbars,
	},
	data(){
		return{
			printNow: false,
		}
	},
	methods: {
		async printThis() {
			this.printNow = true
            console.log("printing..");
            const el = this.$refs.printcontent;

            const options = {
                type: "dataURL",
                // width: 302,
            };
            const printCanvas = await html2canvas(el, options);

            const link = document.createElement("a");
            link.setAttribute("download", "download.png");
            link.setAttribute(
                "href",
                printCanvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream")
            );
            link.click();
            console.log("done");
			// this.printNow = false
        }
	},
	setup(){
		const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
		const name = localStorage.getItem('name')
		const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'
		const lokers = ref([])
		const profile = ref([])
		const router = useRouter()
		// pengecekan role jika yng mencoba akses adalah cusgtomer maka redirect ke halaman dashboard utk customer
		

		onMounted(() => {
			if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}else if(role == 'courier'){
				return router.push({
					path: '/home-kurir'
				})
			}

			axios.get('profile/'+(user))
            .then(response => {
                console.log('GET Profile', response.data.data)
                profile.value = response.data.data
                
            }).catch((err) => {
				if(err.response.data.status_code == 401)
				{
					localStorage.clear()
					router.push({ name: 'auth' });
				}
				console.log("Error Profile", err.response)
			})
			
			axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`driver/lockers/${user}`)
			// axios.get('history-lockers')
			.then(result => {
				console.log(result)
				lokers.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

		onMounted(() => {
			axios.interceptors.response.use( (response) => {
				// Return a successful response back to the calling service
				return response;
			}, (error) => {
				// Return any error which is not due to authentication back to the calling service
				if (error.response.data.status === 401) {
					// return new Promise((resolve, reject) => {
					// 	reject(error);
					// });
					localStorage.removeItem('token')
					// window.location.reload()
					return router.push({
						name: 'auth'
					})
				}
			})
		})

		return{
			token,
			name,
			user,
			lokers,
			role,
			profile
		}
	}
}
</script>

<style scoped>
	.print{
		width: 302px;
	}
	.boxes-opt{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 10px;
    }
    .box-opt{
        background: var(--blueGradient);
        padding: 20px;
        text-decoration: none;
        border-radius: 10px;
        box-shadow: 0 5px 20px rgba(162, 36, 144, 0.3);
        margin: 20px;
        display: block;
        vertical-align: middle;
        /* align-items: center;
        justify-content: center; */
        width: 200px;
        height: 200px;
    }
    .box-opt:hover{
        background: var(--darkBlueG);
    }
    .box-opt:hover > figure img{
        filter: brightness(100);
    }
    .box-opt:hover h6{
        color: white;
    }
    .box-opt figure{
        height: 70%;
        display: flex;
        align-items: center;
    }
    .box-opt figure img{
        display: block;
        margin: 0 auto;
		filter: brightness(100);
    }
    .box-opt h6{
        color: white;
        font-weight: 600;
        text-transform: uppercase;
    }

    @media (max-width: 528px){
        /* .boxes-opt{
           display: ;
           margin: 80px 10px;
        } */
        .box-opt{
			width: 50%;
			height: 160px;
            margin: 0px 10px;
        }
		.box-opt figure{
			height: 50%;
		}
		.box-opt figure img{
			height: 80%;
		}
    }
	@media (max-width: 320px){
		.box-opt{
			padding: 10px;
		}
	}


	h1.title{
		font-weight: 600;
		margin-bottom: 30px;
		color: var(--secondaryColor);
	}

	/* ===== Welcome Text ====== */
	.home .head-box{
		position: relative;
		background: #f2f2f2;
		border-radius: 10px;
		padding: 20px;
		margin: 50px 0 60px;
	}
	.home .head-box .title{
		color: var(--darkBlue);
		font-weight: 800;
		margin-bottom: 20px;
		margin-right: 260px;
		text-align: left;
	}
	.home .head-box .desc{
		color: var(--darkGrey);
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 260px;
	}
	.home .head-box figure{
		position: absolute;
		bottom: -15px;
		right: -20px;
	}
	.home .head-box figure img{
		width: 250px;
		height: auto;
	}
	/* ===== Welcome Text ====== */
	

	@media (max-width: 768px){
		/* ===== Welcome ===== */
		.home .head-box{
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 40px;
			padding: 10px;
		}
		.home .head-box .title{
			font-size: 12pt;
			margin-right: 110px;
		}
		.home .head-box .desc{
			font-size: 8pt;
			margin-right: 110px;
		}
		.home .head-box figure{
			width: 40%;
		}
		.home .head-box figure img{
			width: 100%;
			height: auto;
		}
		/* ===== Welcome ===== */
	}
</style>
