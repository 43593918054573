<template>
<Navbark></Navbark>
	<div class="jemput mt-5">
		<div class="container">
			<div class="courier">
				<div class="c-header">
					<h5 class="c-title">Riwayat Antar</h5>
				</div>
                <div class="c-body">
                    <div class="table-responsive">
						<table class="table table-hover table-sm table-striped">
							<thead>
								<tr>
									<!-- <th class="text-center align-middle">Aksi</th> -->
									<th class="align-middle">Nama Customer</th>
									<th class="align-middle">Kode Laundry</th>
									<th class="align-middle">Alamat</th>
									<th class="align-middle">Wilayah Droppoint</th>
									<th class="align-middle">Note</th>
								</tr>
							</thead>
							<tbody v-for="(list, index) in orderAJ" :key="index">
								<!-- <div v-for="list in orderAJ" :key="list.id"> -->
								<tr v-for="sublist in list.listAntarJemput" :key="sublist.id">
									<!-- <td>
										<div>
											<button class="btn btn__primary tbtn" @click="goToScan()">
												<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
													<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
												</svg>
												Scan
											</button>
										</div>
									</td> -->
									<td>{{sublist.fullname}}</td>
									<td>{{sublist.laundry_code}}</td>
									<td>{{sublist.address}}</td>
									<td>{{sublist.locker_name}}</td>
									<td>{{sublist.note}}</td>
								</tr>
								<tr v-show="index === 0">
									<td colspan="6" v-if="list.listAntarJemput.length == 0" class="text-center">Tidak Ada Data</td>
								</tr>
								<!-- </div> -->
							</tbody>
						</table>
					</div>
                </div>
            </div>

		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Navbark from '@/components/Navbark.vue'
import { onMounted,ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
// import { Modal } from 'bootstrap'

export default {
	name: 'riwayat-jemput',
	components: {
		Navbark,
	},
	setup(){
		const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
		const name = localStorage.getItem('name')
		const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'courier'
		const orderAJ = ref([])
		const router = useRouter()
		// pengecekan role jika yng mencoba akses adalah cusgtomer maka redirect ke halaman dashboard utk customer

		onMounted(() => {
			if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}else if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
			axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`antar-jemput/courier/barang-siap-ambil-droppoint/${user}`)
			.then(result => {
				console.log('List Antar: ',result.data.data)
				orderAJ.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

		function goToScan(){
			return router.push({
				path: '/order-scan'
			})
		}

		return{
			token,
			name,
			user,
			orderAJ,
			role,
			goToScan,
		}
	}
}
</script>

<style scoped>
	h1.title{
		font-weight: 600;
		margin-bottom: 30px;
		color: var(--secondaryColor);
	}

	/* ===== Welcome Text ====== */
	.home .head-box{
		position: relative;
		background: #f2f2f2;
		border-radius: 10px;
		padding: 20px;
		margin: 50px 0 60px;
	}
	.home .head-box .title{
		color: var(--darkBlue);
		font-weight: 800;
		margin-bottom: 20px;
		margin-right: 260px;
	}
	.home .head-box .desc{
		color: var(--darkGrey);
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 260px;
	}
	.home .head-box figure{
		position: absolute;
		bottom: -15px;
		right: -20px;
	}
	.home .head-box figure img{
		width: 250px;
		height: auto;
	}
	/* ===== Welcome Text ====== */
	
	/* ===== Antar Jemput List ====== */
	.courier .c-header .c-title{
		font-weight: 800;
		color: var(--secondaryColor);
	}
	.courier .c-body .table thead{
		/* background: #e9cce5;
		color: var(--darkBlue); */
		border-bottom: 2px solid var(--primaryColor) !important;
		border-top: 2px solid var(--primaryColor) !important;
	}
	.courier .c-body .table tr td .tbtn{
		padding: 5px;
		font-size: 10pt;
		border-radius: 7px;
		margin: 5px;
		font-weight: 600;
	}
	.courier .c-body .table tr td .btnAmbil{
		font-size: 9pt;
		padding: 5px;
		margin: 5px;
		border-radius: 7px;
		background: #ffad50;
		font-weight: 600;
	}
	/* ===== Antar Jemput List ====== */

	@media (max-width: 768px){
		/* ===== Welcome ===== */
		.home .head-box{
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 40px;
			padding: 10px;
		}
		.home .head-box .title{
			font-size: 12pt;
			margin-right: 110px;
		}
		.home .head-box .desc{
			font-size: 8pt;
			margin-right: 110px;
		}
		.home .head-box figure{
			width: 40%;
		}
		.home .head-box figure img{
			width: 100%;
			height: auto;
		}
		/* ===== Welcome ===== */

		.courier .c-body .table thead tr th{
			font-size: 9pt;
		}
	}
</style>
