<template>
    <div class="intro">
        <div class="head d-flex align-items-center">
            <img src="../assets/images/WABO_Logo.png" class="logo" alt="">
            <router-link to="/login" class="skip">Skip</router-link>
        </div>
		<swiper class="slider" :navigation="{ nextEl: '.b-next', prevEl: '.b-prev', }" :pagination="{ el: '.swiper-pagination', dynamicBullets: true, clickable: true }">
            <swiper-slide>
                <figure class="text-center">
                    <img src="../assets/images/slide1.png" alt="">
                </figure>
                <div class="swiper-nav-next b-next text-center">
                    <button class="btn btn__primary">Selanjutnya</button>
                </div>
            </swiper-slide>
            <swiper-slide>
                <figure class="text-center">
                    <img src="../assets/images/slide2.png" alt="">
                </figure>
                <div class="swiper-nav-next b-next text-center">
                    <button class="btn btn__primary">Selanjutnya</button>
                </div>
            </swiper-slide>
            <swiper-slide>
                <figure class="text-center">
                    <img src="../assets/images/slide3.png" alt="">
                </figure>
                <div class="b-next text-center">
                    <button class="btn btn__primary" @click="start()">Mulai</button>
                </div>
            </swiper-slide>
            <!-- <swiper-slide>
                <div class="title my-3">
                    <h5 class="text-center fw-bold mb-3">Selamat Datang di Wabo,</h5>
                    <h5 class="text-center">One Stop Laundry Service!</h5>
                </div>
                <figure class="text-center">
                    <img src="../assets/images/slider-icon1.svg" alt="">
                </figure>
                <div class="swiper-nav-next b-next text-center">
                    <button class="btn btn__primary">Selanjutnya</button>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="title my-3">
                    <h5 class="text-center">Simpel, Higienis, & Terjangkau.</h5>
                </div>
                <br>
                <br>
                <br>
                <figure class="text-center">
                    <img src="../assets/images/slider-icon2.svg" alt="">
                </figure>
                <div class="swiper-nav-next b-next text-center">
                    <button class="btn btn__primary">Selanjutnya</button>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="title my-3">
                    <h5 class="text-center">Eco Friendly Laundry</h5>
                </div>
                <br>
                <br>
                <br>
                <figure class="text-center">
                    <img src="../assets/images/slide-icon3.svg" alt="">
                </figure>
                
            </swiper-slide> -->
                <!-- <div class="b-next text-center">
                    <button class="btn btn__primary" @click="start()">Mulai</button>
                </div> -->
            <div class="swiper-pagination"></div>
        </swiper>
    </div>
</template>

<script>
// import { ref } from 'vue'
import { useRouter } from 'vue-router'

import SwiperCore, { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Autoplay, Pagination, Scrollbar, A11y])

export default {
    name: 'Slide',
    components: {
		Swiper,
		SwiperSlide,
	},
    setup(){
        const router = useRouter();

        function start(){
            router.push({
                name: 'auth'
            })
        }

        return{
            start,
            router
        }
    }
}
</script>

<style scoped>
    .intro{
        position: fixed;
        background: white;
        z-index: 999;
        width: 100%;
        height: calc(100vh - 60px);
        top: 0;
        left: 0;
    }
    /* .intro figure img{
        width: 22%;
    } */
    .intro .slider{
        padding: 0 0 20px;
        height: calc(100vh - 60px);
        /* background: cornflowerblue; */
    }
    .intro .slider .swiper-slide figure img{
        height: 70%;
        margin: 5px 0;
    }
    .intro .head{
        justify-content: space-between;
        margin: 20px 10% 0;
    }
    .intro .head img{
        width: 60px;
    }
    .intro .head a{
        color: var(--darkGrey);
        text-decoration: none;
        font-weight: 500;
    }
    /* .intro .slider .swiper-slide img{
        width: 90%;
    } */
    .intro .slider .swiper-pagination{
        margin: 0px 0;
    }
    .intro .slider .swiper-pagination .swiper-pagination-bullet-active{
        background: var(--primaryColor);
    }
    .intro .slider .b-next button.btn__primary{
        width: 20%;
        padding: 10px;
        margin: 0px 0;
    }
    @media (max-width: 767.98px){
        .intro .head{
            justify-content: space-between;
            margin: 20px 10% 0;
        }
        .intro .head img{
            width: 30%;
        }
        .intro{
            height: calc(100vh - 60px);
        }
        .intro figure img{
            width: 82% !important;
        }
        .intro .slider .swiper-slide figure img{
            height: 80%;
            margin: 0px 0;
        }
        .intro .slider{
            /* background: darkslategray; */
            /* height: calc(100vh - 60px); */
        }
        .intro .slider .b-next button.btn__primary{
            width: 90% !important;
        }
        .intro .slider .swiper-pagination{
            margin: 0px 0;
            position: fixed;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    @media (max-width: 575.98px){
        .intro .slider{
            height: calc(100vh - 120px);
            /* background: coral; */
        }
        .intro figure img{
            height: 75% !important;
            margin: 0px 0;
        }
        .intro .slider .swiper-slide figure img{
            height: 80% !important;
            margin: 0px 0;
        }
        .intro .slider .swiper-slide{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: -6% 0 5px;
        }
        .intro .slider .b-next button.btn__primary{
            width: 75% !important;
            /* position: relative;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%); */
        }
    }

</style>