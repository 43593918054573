<template>
<Navbars></Navbars>
    <div class="pin">
        <div class="container">
            <div class="data">
                <div class="row">
                    <div class="col-lg-4 mx-auto">
                        <h5 class="text-center text-primary data__title mt-5">Masukkan PIN</h5>
                        <!-- <div class="pinInput">
                            <input type="number" pattern="/^-?\d+\.?\d*$/" maxlength="1" onKeyPress="if(this.value.length==1) return false;">
                            <input type="text" autocomplete="false" pattern="[0-9]*" id="sec" maxlength="1">
                            <input type="text" autocomplete="false" pattern="[0-9]*" id="third" maxlength="1">
                            <input type="text" autocomplete="false" pattern="[0-9]*" id="fourth" maxlength="1">
                            <input type="text" autocomplete="false" pattern="[0-9]*" id="fifth" maxlength="1">
                            <input type="text" autocomplete="false" pattern="[0-9]*" id="six" maxlength="1">
                        </div> -->
                        <form @submit.prevent="CheckPin()">
                            <input type="hidden" class="form-control" v-model="pinexpired.locker_number_id">
                            <input type="hidden" class="form-control" v-model="pinexpired.user_id">
                            <div id="divOuter">
                                <div id="divInner">
                                    <input id="partitioned" v-model="pinexpired.pin" autocomplete="false" type="text" maxlength="6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==6) return false;"/>
                                </div>
                            </div>
                            
                            <button type="submit" class="btn btn__primary">
                                Masukkan PIN
                            </button>
                            <!-- <button class="btn btn__primary" @click="modal.show()">
                                Masukkan PIN
                            </button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbars from '@/components/Navbars.vue'
// import { Modal } from 'bootstrap'
import { onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import mqtt from 'mqtt'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'Pin',
    components: {
        Navbars,
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const number = route.params.number
        const profile = ref([])
        // const numberCode = route.params.kode
        localStorage.setItem('scan_type', 'expired')
        const locker_number_id = localStorage.getItem('locker_number_id')
        const pinexpired = reactive({
            locker_number_id: locker_number_id,
            pin: '',
            user_id: user
        })

        // Setting koneksi Ke loker
        const connection = reactive({
            host: 'm13.cloudmqtt.com',
            port: 37475,
            endpoint: '',
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 4000,
            
            clientId: user + Math.floor((Math.random() * 100000000000) + 1),
            username: 'ouxpjuzq',
            password: 'zMtEaDTaqXmx',
        })

        var publish = reactive({
            topic: 'loker',
            qos: 0,
            payload: '',
        })

        let client = ref(false)
        // End Setting koneksi ke loker

        onMounted(() => {
            if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}
            createToast('Berhasil! Kode Pin sudah dikirimkan lewat emailmu. Segera cek email (spam/promotions) untuk mengetahui kode pin milikmu',
            {
            type: 'success',
            timeout: 5000,
            showIcon: 'true',
            transition: 'zoom'
            })
        })

        onMounted (() => {
            let { host, port, ...options } = connection
            let connectUrl = `wss://${host}:${port}`
            try {
                client.value = mqtt.connect(connectUrl, options)
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
            client.value.on('connect', () => {
                console.log('Connection succeeded!')
            })
            client.value.on('error', error => {
                console.log('Connection failed', error)
            })
            client.value.on('message', (topic, message) => {
                this.receiveNews = this.receiveNews.concat(message)
                console.log(`Received message ${message} from topic ${topic}`)
            })
        })

        function doPublish() {
            let { topic, qos, payload } = publish
            client.value.publish(topic, payload, qos, error => {
                if (error) {
                    console.log('Publish error', error)
                }
            })
        }

        function CheckPin(){
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.post('driver/check-pin-expired', pinexpired)
            .then(response => {
                console.log(response.data)
                if(response.data.status == 'success'){
                    localStorage.removeItem('laundry_id')
                    localStorage.removeItem('locker_number_id')

                    let id_loker = localStorage.getItem('id_loker_scan')
                    publish = reactive({
                        topic: 'loker',
                        qos: 0,
                        payload: id_loker.toLowerCase() + '_open',
                    })
                    console.log('Data To Loker Device: ', publish)
                    doPublish()

                    return router.push({
                        name: 'detail-data',
                        params: number
                    })
                }else{
                    createToast('Ops.. Terjadi Kesalahan Sistem. Silahkan Hubungi Admin ERR Code: ' + response.data.status_code.toString(),
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                    this.message = 'Ops, terjadi kesalahan sistem, Hubungi Admin Sistem, Err Code: ' + response.data.status_code.toString()
                }
                document.getElementById("message_response").innerHTML = this.message
            }).catch((err) => {
				console.log(err.response)
                if(err.response.status.toString() == '404'){
                    createToast('Pin yang anda inputkan salah!',
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                }else{
                    createToast('Ops.. Terjadi Kesalahan Sistem. Silahkan Hubungi Admin ERROR CODE: ' + err.response.status,
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                }
                document.getElementById("message_response").innerHTML= 'Ops, terjadi kesalahan sistem, Hubungi Admin Sistem'
			})
        }

        return{
            profile,
            route,
            router,
            token,
            user,
            pinexpired,
            CheckPin,
            doPublish,
            client,
            connection,
            publish,
            role,
        }
    }
}
</script>

<style>
    .pin .data #partitioned {
        padding-left: 20px;
        letter-spacing: 31px;
        border: 0;
        background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 40px 1px;
        background-repeat: repeat-x;
        background-position-x: 35px;
        width: 265px;
        min-width: 250px;
    }
    .pin .data #partitioned:focus{
        outline: none;
        border: none;
        box-shadow: none;
        background-image: linear-gradient(to left, rgb(13, 110, 253) 70%, rgba(255, 255, 255, 0) 0%);
    }

    .pin .data #divInner{
        left: 0;
        position: sticky;
    }

    .pin .data #divOuter{
        width: 240px; 
        overflow: hidden;
        display: block;
        margin: 0 auto;
    }
    /* .pin .data .pinInput .otp-input {
        width: 40px;
        height: 40px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: "center";
    } */
    .pin .data .data__title{
        font-weight: 600;
        margin-bottom: 50px;
    }
    .pin .data .pinInput{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
    }

    /* .pin .data input{
        margin: 5px;
        height: 40px;
        width: 40px;
        border-radius: 5px;
        text-align: center;
        font-size: 1.2rem;
        border: 1px solid var(--lightGrey);
        transition: all 0.2s ease;
    }
    .pin .data input:focus{
        border: 1px solid var(--blueColor) !important;
        box-shadow: 0 0 5px rgba(0,123,255,1);
        outline: none;
    } */

    @media (max-width: 768px){
        .pin .data .data__title{
            font-weight: 600;
            margin-bottom: 30px;
        }

        .pin .data #partitioned {
            padding-left: 20px;
            letter-spacing: 31px;
            border: 0;
            background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 40px 1px;
            background-repeat: repeat-x;
            background-position-x: 35px;
            width: 265px;
            min-width: 250px;
        }
        .pin .data #partitioned:focus{
            outline: none;
            border: none;
            box-shadow: none;
            background-image: linear-gradient(to left, rgb(13, 110, 253) 70%, rgba(255, 255, 255, 0) 0%);
        }

        .pin .data #divInner{
            left: 0;
            position: sticky;
        }

        .pin .data #divOuter{
            width: 240px; 
            overflow: hidden;
            display: block;
            margin: 0 auto;
        }
    }
</style>