<template>
<Navbarc></Navbarc>
    <div class="booking">
        <div class="container">
            <h3 class="mt-5 mb-4 title"><b>Drop Point Booking</b></h3>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12" v-for="loker in lokersNear" :key="loker.id" :data_id="loker.droppoint_id" :data_name="loker.name" :data_address="loker.address" @click="storeIdDropPoint">
                    <router-link :to="'/droppoint/booking/'+loker.droppoint_id" class="boxes" :data_id="loker.droppoint_id" :data_name="loker.name" :data_address="loker.address" @click="storeIdDropPoint">
                        <div class="box__lokers">
                            <div class="box"></div>
                            <img src="../../assets/images/icon/light-decor.svg" alt="" class="light-d">
                            <img src="../../assets/images/icon/dot-decor.svg" alt="" class="dot-d">
                            <div class="box__inner">

                                <h3 class="title text-center">{{loker.name}}</h3>

                                <div class="address">
                                    <h6>Alamat :</h6>
                                    <p class="desc">
                                        {{ loker.address }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
                
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
    name: 'BookingDropPoint',
    components: {
        Navbarc,
    },
    methods: {
        storeIdDropPoint: function(event){
            localStorage.setItem("id_drop_point", event.currentTarget.getAttribute('data_id'))
            localStorage.setItem("name_drop_point", event.currentTarget.getAttribute('data_name'))
            localStorage.setItem("address_drop_point", event.currentTarget.getAttribute('data_address'))

        }
    },
    setup(){
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const lokers = ref([]);
        const lokersNear = ref([]);
        const router = useRouter()
        localStorage.setItem('schema_id', 2)
        // const id_service = ''

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            var uri = window.location.href.split('?');
            if (uri.length == 2)
            {
            let vars = uri[1].split('&');
            let getVars = {};
            let tmp = '';
            vars.forEach(function(v){
                tmp = v.split('=');
                if(tmp.length == 2)
                getVars[tmp[0]] = tmp[1];
            });
            console.log(getVars);
            localStorage.setItem("service_id", getVars.service_id ? getVars.service_id : '');
            localStorage.setItem("service_name", getVars.service_name ? getVars.service_name : '');
            }
        })

        onMounted(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get('lockers')
			.then(result => {
                console.log(result)
				lokers.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
        })
        // onMounted(() => {
            
        // })

        onMounted(() => {
            // https://adminlaundry.bithouse.id/api/droppoint/near-me/{lat}/{long}
            if(navigator.geolocation)
            {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        let lat = position.coords.latitude
                        let long = position.coords.longitude
                        // console.log("Lokasi anda: ", position.coords.latitude , ",", position.coords.longitude)
                        // let lat = -8.653903134557638
                        // let long = 115.22866795310621
                        axios.defaults.headers.common.Authorization = `Bearer ${token}`
                        axios.get('droppoint/near-me/'+(lat)+'/'+(long))
                        .then(result => {
                            console.log(result)
                            lokersNear.value = result.data.data
                        }).catch((err) => {
                            console.log(err.response)
                        })
                    },
                    error => {
                        console.log(error.message)
                    }
                )
            } else
            {
                console.log("your browser does not support geolocation API");
            }
        })

        return{
            lokers,
            token,
            router,
            lokersNear,
            role,
        }
    }
}
</script>

<style scoped>
    .boxes{
        text-decoration: none;
    }
    .box__lokers{
        border-radius: 10px;
        background: url('../../assets/images/bg-droppoint.png');
        background-size: cover;
        background-position: center;
        padding: 10px;
        position: relative;
        box-shadow: 0 5px 20px rgba(30, 251, 218, 0.3);
        margin-bottom: 20px;
        z-index: 1;
        height: 200px;
    }
    .box__lokers .box{
        background: var(--secondaryColor);
        object-fit: cover;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        opacity: 0.7;
    }
    .box__lokers .light-d{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
    }
    .box__lokers .dot-d{
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
        opacity: 0.2;
    }
    .box__lokers .box__inner .empty{
        position: relative;
        z-index: 5;
    }
    .box__lokers .box__inner .empty .badge{
        font-size: 10pt;
        color: var(--secondaryColor);
        box-shadow: 0 3px 10px rgba(255, 173, 80, 0.3);
    }
    .box__lokers .box__inner .title{
        color: white;
        font-weight: 800;
        margin: 20px 0;
        position: relative;
        z-index: 5;
    }
    .box__lokers .box__inner .address{
        backdrop-filter: blur(5px);
        background: rgba(255,255,255,0.3);
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        padding: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 5;
        height: 85px;
    }
    .box__lokers .box__inner .address h6{
        font-weight: 800;
        color: white;
        font-size: 10pt;
    }
    .box__lokers .box__inner .address p.desc{
        color: white;
        margin-bottom: 0;
        font-size: 10pt;
        max-height: 45px;
        overflow-y: scroll;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255,1); 
    }

    @media (max-width: 767.98px){
        .box__lokers{
            margin: 0 50px 20px;
        }
    }
    @media (max-width: 549.98px){
        .box__lokers .light-d{
            width: 40%;
        }
        .box__lokers .dot-d{
            width: 40%;
        }
    }
</style>