<template>
<Navbarc></Navbarc>
    <div class="laundry">
        <div class="container">
            <h1 class="mt-3 text-center title fw-bold">
				Laundry Detail
			</h1>

            <div class="head-text">
                <h6 class="card-title"><span class="title">Date</span>: {{ dateTime(laundry.updated_at) }}</h6>
                <h6 class="card-title"><span class="title">Laundry Code</span>: <b class="ms-2">{{ laundry.laundry_code }}</b></h6>
                <h6 class="card-text"><span class="title">Laundry Status</span>: <span class="badge bg-primary ms-2">{{ laundry.status }}</span></h6>
                <h6 class="card-text"><span class="title">No. Loker</span>: <b class="ms-2">{{ laundry.number_code ? laundry.number_code : '-' }}</b></h6>
                <h6 class="card-text"><span class="title">Layanan</span>: <b class="ms-2">{{ laundry.locker_name ? laundry.locker_name : '-' }}</b></h6>
                <!-- <h6 class="card-text"><span class="title">Jenis Layanan</span>: <b class="ms-2">{{service_name2}}{{service_name}}</b></h6> -->
            </div>
            <div class="content">
                <p class="text_title">List Laundry</p>
                <div class="list_barang">
                    <div class="text_barang">
                        <p class="text_sub">{{service_name}}</p>
                    </div>
                    <div class="text_barang" v-for="(item, index) in laundryDetail" :key="index">
                        <p class="text_desc"><span class="list_item">-</span> {{item.cloth_name}} <span class="info">{{item.qty}}</span></p>
                    </div>
                    <div class="text_barang" v-for="(itemKilo, index) in laundryDetail2" :key="index">
                        <p class="text_sub">{{itemKilo.name}}</p>
                        <p class="text_desc"><b>Note:</b> 
                            <span class="info" v-if="itemKilo.parfum == 'include'">Parfum {{itemKilo.parfum}}</span>
                            <span class="info" v-else>{{itemKilo.parfum}}</span>
                        </p>
                        <p class="text_desc">{{itemKilo.note_service}}</p>
                        <p class="text_desc mt-3" v-if="itemKilo.note_admin"><b>Note Admin:</b></p>
                        <p class="text_subdesc" v-if="itemKilo.note_admin">{{itemKilo.note_admin}}</p>
                    </div>
                </div>
            </div>

            <router-link to="/" class="btn btn__primary back-btn d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                Kembali
            </router-link>

        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import { onMounted, ref } from '@vue/runtime-core'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useRouter } from "vue-router"
import moment from 'moment'

export default {
    name: 'detail-laundry',
    components: {
        Navbarc
    },
    setup(){
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const laundry = ref([])
        const laundryDetail = ref([])
        const laundryDetail2 = ref([])
        const route = useRoute()
        const router = useRouter()

        var service_name = ref([])
        var service_name2 = ref([])
        var list_services = new Array()
        var per_service = []

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice/${route.params.laundry_id}`)
			.then(result => {
				console.log('Laundry data: ', result)
				laundry.value = result.data.data
                // totalOngkir.ongkir = result.data.data.delivery_cost
                // console.log('ongkir: ', totalOngkir.ongkir)
			}).catch((err) => {
				console.log(err.response)
			})
        })
        onMounted(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice-detail/${route.params.laundry_id}`)
			.then(result => {
				console.log('Laundry Detail: ', result)
				laundryDetail.value = result.data.data
                for(var i = 0; i<result.data.data.length; i++){
                    if(i == (result.data.data.length - 1)){
                        // check apakah 
                        if(list_services.indexOf(result.data.data[i].service_name) == -1){
                            service_name.value = service_name.value + result.data.data[i].service_name + ", "
                            per_service.push({'name': result.data.data[i].service_name})
                            console.log('Service 1: ', result.data.data[i].service_name)
                        }
                    }else{
                        if(list_services.indexOf(result.data.data[i].service_name) == -1){
                            service_name.value = service_name.value + result.data.data[i].service_name + ", "
                            per_service.push({'name': result.data.data[i].service_name})
                            console.log('Service 2: ', result.data.data[i].service_name)
                        }
                    }
                    list_services.push(result.data.data[i].service_name)
                    console.log('Service List: ', result.data.data[i].cloth_name)
                    
                }
			}).catch((err) => {
				console.log(err.response)
			})
            axios.get(`invoice-detail/${route.params.laundry_id}`)
			.then(result => {
				console.log('Laundry Detail Kilo: ', result)
				laundryDetail2.value = result.data.data2
                for(var i = 0; i<result.data.data2.length; i++){
                    if(i == (result.data.data2.length - 1)){
                        // check apakah 
                        if(list_services.indexOf(result.data.data2[i].service_name) == -1){
                            service_name2.value = service_name2.value + result.data.data2[i].service_name + ", "
                            per_service.push({'name': result.data.data2[i].service_name})
                            console.log('Service 1: ', result.data.data2[i].service_name)
                        }
                    }else{
                        if(list_services.indexOf(result.data.data2[i].service_name) == -1){
                            service_name2.value = service_name2.value + result.data.data2[i].service_name + ", "
                            per_service.push({'name': result.data.data2[i].service_name})
                            console.log('Service 2: ', result.data.data2[i].service_name)
                        }
                    }
                    list_services.push(result.data.data2[i].name)
                    console.log('Service List: ', result.data.data2[i].name)
                    
                }
			}).catch((err) => {
				console.log(err.response)
			})
        })

        function dateTime(value) {
			return moment(value).format("DD-MM-YYYY h:mm A");
		}

        return{
            token,
            laundry,
            laundryDetail,
            laundryDetail2,
            route,
            router,
            dateTime,
            service_name,
            service_name2,
        }
    }
}
</script>

<style>
    .laundry .head-text h6{
        display: flex;
    }
    .laundry .head-text h6.c-title{
        color: var(--primaryColor);
        font-weight: 600;
    }
    .laundry .head-text h6 span.title{
        width: 130px;
    }

    .laundry .content{
        margin-bottom: 30px;
    }
    .laundry .content .text_title{
        color: var(--darkGrey);
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
        font-size: 12pt;
        margin-top: 40px;
    }
    .laundry .content .list_barang{
        background: #f8f8f8;
        border-left: 3px solid var(--yellow);
        padding: 5px 10px 10px;
        /* max-height: 400px;
        overflow-y: scroll; */
    }
    .laundry .content .list_barang .text_barang .text_sub{
        color: var(--secondaryColor);
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 12pt;
    }
    .laundry .content .list_barang .text_barang .text_desc{
        padding-left: 15px;
        margin-bottom: 5px;
        font-size: 12pt;
    }
    .laundry .content .list_barang .text_barang .text_desc span.list_item{
        width: 20px !important;
        margin-right: 2px;
        margin-left: -12px;
    }
    .laundry .content .list_barang .text_barang .text_subdesc{
        padding: 10px 15px;
        margin-bottom: 5px;
        margin-left: 15px;
        font-size: 12pt;
        background: #ebebeb;
    }
    .laundry .content .list_barang .text_barang .text_desc span.info{
        background: var(--primaryLight);
        border-radius: 5px;
        padding: 0px 5px;
        font-size: 8pt;
        font-weight: 600;
        color: var(--secondaryColor);
        margin-left: 10px;
    }

    .laundry .back-btn{
        width: 100%;
        margin: 0 auto 30px;
        display: block;
    }
</style>