<template>
<Navbars></Navbars>
    <div class="data_scan">
        <div class="container">
            <h3 class="mt-5 mb-4 title"><b>List Drop Point</b></h3>

            <div class="data">
                <div class="row">
                    <div class="col-lg-4" v-for="item in droppoint" :key="item.id">
                        <div class="drop_list">
                            <h5 class="title">{{item.fullname}}</h5>
                            <p class="text_info">{{item.email}}</p>
                            <p class="text_info">{{item.phone_number}}</p>
                            <p class="text_info">{{item.address}}</p>

                            <hr>

                            <p class="text_title">List Barang</p>
                            <div class="list_barang">
                                <div class="text_barang" v-for="list in item.detail" :key="list.id">
                                    <p class="text_sub">{{list.service_name}}</p>
                                    <p class="text_desc">{{list.cloth_name}} <span>{{list.qty}}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12 mx-auto">
                        <div class="tombol">
                            <button class="btn btn__primary" @click="goToHome()">Selesai</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbars from '@/components/Navbars.vue'
import { ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import axios from 'axios'
// import moment from 'moment'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'DataScan',
    components: {
        Navbars,
    },
    methods: {
        print() {
            print(this.$htmlToPaper("printMe", {
                styles: [
                    'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.css'
                ]
            }));
            // this.$htmlToPaper("printMe", {
            //     styles: [
            //         'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.css'
            //     ]
            // });
        }
    },
    setup(){
        const token = localStorage.getItem('token')
        const scan_type = localStorage.getItem('scan_type')
        const route = useRoute();
        const router = useRouter();
        const user = localStorage.getItem("user");
        const drop_code = route.params.drop_code;
        const droppoint = ref([])
        const laundry_id = localStorage.getItem('laundry_id') ? localStorage.getItem('laundry_id') : 'invalid'
        const storeUpdateStatus = reactive({
            laundry_id: '',
            user_id: user,
            droppoint_id: localStorage.getItem('droppoint_id'),
            schema_id: localStorage.getItem('schema_id'),
        });

        onMounted(() => {
            console.log('URL DATADROPPOINT: ', 'driver/scan-reader-droppoint/'+(drop_code)+'/'+(user))
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get('driver/scan-reader-droppoint/'+(drop_code)+'/'+(user))
			.then(result => {
				console.log('List Drop Point Data : ', result.data.data)
				droppoint.value = result.data.data
			}).catch((err) => {
				console.log('ERROR List Drop Point Data : ',err.response)
			})
        })

        function goToHome(){
            if(scan_type == 'pickup'){
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.post("booking/update-status-pickup-by-driver", storeUpdateStatus)
                .then((response) => {
                    console.log('Respon perubahan status by driver: ', response)
                    return router.push({
                        name: 'home',
                    })
                })
                .catch((err) => {
                    console.log(err.response);
                    createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
                    {
                        type: 'danger',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                });
            }else if(scan_type == 'finish'){
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.get(`booking/update-status-siap-diambil-customer/${laundry_id}`)
                .then(response => {
                    console.log(response)
                    console.log('Respon perubahan status by driver: ', response)
                    return router.push({
                        name: 'home',
                    })
                }).catch((err) => {
                    console.log(err.response)
                    createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
                    {
                        type: 'danger',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                })
            }else if(scan_type == 'expired'){
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.get(`booking/update-status-expired-pickup-by-driver/${laundry_id}`)
                .then(response => {
                    console.log(response)
                    console.log('Respon perubahan status by driver: ', response)
                    return router.push({
                        name: 'home',
                    })
                }).catch((err) => {
                    console.log(err.response)
                    createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
                    {
                        type: 'danger',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                })
            }else{
                createToast('Scan type tidak diketahui!',
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
            }
        }
        

        return{
            // profile,
            route,
            router,
            drop_code,
            token,
            droppoint,
            goToHome
        }
    }
}
</script>

<style scoped>
    .data_scan .title{
        color: var(--secondaryColor);
        margin-bottom: 5px;
    }
    .data_scan .subtitle{
        color: var(--grey);
        border-left: 2px solid var(--secondaryColor);
        padding-left: 10px;
    }
    /* ===== Data Droppoint List ===== */
    .drop_list{
        border: 1px solid #ececec;
        padding: 15px;
    }
    .drop_list .title{
        color: var(--secondaryColor);
        font-weight: 700;
        text-align: left;
    }
    .drop_list .text_info{
        color: var(--darkGrey);
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
        font-size: 10pt;
    }

    .drop_list hr{
        height: 1px;
        background: var(--lightGrey);
    }

    .drop_list .text_title{
        color: var(--darkGrey);
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
        font-size: 10pt;
    }
    .drop_list .list_barang{
        background: #f8f8f8;
        border-left: 3px solid var(--yellow);
        padding: 5px 10px 10px;
        max-height: 100px;
        overflow-y: scroll;
    }
    .drop_list .list_barang::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 64, 75, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    .drop_list .list_barang::-webkit-scrollbar
    {
        width: 5px;
        background-color: #F5F5F5;
    }
    .drop_list .list_barang::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px var(--secondaryColor);
        background-color: #555;
    }
    .drop_list .list_barang .text_barang .text_sub{
        color: var(--secondaryColor);
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 10pt;
    }
    .drop_list .list_barang .text_barang .text_desc{
        padding-left: 15px;
        margin-bottom: 5px;
        font-size: 10pt;
    }
    .drop_list .list_barang .text_barang .text_desc span{
        background: var(--primaryLight);
        border-radius: 5px;
        padding: 0px 5px;
        font-size: 8pt;
        font-weight: 600;
        color: var(--secondaryColor);
        margin-left: 10px;
    }
    /* ===== Data Droppoint List ===== */
</style>