<template>
    <Navbarc></Navbarc>
    <div class="profil">
        <div class="container">
            <br>
            <center><h1 class="mt-3 title">Profil</h1></center>
            <div class="row">
                <div class="col-lg-12">
                    <div class="wrapper">
                        <div class="card">
                            <div class="card-body">
                                <div class="row m-l-0 m-r-0">
                                    <div class="col-sm-4 bg-c-lite-green user-profile">
                                        <div class="card-block text-center text-white">
                                            <div class="m-b-25"> <img src="https://img.icons8.com/bubbles/100/000000/user.png" class="img-radius" alt="User-Profile-Image"> </div>
                                            <h4 class="f-w-600">{{ profile.fullname }}</h4>
                                            <p>{{ profile.role }}</p> <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="card-block">
                                            <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Information</h6>

                                            <p class="m-b-10 f-w-600">Email</p>
                                            <h6 class="text-muted f-w-400">{{ profile.email }}</h6>

                                            <p class="m-b-10 f-w-600">Phone</p>
                                            <h6 class="text-muted f-w-400">{{ profile.phone_number }}</h6>

                                            <p class="m-b-10 f-w-600">Address</p>
                                            <h6 class="text-muted f-w-400">{{ profile.address }}</h6>

                                            <ul class="social-link list-unstyled m-t-40 m-b-10">
                                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="facebook" data-abc="true"><i class="mdi mdi-facebook feather icon-facebook facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="twitter" data-abc="true"><i class="mdi mdi-twitter feather icon-twitter twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="instagram" data-abc="true"><i class="mdi mdi-instagram feather icon-instagram instagram" aria-hidden="true"></i></a></li>
                                            </ul>

                                            <button class="btn btn__primary tbtn" @click="editProfil()">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                                </svg>
												Edit Profil
											</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="modalScan" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="card-title">Edit Profil</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="mx-2 mt-4" @submit.prevent="updateProfile()">
                            <input type="hidden" class="form-control" id="user_id" v-model="state.user_id">
                            <div class="mb-4 form-group">
                                <label for="nama" class="form-label"><span>Nama Lengkap</span></label>
                                <input type="text" class="form-control" id="nama" v-model="state.fullname">
                                <!-- <div v-if="validations.fullname" class="mt-2 text-start text-danger error__text">
                                    {{ validations.fullname[0] }}
                                </div> -->
                            </div>
                            <div class="mb-4 form-group">
                                <label for="emailr" class="form-label"><span>Email</span></label>
                                <input type="email" class="form-control" id="emailr" v-model="state.email">
                                <!-- <div v-if="validations.email" class="mt-2 text-start text-danger error__text">
                                    {{ validations.email[0] }}
                                </div> -->
                            </div>
                            <div class="mb-4 form-group">
                                <label for="alamat" class="form-label"><span>Alamat</span></label>
                                <input type="text" class="form-control" id="alamat" v-model="state.address">
                                <!-- <div v-if="validations.address" class="mt-2 text-start text-danger error__text">
                                    {{ validations.address[0] }}
                                </div> -->
                            </div>
                            
                            <div class="mb-4 form-group">
                                <label for="telepon" class="form-label"><span>Telepon</span></label>
                                <input type="text" class="form-control" id="telepon" v-model="state.phone_number">
                                <!-- <div v-if="validations.phone_number" class="mt-2 text-start text-danger error__text">
                                    {{ validations.phone_number[0] }}
                                </div> -->
                            </div>
                            
                            <div class="mb-4 form-group">
                                <label for="passwordr" class="form-label"><span>Password</span></label>
                                <input type="password" class="form-control" id="passwordr" v-model="state.password">
                                <!-- <div v-if="validations.password" class="mt-2 text-start text-danger error__text">
                                    {{ validations.password[0] }}
                                </div> -->
                                <div id="passwordHelpBlock" class="form-text">
                                    Password harus minimal 8 karakter, perpaduan angka dan huruf.
                                </div>
                            </div>
                            <div class="mb-4 form-group">
                                <label for="passwordcr" class="form-label"><span>Confirm Password</span></label>
                                <input type="password" class="form-control" id="passwordcr" v-model="state.password_confirmation">
                                <!-- <div v-if="validations.password_confirmation" class="mt-2 text-start text-danger error__text">
                                    {{ validations.password_confirmation[0] }}
                                </div> -->
                            </div>
                            <div class="d-flex justify-content-end">
                                <button class="btn btn__primary mb-3 d-flex align-items-center justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save me-2" viewBox="0 0 16 16">
                                        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                                    </svg>
                                    Simpan
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import { onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { Modal } from "bootstrap"

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
    name: 'Profile',
    components: {
        Navbarc,
    },
    setup(){
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const profile = ref([])
        const router = useRouter();
        const modal = ref(null);
        const modalScan = ref(null);
        // const validations = ref([])

        

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            modal.value = new Modal(modalScan.value);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get('profile/'+(user))
            .then(response => {
                console.log('Response Profile',response)
                if(response.data.status == 'success'){
                    profile.value = response.data.data
                }else{
                    try {
                        let fullname = localStorage.getItem('profile_name')
                        let address = localStorage.getItem('profile_address')
                        let gender = localStorage.getItem('profile_gender')
                        let email = localStorage.getItem('profile_email')
                        profile.value = {
                            fullname: fullname,
                            address: address,
                            gender: gender,
                            email: email,
                        }
                    } catch (error) {
                        console.log("DATA DIRI ERROR MSG: ", error)
                    }
                }
                
			}).catch((err) => {
				console.log(err.response)
			})
        })

        const state = reactive({
            user_id: user,
            fullname: '',
            email: '',
            phone_number: '',
            address: '',
            password: '',
            password_confirmation: ''
        })

        function checkpass(input_text){
            var regexp = /\d/g;
            var data = {
                'message': '',
                'valid': false
            }
            // cek panjang password
            if(input_text.length >= 8) 
            {
                // check password contain number
                if(regexp.test(input_text) == true){
                    // check password semua charter number
                    if(/^\d+$/.test(input_text) == true){
                        data.message = 'Password terlalu lemah, tidak boleh angka semua!'
                        data.valid = false
                        return data
                    }else{
                        data.message = 'Password diperbolehkan!'
                        data.valid = true
                        return data
                    }

                }else{
                    data.message = 'Password harus berisi 1 angka atau lebih'
                    data.valid = false
                    return data
                }
            }
            else
            { 
                data.message = 'Password niminum 8 character ya!'
                data.valid = false
                return data;
            }
        }

        function editProfil(){
            modal.value.show()
            axios.get('profile/'+(user))
            .then(response => {
                console.log('GET Profile', response.data.data)
                profile.value = response.data.data
                
            }).catch((err) => {
				console.log(err.response)
			})
            state.fullname = profile.value.fullname
            state.email = profile.value.email
            state.phone_number = profile.value.phone_number
            state.address = profile.value.address
        }
        function updateProfile(){
            var cek_pass = checkpass(state.password)
            
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.post('update-profile', state
                )
                .then(response => {
                    console.log(response)
                    
                    if(cek_pass.valid == false && state.password !== "")
                    {
                        createToast(cek_pass.message,
                        {
                            type: 'warning',
                            timeout: 5000,
                        })
                    }else{
                        createToast('Data Berhasil Disimpan!',
                        {
                            type: 'success',
                            timeout: 5000,
                        })
                        modal.value.hide()
                        window.location.reload()
                    }
    
                }).catch(error => {
                    // validations.value = error.response.data.data
                    console.log(error.response)
                    createToast('Oopss.. Sepertinya ada yang salah deh :(',
                    {
                        type: 'warning',
                        timeout: 5000,
                    })
                })
            
        }

        return{
            profile,  
            token,
            user,
            router,
            role,
            updateProfile,
            state,
            editProfil,
            modal,
            modalScan,
            // validations,
            checkpass
        }

    }
}
</script>

<style scoped>
    h1.title{
		font-weight: 600;
		margin-bottom: 30px;
	}

    .profile .card .invoice-header h6{
        display: flex;
    }
    .profile .card .invoice-header h6.c-title{
        color: var(--blueColor);
        font-weight: 600;
    }
    .profile .card .invoice-header h6 span.title{
        width: 130px;
    }

    .profile .card .card-body .table thead{
        border-bottom: 2px solid var(--blueColor) !important;
        border-top: 2px solid var(--blueColor) !important;
    }
    .profile .card .card-body .table thead tr th{
        color: var(--blueColor);
    }

    .btn__upload {
        position: relative;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 30px;
    }
    .btn__upload input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .btn__upload .btn-sm{
        padding: 0.2em 0.3em;
        font-size: 0.9em;
        font-weight: normal;
    }
    .img-payment{
        max-width: 130px;
        display: block;
        /* margin: 0 auto; */
        margin-bottom: 10px;
    }

    @media (max-width: 767.98px){
        .profile .card .invoice-header{
            margin-bottom: 30px;
        }
    }
    .padding {
        padding: 3rem !important
    }

    .user-card-full {
        overflow: hidden
    }

    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
        box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
        border: none;
        margin-bottom: 30px
    }

    .m-r-0 {
        margin-right: 0px
    }

    .m-l-0 {
        margin-left: 0px
    }

    .user-card-full .user-profile {
        border-radius: 5px 0 0 5px
    }

    .bg-c-lite-green {
        /* background: -webkit-gradient(linear, left top, right top, from(#5e7ac9), to(#051885));
        background: linear-gradient(to right, #1ddabd, #0d91fd); */
        background: var(--blueGradient);
    }

    .user-profile {
        padding: 20px 0
    }

    .card-block {
        padding: 1.25rem
    }

    .m-b-25 {
        margin-bottom: 25px
    }

    .img-radius {
        border-radius: 5px
    }

    h6 {
        font-size: 14px
    }

    .card .card-block p {
        line-height: 25px
    }

    @media only screen and (min-width: 1400px) {
        p {
            font-size: 14px
        }
    }

    .card-block {
        padding: 1.25rem
    }

    .b-b-default {
        border-bottom: 1px solid #e0e0e0
    }

    .m-b-20 {
        margin-bottom: 20px
    }

    .p-b-5 {
        padding-bottom: 5px !important
    }

    .card .card-block p {
        line-height: 25px
    }

    .m-b-10 {
        margin-bottom: 10px
    }

    .text-muted {
        color: #919aa3 !important
    }

    .b-b-default {
        border-bottom: 1px solid #e0e0e0
    }

    .f-w-600 {
        font-weight: 600
    }

    .m-b-20 {
        margin-bottom: 20px
    }

    .m-t-40 {
        margin-top: 20px
    }

    .p-b-5 {
        padding-bottom: 5px !important
    }

    .m-b-10 {
        margin-bottom: 10px
    }

    .m-t-40 {
        margin-top: 20px
    }

    .user-card-full .social-link li {
        display: inline-block
    }

    .user-card-full .social-link li a {
        font-size: 20px;
        margin: 0 10px 0 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out
    }
</style>