<template>
<Navbarc></Navbarc>
    <div class="data_scan">
        <div class="container">
            <h3 class="mt-5 mb-4 title"><b>Result</b></h3>

            <div class="data">
                <div class="row">
                    <div class="col-lg-6 mx-auto">
                        <div class="card" id="printMe">
                            <h3 class="card-title text-center fw-bold mt-3">Data Pelanggan</h3>
                            <div class="row">
                                <div class="col-sm-12 right">
                                    <!-- <img src="../../assets/images/qr-code.png" class="img-fluid"> -->
                                    <img :src="imageUrl+profile.info_laundry.qr_code" class="img-fluid" v-if="scan_type != 'finish'">
                                </div>
                                <div class="col-sm-12 left">
                                    <div class="card-body">
                                        <p class="card-title fw-bold">Nama :</p>
                                        <p class="card-text">{{profile.info_laundry.fullname}}</p>

                                        <p class="card-title fw-bold">No. Tlp :</p>
                                        <p class="card-text">{{profile.info_laundry.phone_number}}</p>

                                        <p class="card-title fw-bold">List Barang :</p>
                                        <p class="card-text" v-for="item in detail_laundry.detail_laundry" :key="item.cloth_name">{{ item.cloth_name }} Rp. {{ formatPrice(item.price) }} X {{ item.qty }} = Rp. {{ subTotal(item.price, item.qty) }}</p>
                                        <p class="card-title fw-bold">TOTAL : Rp. {{ getTotal(detail_laundry.detail_laundry) }}</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
            
                        <div class="tombol-grup">
                            <button class="btn btn__grey">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
                                    <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                                </svg>
                                Scan Lagi
                            </button>
                            <button @click="goToHome()" class="btn btn__primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                                </svg>
                                Selesai
                            </button>
                            <!-- <button @click="print()" class="btn btn__primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 16">
                                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                </svg>
                                Print
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import { onMounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import axios from 'axios'
import moment from 'moment'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'DataScan',
    components: {
        Navbarc,
    },
    methods: {
        print() {
            print(this.$htmlToPaper("printMe", {
                styles: [
                    'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.css'
                ]
            }));
            // this.$htmlToPaper("printMe", {
            //     styles: [
            //         'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.css'
            //     ]
            // });
        }
    },
    setup(){
        const token = localStorage.getItem('token')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const route = useRoute();
        const router = useRouter();
        const invoice = ref([])
        const invDetail = ref([])
        const total_order = reactive({
            total: 0
        })
        const scan_type = localStorage.getItem('scan_type')
        const user = localStorage.getItem("user");
        
        const profile = reactive(JSON.parse(route.params.data));
        const detail_laundry = reactive(JSON.parse(route.params.data))
        console.log('data profile: ', profile)
        // const numberCode = ref(route.params.number);

        const laundry_id = detail_laundry.info_laundry.laundry_id
        console.log("laundry id: ", laundry_id)
        
        const storeUpdateStatus = reactive({
            laundry_id: laundry_id,
            user_id: user,
            droppoint_id: '',
            schema_id: localStorage.getItem('schema_id'),
        });

        onMounted(() => {
            if(role == 'courier'){
				return router.push({
					path: '/home-kurir'
				})
			}else if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice/${laundry_id}`)
			.then(result => {
				console.log('invoice data: ', result.data.data)
				invoice.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
        })
        onMounted(() => {
            var data_total = 0
            var tempInvDetail = []
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice-detail/${laundry_id}`)
			.then(result => {
				console.log('invoice detail data: ', result.data.data)
				invDetail.value = result.data.data
                tempInvDetail = result.data.data
                
                console.log('sub_total: ', tempInvDetail)
                for(var index in tempInvDetail){
                    console.log('datanya : ', tempInvDetail[index]['sub_total'])
                    data_total = data_total + parseInt(tempInvDetail[index]['sub_total'])
                }
                total_order.total = data_total
                
			}).catch((err) => {
				console.log(err.response)
			})
        })

        function formatPrice(value){
            value = parseInt(value)
            let val = (value/1)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }

        function subTotal(price, qty){
            price = parseInt(price)
            qty = parseInt(qty)
            return formatPrice(price * qty)
        }

        function getTotal(detail){
            var total = 0
            for(var i=0; i < detail.length; i++){
                total = total + (parseInt(detail[i].qty) * parseInt(detail[i].price))
            }
            return formatPrice(total)
        }

        function dateTime(value) {
			return moment(value).format("DD-MM-YYYY h:mm A");
		}
        // function goToHome(){
        //     return router.push({
        //         name: 'home-kurir',
        //     })
        // }

        function goToHome(){
            if(scan_type == 'ambil'){
                axios.defaults.headers.common.Authorization = `Bearer ${token}`
                axios.get(`/booking/update-status-sudah-diambil-customer/${laundry_id}`)
                .then((response) => {
                    console.log('Respon perubahan status by Customer: ', response)
                    createToast('Sukses!! Terimakasih ya :)',
                    {
                        type: 'success',
                        timeout: 4000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                    setTimeout(function () {
                        return router.push({
                            name: 'dashboard',
                        })
                    }, 5000);
                    
                })
                .catch((err) => {
                    console.log(err.response);
                    createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
                    {
                        type: 'danger',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'zoom'
                    })
                });
            // }else if(scan_type == 'finish'){
            //     axios.defaults.headers.common.Authorization = `Bearer ${token}`
            //     axios.get(`booking/update-status-siap-diambil-customer/${laundry_id}`)
            //     .then(response => {
            //         console.log(response)
            //         console.log('Respon perubahan status by driver: ', response)
            //         return router.push({
            //             name: 'home',
            //         })
            //     }).catch((err) => {
            //         console.log(err.response)
            //         createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
            //         {
            //             type: 'danger',
            //             timeout: 5000,
            //             showIcon: 'true',
            //             transition: 'zoom'
            //         })
            //     })
            // }else if(scan_type == 'expired'){
            //     axios.defaults.headers.common.Authorization = `Bearer ${token}`
            //     axios.get(`booking/update-status-expired-pickup-by-driver/${laundry_id}`)
            //     .then(response => {
            //         console.log(response)
            //         console.log('Respon perubahan status by driver: ', response)
            //         return router.push({
            //             name: 'home',
            //         })
            //     }).catch((err) => {
            //         console.log(err.response)
            //         createToast('Ada Kesalahan di rumah WABO :"(, ERROR: ' + err.message,
            //         {
            //             type: 'danger',
            //             timeout: 5000,
            //             showIcon: 'true',
            //             transition: 'zoom'
            //         })
            //     })
            }else{
                createToast('Scan type tidak diketahui!',
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'zoom'
                })
            }
             
        }

        // function goToScan(){
        //     var url_scan_again = localStorage.getItem('url_scan_again')
        //     return router.push(url_scan_again)
        // }

        return{
            profile,
            route,
            router,
            invoice,
            invDetail,
            // numberCode,
            token,
            formatPrice,
            dateTime,
            goToHome,
            // goToScan,
            total_order,
            storeUpdateStatus,
            scan_type,
            role,
            detail_laundry,
            subTotal,
            getTotal
        }
    }
}
</script>

<style>
    
    /* ===== Data Diri ===== */
    .data .data__diri{
        border: 1px solid var(--lightGrey);
        padding: 10px;
        text-align: left;
    }
    .data .data__diri .data__title{
        font-weight: 600;
        text-align: center;
        color: var(--blueColor);
    }
    .data .data__diri .text__title{
        font-weight: 600;
        margin-bottom: 0;
        text-align: left;
    }
    .data .data__diri .text__name{
        font-weight: 400;
        margin-bottom: 10px;
        text-align: left;
    }

    .data .btn{
        width: 100%;
        margin: 30px 0;
    }

    .data .qr_code img{
        width: 100%;
    }

    .data .card img{
        width: 50%;
        display: block;
        margin: 0 auto;
    }
    .tombol-grup{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .tombol-grup .btn{
        margin: 30px 5px 40px;
        font-size: 10pt;
        padding: 15px 10px;
    }
    /* ===== Data Diri ===== */
    @media(max-width: 576px){
        .data .card img{
            width: 100%;
            display: block;
            margin: 0 auto;
        }
        .tombol-grup{
            display: flex;
            align-items: center;
            width: 100%;
        }
        .tombol-grup .btn{
            margin: 20px 5px 30px;
            font-size: 10pt;
            padding: 15px 10px;
        }
    }

    @media print {
        button{
            display: none !important;
        }
        body{
            margin: 0 !important;
            padding: 0 !important;
        }
        img{
            width: 92% !important;
            visibility: visible !important;
            margin: 0 auto !important;
            /* position: absolute !important;
            top: 30px !important;
            right: 0 !important; */
        }
        .navbar{
            display: none !important;
        }
        h3.title{
            display: none !important;
        }
        .container{
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        .data{
            width: 100% !important;
        }

        .card{
            padding: 0 !important;
            margin: 0 -10px !important;
        }
        .row{
            display: flex !important;
        }
        .right{
            float: right !important;
        }
        .card-body{
            width: 100% !important;
        }

        h3.card-title{
            font-size: 9pt !important;
            margin-bottom: 0 !important;
            margin-top: 5px !important;
        }
        p.card-title{
            font-size: 6pt !important;
            margin-bottom: 0 !important;
        }
        p.card-text{
            font-size: 6pt !important;
            margin-bottom: 5px !important;
        }
        @page {
            size: 2.2in 5in !important;
            padding: 0 !important;
            margin: 0 !important;
        }
    }
</style>