<template>
<Navbars></Navbars>
    <div class="list_loker">
        <div class="container">
            <h3 class="mt-5 mb-4 title"><b>Scan Loker</b></h3>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12" v-for="loker in lokers" :key="loker.id">
                    <router-link :to="'/list-detail/'+loker.locker_id" class="boxes">
                        <div class="box__lokers">
                            <div class="box"></div>
                            <img src="../../assets/images/icon/light-decor.svg" alt="" class="light-d">
                            <img src="../../assets/images/icon/dot-decor.svg" alt="" class="dot-d">
                            <div class="box__inner">
                                <div class="empty">
                                    <span class="badge bg-warning">Slot Kosong : {{loker.empty}}</span>
                                </div>

                                <h3 class="title text-center">{{loker.name}}</h3>

                                <div class="address">
                                    <h6>Alamat :</h6>
                                    <p class="desc">
                                        {{ loker.address }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Navbars from '@/components/Navbars.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
    name: 'LokerList',
    components: {
        Navbars,
    },
    setup(){
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const lokers = ref([]);
        const router = useRouter()
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'

        onMounted(() => {
            if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`driver/lockers/${user}`)
			.then(result => {
				console.log(result)
				lokers.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        return{
            lokers,
            token,
            router,
            role
        }
    }
}
</script>

<style scoped>
    .list_loker h3.title{
        color: var(--darkBlue);
    }
    /* ===== Locker Place ====== */
	.boxes{
        text-decoration: none;
    }
    .box__lokers{
        border-radius: 10px;
        background: url('../../assets/images/bg-loker-place.jpg');
        background-size: cover;
        padding: 10px;
        position: relative;
        box-shadow: 0 5px 20px rgba(162, 36, 144, 0.3);
        margin-bottom: 20px;
        z-index: 1;
        height: 200px;
    }
    .box__lokers .box{
        background: var(--darkBlue);
        object-fit: cover;
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        opacity: 0.7;
    }
    .box__lokers .light-d{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
    }
    .box__lokers .dot-d{
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
        opacity: 0.2;
    }
    .box__lokers .box__inner .empty{
        position: relative;
        z-index: 5;
    }
    .box__lokers .box__inner .empty .badge{
        font-size: 10pt;
        color: var(--darkBlue);
        box-shadow: 0 3px 10px rgba(255, 173, 80, 0.3);
    }
    .box__lokers .box__inner .title{
        color: white;
        font-weight: 800;
        margin: 20px 0;
        position: relative;
        z-index: 5;
    }
    .box__lokers .box__inner .address{
        backdrop-filter: blur(5px);
        background: rgba(255,255,255,0.3);
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        padding: 10px;
        border-radius: 5px;
        position: relative;
        z-index: 5;
        height: 85px;
    }
    .box__lokers .box__inner .address h6{
        font-weight: 800;
        color: white;
        font-size: 10pt;
    }
    .box__lokers .box__inner .address p.desc{
        color: white;
        margin-bottom: 0;
        font-size: 10pt;
        max-height: 45px;
        overflow-y: scroll;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255,1); 
    }
	/* ===== Locker Place ====== */
	

	@media (max-width: 768px){

		/* ===== Locker Place ===== */
		.box__lokers{
            margin: 0 50px 20px;
        }
		/* ===== Locker Place ===== */
	}
	@media (max-width: 549.98px){
        .list_loker h3.title{
            text-align: center;
        }
		/* ===== Locker Place ===== */
        .box__lokers .light-d{
            width: 40%;
        }
        .box__lokers .dot-d{
            width: 40%;
        }
		/* ===== Locker Place ===== */
    }
</style>