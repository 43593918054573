<template>
<Navbarc></Navbarc>
    <div class="identitas">
        <div class="container">
            <h3 class="mt-5 mb-4 text-secondary"><b>Booking</b></h3>

            <div class="data text-start">
                <div class="row">
                    <div class="col-lg-4 mx-auto">
                        <div class="data__diri">
                            <h3 class="data__title">Data Diri</h3>

                            <p class="text__title">Nama :</p>
                            <p class="text_name">{{ profile.fullname }}</p>

                            <p class="card-title fw-bold">Layanan :</p>
                            <p class="card-text">{{invoice.type_location}}</p>

                            <p class="card-title fw-bold">No. Tlp :</p>
                            <p class="card-text">{{invoice.phone_number}}</p>

                            <div v-for="kilo in invDetailKilo" :key="kilo.id">
                                <p class="card-title fw-bold">{{kilo.name}}</p>
                                <p class="card-text2">{{kilo.berat}} X Rp. {{formatPrice(kilo.harga_kg)}} = Rp. {{formatPrice(kilo.total_harga)}}</p>
                                <p class="card-text2">{{kilo.note_service}}</p>
                                <p class="card-text2">Parfum : {{kilo.parfum}}</p>
                            </div>

                            <p class="card-title fw-bold">List Barang :</p>
                            <p class="card-text" v-for="item in invDetail" :key="item.id">{{ item.name }} Rp. {{ formatPrice(item.price) }} X {{ item.qty }} = Rp. {{ formatPrice(item.sub_total)}}</p>
                            <div v-for="(data, index) in invDetail" :key="index">
                                <p class="card-title fw-bold" v-if="index == 0">TOTAL : Rp. {{ formatPrice(data.total_price) }}</p>
                            </div>
                        </div>

                        <button @click="goToScan()" class="btn btn__grey">
                            Scan Lagi
                        </button>
                        <button @click="goToHome()" class="btn btn__primary">
                            Selesai
                        </button>
            
                        <!-- <button @click="pinAmbil()" class="btn btn__primary">
                            Masukkan PIN
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'

import { onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import moment from 'moment'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'LokerDetail',
    components: {
        Navbarc,
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        localStorage.setItem('redirect_after_rating', 'dataAmbil')
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const profile = ref([])
        // const locker_number_id = localStorage.getItem('locker_number_id') ? localStorage.getItem('locker_number_id') : '0'
        // const codeNumber = route.params.code

        const invoice = ref([])
        const invDetail = ref([])
        const invDetailKilo = ref([])
        const total_order = reactive({
            total: 0
        })
        const laundry_id = localStorage.getItem('laundry_id') ? localStorage.getItem('laundry_id') : 'invalid'

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get('profile/'+(user))
            .then(response => {
                console.log('Response Profile',response)
                if(response.data.status == 'success'){
                    profile.value = response.data.data
                }else{
                    try {
                        let fullname = localStorage.getItem('profile_name')
                        let address = localStorage.getItem('profile_address')
                        let gender = localStorage.getItem('profile_gender')
                        let email = localStorage.getItem('profile_email')
                        profile.value = {
                            fullname: fullname,
                            address: address,
                            gender: gender,
                            email: email,
                        }
                    } catch (error) {
                        console.log("DATA DIRI ERROR MSG: ", error)
                    }
                }
                
			}).catch((err) => {
				console.log(err.response)
			})
        })

        onMounted(() => {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice/${laundry_id}`)
			.then(result => {
				console.log('invoice data: ', result.data.data)
				invoice.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
        })
        onMounted(() => {
            if(laundry_id == 'invalid'){
                createToast('Ops.. Terjadi Kesalahan Saat mengambil detail order.',
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
            }
            var data_total = 0
            var tempInvDetail = []
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`invoice-detail/${laundry_id}`)
			.then(result => {
                
				console.log('invoice detail data: ', result.data.data)
				invDetail.value = result.data.data
                tempInvDetail = result.data.data
                
                console.log('sub_total: ', tempInvDetail)
                for(var index in tempInvDetail){
                    console.log('datanya : ', tempInvDetail[index]['sub_total'])
                    data_total = data_total + parseInt(tempInvDetail[index]['sub_total'])
                }
                total_order.total = data_total
                
			}).catch((err) => {
                createToast('Ops.. Terjadi Kesalahan Saat mengambil detail order. ERR Code: ' + err.response.data.status_code.toString(),
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
				console.log(err.response)
			})
            axios.get(`invoice-detail/${laundry_id}`)
			.then(result => {
                
				console.log('invoice detail data: ', result.data.data)
				invDetailKilo.value = result.data.data2
                tempInvDetail = result.data.data
                
                console.log('sub_total: ', tempInvDetail)
                for(var index in tempInvDetail){
                    console.log('datanya : ', tempInvDetail[index]['sub_total'])
                    data_total = data_total + parseInt(tempInvDetail[index]['sub_total'])
                }
                total_order.total = data_total
                
			}).catch((err) => {
                createToast('Ops.. Terjadi Kesalahan Saat mengambil detail order. ERR Code: ' + err.response.data.status_code.toString(),
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
				console.log(err.response)
			})
        })
        function pinAmbil(){
            return router.push({
                name: 'pinAmbil'
            })
        }
        function goToHome(){
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get(`booking/update-status-sudah-diambil-customer/${laundry_id}`)
            .then(result => {
                console.log('Respon perubahan status jadi sudah diambil: ', result.data.data)
                return router.push({
                    name: 'dashboard',
                })
            }).catch((err) => {
                createToast('Ops.. Terjadi Kesalahan saat update status by customer. ERR MSG: ' + err.message,
                {
                    type: 'warning',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
                console.log(err.response)
            }) 
        }
        function goToScan(){
            return router.push({
                name: 'scanAmbil',
            })
        }
        function formatPrice(value){
            let val = (value/1)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }

        function dateTime(value) {
			return moment(value).format("DD-MM-YYYY h:mm A");
		}

        return{
            profile,
            route,
            router,
            token,
            user,
            pinAmbil,
            goToHome,
            goToScan,
            invoice,
            invDetail,
            invDetailKilo,
            formatPrice,
            dateTime,
            total_order,
            role,
        }
    }
}
</script>

<style>
    /* ===== Data Diri ===== */
    .data .data__diri{
        border: 1px solid var(--lightGrey);
        padding: 10px;
    }
    .data .data__diri .data__title{
        font-weight: 600;
        text-align: center;
        color: var(--primaryColor);
    }
    .data .data__diri .text__title{
        font-weight: 600;
        margin-bottom: 0;
    }
    .data .data__diri .text__name{
        font-weight: 400;
        margin-bottom: 10px;
    }

    .data .btn{
        width: 100%;
        margin: 30px 0;
    }

    .data .qr_code img{
        width: 100px;
    }
    /* ===== Data Diri ===== */
</style>