<template>
<Navbarc></Navbarc>
	<div class="home">
		<div class="container">
			<h1 class="title mt-5 mb-4">Riwayat Order</h1>

			<div class="histories">
				<!-- <div class="h_header">
					<h5 class="h_title">Riwayat Order</h5>
				</div> -->
				<div class="h_body">
					<div class="table-responsive">
						<table class="table table-hover table-sm" id="histories">
							<thead>
								<tr class="align-middle">
									<th class="text-center">Aksi</th>
									<th class="text-center">Status</th>
									<th class="text-center">Layanan</th>
									<th class="text-center">Kode Nomor</th>
									<th class="text-center">Kode Laundry</th>
									<th class="text-center">Harga</th>
									<th class="text-center">Status Pembayaran</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="riwayat in history" :key="riwayat.id">
									<td>
										<div>
											<!-- <div v-if="riwayat.locker_number_id === '0'">
												<button class="btn btn__outline_secondary tbtn" :id="riwayat.qr_code" @click="showQR($event)">
													<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" width="24" height="24" viewBox="0 0 20 20" fill="currentColor">
														<path fill-rule="evenodd" d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z" clip-rule="evenodd" />
														<path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3zM17 13a1 1 0 01-1 1h-2a1 1 0 110-2h2a1 1 0 011 1zM16 17a1 1 0 100-2h-3a1 1 0 100 2h3z" />
													</svg>
													QR Code
												</button>
												
											</div> -->
		
											<router-link :to="'/invoice/'+riwayat.laundry_id" class="btn btn__primary tbtn">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
													<path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
													<path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
												</svg>
												Invoice
											</router-link>
										</div>
									</td>
									<td class="text-center">
										<div>
											<span class="badge bg-danger" v-if="riwayat.status == 'Cancel'">{{riwayat.status}}</span>
											<span class="badge bg-success" v-else>{{riwayat.status}}</span>
										</div>
									</td>
									<td class="text-center">
										<div>
											{{riwayat.locker_name}}
										</div>
									</td>
									<td class="text-center">
										<div>
											{{riwayat.number_code}}
										</div>
									</td>
									<td class="text-center">
										<div>
											{{riwayat.laundry_code}}
										</div>
									</td>
									<td v-if="riwayat.price == null" class="text-center">
										<div>
											-
										</div>
									</td>
									<td v-else-if="riwayat.price !== null" class="text-center">
										<div>
											Rp. {{formatPrice(riwayat.total_payment)}}
										</div>
									</td>
									<td class="text-center" v-if="riwayat.payment_status == null">
										<div>
											-
										</div>
									</td>
                                    <td class="text-center" v-else-if="riwayat.payment_status == 'Belum Lunas'">
										<div>
											<span class="badge bg-warning text-dark">{{riwayat.payment_status}}</span>
										</div>
									</td>
                                    <td class="text-center" v-else>
										<div v-if="riwayat.status == 'Cancel' && riwayat.status == 'Sudah Diambil'">
											<span class="badge bg-success">{{riwayat.payment_status}}</span>
										</div>
										<div v-else>
											<span class="badge bg-success">{{riwayat.payment_status}}</span>
										</div>
									</td>
								</tr>
								<tr v-if="history.length == null">
									<td>Tidak ada riwayat order</td>
								</tr>
							</tbody>
							<tfoot>
								<tr class="align-middle">
									<th class="text-center">Aksi</th>
									<th class="text-center">Status</th>
									<th class="text-center">Layanan</th>
									<th class="text-center">Kode Nomor</th>
									<th class="text-center">Kode Laundry</th>
									<th class="text-center">Harga</th>
									<th class="text-center">Status Pembayaran</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>

			<!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="modalAmbil" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Apakah anda ingin ambil laundry sekarang?</h5>
                        <div class="text-center">
                            <button type="button" class="btn btn__primary px-5 mt-3" @click="scan()" v-on:click="modal.hide()">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
									<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
								</svg>
								Scan Loker
							</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			<!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="modalPemberitahuan" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
						<div class="text-end">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/ambil-laundry.svg" class="img__ambil" alt="">
                        </div>
                        <h5 class="text-center">Silahkan menuju loker yang anda pesan.</h5>
                        <div class="text-center">
							<router-link :to="{name: 'scan', params: { number_code: id_loker_want_to_scan }}" @click="modal2.hide()" para class="btn btn__primary tbtn">
								Oke Mengerti
							</router-link>
                        </div>
                    </div>
                </div>
                </div>
            </div>
			<!-- Modal QR -->
			<div class="modal fade" id="staticBackdrop" ref="modalQR" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-body">
							<div class="text-end">
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div class="text-center my-3">
								<figure>
									<img :src="imageUrl+qr_code" class="img__ambil" alt="">
								</figure>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Navbarc from '@/components/Navbarc.vue'
import { onMounted, nextTick ,ref, reactive } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import 'jquery/dist/jquery.min.js'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

//Datatable Modules
import "datatables.net-bs5/js/dataTables.bootstrap5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import $ from 'jquery'

export default {
	name: 'Home',
	components: {
		Navbarc,
	},
	methods: {
		processService: function (event) {
			var jenis_service = event.currentTarget.getAttribute('data_service')
			localStorage.setItem('service_type', jenis_service)
			console.log("jenis_service: ", jenis_service)
			var list_data = []
			var markedCheckbox = document.getElementsByName('service');  
			for (var checkbox of markedCheckbox) {  
				if (checkbox.checked){
					list_data.push(checkbox.value);
				}				  
			}
			console.log("Data service yang di pilih: ", list_data);
			if(list_data.length > 0){
				this.storeService.service_id = list_data
				axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
				axios.post("cart-store", this.storeService)
				.then((response) => {
					console.log('Response Store Service: ',response);
					// jika sukses atau mereturn response uncheck pilihannya
					for (var checkbox of markedCheckbox) {  
						checkbox.checked = false;			  
					}
					// jika sukses juga maka masuk ke halaman lokasi loker dll (nanti isi disini)
					if(jenis_service == 'drop_point'){
						this.gotoBookingDropPoint()
					}else if(jenis_service == 'loker'){
						this.gotoBooking()
					}else{
						// Ke antar jemput ngab
						console.log("goto antarjemput page")
					}
				})
				.catch((err) => {
					console.log(err.response);
					createToast('Terjadi kesalahan, ERR MSG: ' + err.response,
					{
						type: 'danger',
						timeout: 5000,
						showIcon: 'true',
						transition: 'zoom'
					})
				});
			}else{
				createToast('Ops sepertinya anda belum memilih layanan deh..',
				{
					type: 'warning',
					timeout: 5000,
					showIcon: 'true',
					transition: 'zoom'
				})
			}
			
		},
	},
	data: () => ({
        modal: null,
        modal2: null,
    }),
	computed: {
		qrDrop() {
			// let that = this
			return this.history.filter(p => p.laundry_id == this.idLaundry);
			// return this.products.filter(p => p.id_kategori == that.active);
		}
	},
    mounted() {
        this.modal = new Modal(this.$refs.modalAmbil)
        this.modal2 = new Modal(this.$refs.modalPemberitahuan)
		localStorage.setItem('redirect_after_rating', '/')
		localStorage.removeItem('laundry_id')
		localStorage.removeItem('id_drop_point')
		localStorage.removeItem('name_drop_point')
		localStorage.removeItem('address_drop_point')
		localStorage.removeItem('locker_number_id')
		localStorage.removeItem('data_drop_point')
		localStorage.removeItem('service_type')
    },
	setup(){
		const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
		const name = localStorage.getItem('name')
		const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
		const history = ref([])
		const router = useRouter()
		const services = ref([])
		const checked = ref(false)
		console.log('ROLE: ', role)
		// pengecekan role jika yng mencoba akses adalah driver maka redirect ke halaman home utk driver
		
		const storeService = reactive({
			user_id: user,
			checked: 'checked',
			service_id: []
		})
		const qr_code = ref(null)
		
		const modal = ref(null);
		const modalQR = ref(null);

		onMounted(() => {
			modal.value = new Modal(modalQR.value);
		})

		// const invoices = ref([])
		var id_loker_want_to_scan = localStorage.getItem('id_loker_scan') ? localStorage.getItem('id_loker_scan') : ''

		onMounted(() => {
			if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`cart/${user}`)
			.then((result) => {
                console.log('List Services: ',result)
				services.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        axios.defaults.headers.common.Authorization = `Bearer ${token}`
		onMounted(() => {
			axios.get(`history-lockers/${user}`)
			// axios.get('history-lockers')
			.then(result => {
				console.log(result)
				history.value = result.data.data3
				getHistory()
			}).catch((err) => {
				console.log(err.response)
			})
		})

		function showQR(event){
			var targetId = event.currentTarget.id;
			qr_code.value = targetId
			modal.value.show()
		}

		function cartService(){
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
			axios.post("cart-store", storeService)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				console.log(err.response);
			});
		}

		function getHistory(){
			nextTick(() => {
				$('#histories').DataTable({
					ordering:  false
				})
			})
		}

		function formatPrice(value){
            let val = (value/1)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
		// onMounted(() => {
		// 	axios.defaults.headers.common.Authorization = `Bearer ${token}`
		// 	axios.get(`invoice`)
		// 	// axios.get('history-lockers')
		// 	.then(result => {
		// 		console.log(result)
		// 		invoice.value = result.data.data
		// 	}).catch((err) => {
		// 		console.log(err.response)
		// 	})
		// })
		function scan(){
			return router.push({
				name: 'scanAmbil'
			})
		}

		function gotoBooking(){
			return router.push({
				name: 'booking',
			})
		}

		function gotoBookingDropPoint(){
			return router.push({
				name: 'bookingDropPoint',
			})
		}

		function storeDataLockerLaundry(id_loker, locker_number_id, id_laundry){
			localStorage.setItem('id_loker_scan', id_loker)
			localStorage.setItem('locker_number_id', locker_number_id)
			localStorage.setItem('laundry_id', id_laundry)
			id_loker_want_to_scan = id_loker
		}

        const handlePayment = (event, laundry_id) => {
            const file = event.target.files;
            if(file == null && file.length == 0){
                return false;
            }
            
            const data = new FormData();
            data.append('payment_attachment', file[0]);
            data.append('laundry_id', laundry_id);
            
            axios.post('booking/payment-confirmation', data, {
                'Content-Type': 'multipart/form-data'
            }).then((response) => {
                const { data } = response;

                alert(data.message);
            }).catch((err) => {
                const { data } = err.response;

                alert(data.message);
            })
        };

		return{
			history,
			router,
			token,
			user,
			name,
			services,
			// invoice,
			scan,
			gotoBooking,
			gotoBookingDropPoint,
			handlePayment,
			storeDataLockerLaundry,
			id_loker_want_to_scan,
			formatPrice,
			storeService,
			cartService,
			checked,
			showQR,
			modalQR,
			qr_code,
			role
		}
	}
}
</script>

<style>
	h1.title{
		font-weight: 600;
		margin-bottom: 30px;
	}

	/* ===== Welcome Text ====== */
	.home .head-box{
		position: relative;
		background: #f2f2f2;
		border-radius: 10px;
		padding: 20px;
		margin: 50px 0 60px;
	}
	.home .head-box .title{
		color: var(--darkBlue);
		font-weight: 800;
		margin-bottom: 20px;
		margin-right: 260px;
	}
	.home .head-box .desc{
		color: var(--darkGrey);
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 260px;
	}
	.home .head-box figure{
		position: absolute;
		bottom: -15px;
		right: -20px;
	}
	.home .head-box figure img{
		width: 250px;
		height: auto;
	}
	/* ===== Welcome Text ====== */

	/* ===== Layanan ====== */
	.home .services{
        margin: 0 0 30px;
        padding: 0;
    }
    .home .services .service__link{
        text-decoration: none;
        color: var(--secondaryColor);
        width: 16.6%;
        height: 150px;
        padding: 10px;
        transition: all 0.3s ease;
		position: relative;
    }

	/* .home .services .service__link form{
		position: absolute;
		top: 0;
		left: 0;
	} */
	.home .services .service__link  input{
		opacity: 0;
		width: 1em;
		height: 1em;
		position: absolute;
		top: 0;
		left: 0;
	}
	.home .services .service__link  input:hover + .service__list{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.6);
	}
	.home .services .service__link  input:hover + .service__list figure.icon img{
		filter: brightness(100);
	}
	.home .services .service__link  input:hover + .service__list .text .title,
	.home .services .service__link  input:hover + .service__list .text .sub_title{
		color: white;
	}
	.home .services .service__link  input:checked + .service__list{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.8);
	}
	.home .services .service__link  input:checked + .service__list figure.icon img{
		filter: brightness(100);
	}
	.home .services .service__link  input:checked + .service__list .text .title,
	.home .services .service__link  input:checked + .service__list .text .sub_title{
		color: white;
	}
	
	/* .home .services .service__link:hover > .service__list{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.6);
	} */
	/* .home .services .service__link:hover > .service__list figure.icon img{
		filter: brightness(100);
	} */
	.home .services .service__link .service__list.checked{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.6);
	}
    .home .services .service__link .service__list{
        width: 100%;
        height: 100%;
        color: var(--secondaryColor);
        text-decoration: none;
		background: var(--blueGradient);
		border-radius: 10px;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.3);
		position: relative;
		outline: unset;
		border: unset;
    }
	.home .services .service__link .service__list figure.icon{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60%;
		margin: 0;
	}
	/* .home .services .service__link .service__list{
		background: transparent;
		color: var(--secondaryColor);
		outline: unset;
		border: unset;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
	} */
	.home .services .service__link .service__list figure.icon img{
		height: 60%;
		width: auto;
	}
	.home .services .service__link .service__list figure.icon img.sepatu{
		/* height: 60%; */
		width: 70%;
	}
	.home .services .service__link .service__list .text{
		text-align: center;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		padding: 5px;
		background: unset !important;
	}
	.home .services .service__link .service__list .text .title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 12pt;
		margin-bottom: 0;
		color: var(--secondaryColor);
	}
	.home .services .service__link .service__list .text .sub_title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 9pt;
		margin-bottom: 0;
		color: var(--secondaryColor);
	}
	/* .home .services{
		margin: 0 0 30px;
		display: flex;
		align-items: center;
	}
	.home .services .service-box{
		background: var(--blueGradient);
		height: 130px;
		width: 16.6%;
		border-radius: 10px;
		text-decoration: none;
		color: var(--secondaryColor);
		font-weight: 800;
		text-transform: uppercase;
		margin: 10px;
		position: relative;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.3);
		-moz-transition: all .3s ease-in-out;
		-webkit-transition: all .3s ease-in-out;
		-ms-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
	.home .services .service-box:hover{
		background: var(--darkBlueG);
		color: white;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.6);
	}
	.home .services .service-box:hover > figure.icon img{
		filter: brightness(100);
	}
	.home .services .service-box figure.icon{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60%;
		margin: 0;
	}
	.home .services .service-box figure.icon img{
		height: 50%;
		width: auto;
		-moz-transition: all .2s ease-in-out;
		-webkit-transition: all .2s ease-in-out;
		-ms-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
	.home .services .service-box .text{
		text-align: center;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		padding: 5px;
	}
	.home .services .service-box .text .title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 12pt;
		margin-bottom: 0;
	}
	.home .services .service-box .text .sub_title{
		font-weight: 800;
		text-transform: uppercase;
		font-size: 9pt;
		margin-bottom: 0;
	} */
	/* ===== Layanan ====== */


	/* ===== Sub Layanan ====== */
	.sub_service{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-top: 1px solid var(--lightGrey);
		padding: 30px 0;
	}
	.sub_service .option-btn:disabled, .dropdown .btn:disabled{
		background: var(--lightGrey);
		color: var(--dark);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	}
	.sub_service .option-btn{
		margin: 10px;
		width: 48%;
	}
	.sub_service .dropdown{
		margin: 10px;
		width: 48%;
	}
	.sub_service .dropdown .btn{
		width: 100%;
	}
	.sub_service .dropdown .dropdown-menu{
		padding: 0;
		box-shadow: 0 5px 15px rgba(30, 251, 218, 0.3);
	}
	.sub_service .dropdown .dropdown-menu li:focus .dropdown-item,
	.sub_service .dropdown .dropdown-menu li:hover .dropdown-item
	{
		background: var(--blueGradient) !important;
	}
	.sub_service .dropdown ul li .dropdown-item{
		display: flex;
		align-items: center;
		color: var(--secondaryColor);
		font-weight: 600;
		padding: 10px 20px;
	}
	.sub_service .dropdown ul li .dropdown-item img{
		width: 50px;
		margin-right: 10px;
	}
	.sub_service .dropdown ul li .dropdown-item img.drop_point{
		height: 40px;
	}
	/* ===== Sub Layanan ====== */


	/* ===== Layanan ====== */
	.histories{
		margin-bottom: 50px;
	}
	.histories .h_header{
		padding: 5px 0;
	}
	.histories .h_header .h_title{
		font-weight: 800;
		color: var(--secondaryColor);
	}

	.histories .h_body{
		position: relative;
	}
	.histories .h_body table.dataTable thead tr th, 
	.histories .h_body table.dataTable tfoot tr th{
		border-bottom: 2px solid var(--primaryColor) !important;
		border-top: 2px solid var(--primaryColor) !important;
		/* background: var(--blueGradient); */
	}
	.histories .h_body table.dataTable tbody, thead,td, tfoot, th, tr{
		border-style: none !important;
	}
	.histories .h_body .table thead tr th,
	.histories .h_body .table tfoot tr th{
		color: var(--secondaryColor);
		padding: 10px 5px;
	}
	.histories .h_body .table tr td .tbtn{
		padding: 5px;
		font-size: 10pt;
		border-radius: 7px;
		margin: 5px;
		font-weight: 600;
	}
	.histories .h_body .table tr td .btnAmbil{
		font-size: 9pt;
		padding: 5px;
		margin: 5px;
		border-radius: 7px;
		background: #ffad50;
		font-weight: 600;
	}
	.dataTables_paginate .pagination .page-item.active .page-link{
		background: var(--blueGradient) !important;
		border-color: var(--primaryColor) !important;
		color: var(--secondaryColor) !important;
	}
	.dataTables_paginate .pagination .page-item .page-link{
		color: var(--primaryColor);
	}
	.dataTables_paginate .pagination .disabled .page-link{
		color: var(--darkGrey);
	}
	/* ===== Layanan ====== */

	
	@media (max-width: 768px){
		/* ===== Welcome ===== */
		.home .head-box{
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 40px;
			padding: 10px;
		}
		.home .head-box .title{
			font-size: 12pt;
			margin-right: 110px;
		}
		.home .head-box .desc{
			font-size: 8pt;
			margin-right: 110px;
		}
		.home .head-box figure{
			width: 40%;
		}
		.home .head-box figure img{
			width: 100%;
			height: auto;
		}
		/* ===== Welcome ===== */
		
		/* ===== Services ===== */
		.home .services .service__link{
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100px;
            padding: 5px;
        }
		/* ===== Services ===== */

		/* ====== DataTables ======= */
		.histories table.dataTable{
			margin: 50px 0 !important;
		}
		.histories .dataTables_length{
			position: absolute;
			top: 0;
			left: 0;
		}
		.histories .dataTables_filter{
			position: absolute;
			top: 0;
			right: 0;
		}
		.histories .dataTables_info{
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.histories .dataTables_paginate{
			position: absolute;
			bottom: 0;
			right: 0;
		}
		/* ====== DataTables ======= */
    }
	@media (max-width: 576px){
		/* ===== Services ===== */
        .home .services .service__link{
            text-decoration: none;
            color: white;
            width: 25%;
            height: 100px;
            padding: 5px;
        }
		.home .services .service__link .service__list .text{
			bottom: 5px !important;
		}
		.home .services .service__link .service__list .text .title{
			font-weight: 800;
			text-transform: uppercase;
			font-size: 8pt !important;
			margin-bottom: 0;
		}
		.home .services .service__link .service__list .text .sub_title{
			font-weight: 800;
			text-transform: uppercase;
			font-size: 6pt !important;
			margin-bottom: 0;
		}
		/* ===== Services ===== */

		/* ====== DataTables ======= */
		.histories table.dataTable{
			margin: 100px 0 !important;
		}
		.histories .dataTables_length{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		.histories .dataTables_filter{
			position: absolute;
			top: 50px;
			right: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		.histories .dataTables_info{
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		.histories .dataTables_paginate{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 100% !important;
		}
		/* ====== DataTables ======= */
    }
</style>
