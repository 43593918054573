<template>
    <Navbarc></Navbarc>
    <div class="pin">
        <div class="container">
            <div class="data">
                <div class="row">
                    <div class="col-lg-4 mx-auto">
                        <h5 class="text-center text-primary data__title mt-5">Masukkan PIN</h5>
                        <form @submit.prevent="checkPin()">
                            <input type="hidden" class="form-control" v-model="statepin.locker_number_id">
                            <input type="hidden" class="form-control" v-model="statepin.user_id">
                            <div id="divOuter">
                                <div id="divInner">
                                    <input id="partitioned" v-model="statepin.pin" autocomplete="false" type="text" maxlength="6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==6) return false;"/>
                                </div>
                            </div>
                            
                            <button type="submit" class="btn btn__primary">
                                Masukkan PIN
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="staticBackdrop" ref="ModalPin" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="text-center my-3">
                            <img src="../../assets/images/icon/proses.svg" alt="">
                        </div>
                        <h5 class="text-center" id="message_response">{{message_respons}}</h5>
                        <div class="text-center">
                            <button type="button" class="btn btn__primary px-5 mt-3" @click="finish()" v-on:click="modal.hide()">OK</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import { Modal } from 'bootstrap'
import { onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import mqtt from 'mqtt'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'Pin',
    components: {
        Navbarc,
    },
    data: () => ({
        modal: null,
        message_respons: null
    }),
    mounted() {
        this.modal = new Modal(this.$refs.ModalPin)
    },
    setup(){
        const route = useRoute();
        const router = useRouter();
        
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const profile = ref([])
        // const numberCode = route.params.kode
        const id_loker_scan = localStorage.getItem('id_loker_scan') ? localStorage.getItem('id_loker_scan') : ''
        localStorage.setItem('redirect_after_rating', '/detail-data/' + id_loker_scan)
        const locker_number_id = localStorage.getItem('locker_number_id')
        const statepin = reactive({
            locker_number_id: locker_number_id,
            pin: '',
            user_id: user
        })

        // Setting koneksi Ke loker
        const connection = reactive({
            host: 'm13.cloudmqtt.com',
            port: 37475,
            endpoint: '',
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 4000,
            
            clientId: user + Math.floor((Math.random() * 100000000000) + 1),
            username: 'ouxpjuzq',
            password: 'zMtEaDTaqXmx',
        })

        var publish = reactive({
            topic: 'loker',
            qos: 0,
            payload: '',
        })

        let client = ref(false)
        // End Setting koneksi ke loker

        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            createToast('Berhasil! Kode Pin sudah dikirimkan lewat emailmu. Segera cek email (spam/promotions) untuk mengetahui kode pin milikmu',
            {
            type: 'success',
            timeout: 5000,
            showIcon: 'true',
            transition: 'zoom'
            })
        })

        onMounted (() => {
            let { host, port, ...options } = connection
            let connectUrl = `wss://${host}:${port}`
            try {
                client.value = mqtt.connect(connectUrl, options)
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
            client.value.on('connect', () => {
                console.log('Connection succeeded!')
            })
            client.value.on('error', error => {
                console.log('Connection failed', error)
            })
            client.value.on('message', (topic, message) => {
                this.receiveNews = this.receiveNews.concat(message)
                console.log(`Received message ${message} from topic ${topic}`)
            })
        })
        function doPublish() {
            let { topic, qos, payload } = publish
            client.value.publish(topic, payload, qos, error => {
                if (error) {
                    console.log('Publish error', error)
                }
            })
        }

        function checkPin(){
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.post('booking/check-pin-finish', statepin)
            .then(response => {
                console.log(response.data)
                if(response.data.status == 'success'){
                    let id_loker = localStorage.getItem('id_loker_scan')
                    publish = reactive({
                        topic: 'loker',
                        qos: 0,
                        payload: id_loker.toLowerCase() + '_open',
                    })
                    console.log('Data To Loker Device: ', publish)
                    doPublish()
                    
                    let kode = localStorage.getItem('locker_number_id')
                    this.message_respons = 'Laundry anda akan diproses'

                    localStorage.setItem('redirect_after_rating', '/detail-data/' + id_loker_scan)
                    axios.defaults.headers.common.Authorization = `Bearer ${token}`
                    axios.get(`review/check/${user}`)
                    .then(result => {
                        console.log('Result Cek review/rating: ',result)
                        if (result.data.message == 'User belum memberikan ulasan.'){
                            return router.push({
                                name: "rating",
                            });
                        }else{
                            return router.push({
                                name: 'dataAmbil',
                                params: {kode}
                            })
                        }
                    }).catch((err) => {
                        console.log(err.response)
                        return router.push({
                            name: 'dataAmbil',
                            params: {kode}
                        })
                    })
                }else{
                    createToast('Ops.. Terjadi Kesalahan Sistem. Silahkan Hubungi Admin ERR Code: ' + response.data.status_code.toString(),
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                    this.message_respons = 'Ops, terjadi kesalahan sistem, Hubungi Admin Sistem, Err Code: ' + response.data.status_code.toString()
                }
            }).catch((err) => {
                if(err.response.status.toString() == '404'){
                    createToast('Pin yang anda inputkan salah!',
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                }else{
                    createToast('Ops.. Terjadi Kesalahan Sistem. Silahkan Hubungi Admin ERROR CODE: ' + err.response.status,
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                }
				console.log(err.response)
			})
        }
        function finish(){
            return router.push({
                name: 'dashboard'
            })
        }

        return{
            profile,
            route,
            router,
            token,
            user,
            statepin,
            checkPin,
            finish,
            doPublish,
            client,
            connection,
            publish,
            role,
            id_loker_scan,
        }
    }
}
</script>

<style>

</style>