<template>
<Navbars></Navbars>
    <div class="scan_loker">
        <div class="container">
            <center><h3 class="mt-5 mb-4 title"><b>Scan Loker {{route.params.number_code}} </b></h3></center>

            <div class="scan">
                <div class="row">
                    <div class="col-lg-4 mx-auto">
                        <!-- <div class="camera">
                            <img src="../../assets/images/icon/frame-qr.svg" class="frame" alt="">
                            <video autoplay="true" id="cameraScan">
                            </video>
                            <video ref="video" class="camera-stream" id="cameraScan"/>
                        </div> -->
                        <center>
                            <div class="stream">
                                <qr-stream @decode="onDecode" class="kamera">
                                    
                                </qr-stream>
                                <img src="../../assets/images/icon/frame-qr-topleft.svg" class="frame topleft">
                                <img src="../../assets/images/icon/frame-qr-topright.svg" class="frame topright">
                                <img src="../../assets/images/icon/frame-qr-bottomleft.svg" class="frame bottomleft">
                                <img src="../../assets/images/icon/frame-qr-bottomright.svg" class="frame bottomright">
                            </div>
                        </center>

                        <!-- <div class="my-3">
                            <button class="btn btn__primary" @click="reqPin()">Request PIN</button>
                        </div>             -->
                        <!-- <router-link to="/detail-data" class="btn btn__primary">
                            Scan
                            <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" width="13.639" height="13.639" viewBox="0 0 13.639 13.639">
                                <path id="Icon_awesome-qrcode" data-name="Icon awesome-qrcode" d="M0,8.1H5.845V2.25H0ZM1.948,4.2H3.9V6.147H1.948ZM7.794,2.25V8.1h5.845V2.25Zm3.9,3.9H9.742V4.2H11.69ZM0,15.889H5.845V10.044H0Zm1.948-3.9H3.9V13.94H1.948Zm10.716-1.948h.974v3.9H10.716v-.974H9.742v2.923H7.794V10.044h2.923v.974h1.948Zm0,4.871h.974v.974h-.974Zm-1.948,0h.974v.974h-.974Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Navbars from '@/components/Navbars.vue'
import { QrStream } from 'vue3-qr-reader'
import { reactive, toRefs, ref, onMounted } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import mqtt from 'mqtt'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'ScanLoker',
    components: {
        Navbars,
        QrStream
    },

    setup() {
        const router = useRouter();
        const route = useRoute();
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const locker_number_id = route.params.id
        const number = route.params.number_code
        const state = reactive({
            data: null
        })
        localStorage.setItem('scan_type', 'pickup')
        localStorage.setItem('schema_id', 1)
        const profile = ref([])
        const connection = reactive({
            host: 'm13.cloudmqtt.com',
            port: 37475,
            endpoint: '',
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 4000,
            
            clientId: user + Math.floor((Math.random() * 100000000000) + 1),
            username: 'ouxpjuzq',
            password: 'zMtEaDTaqXmx',
        })
        var publish = reactive({
            topic: 'loker',
            qos: 0,
            payload: '',
        })

        let client = ref(false)

        function onDecode(data) {
            let number = data
            state.data = number
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get('driver/scan-reader/'+(locker_number_id)+'/'+(user)+'/'+(number))
            .then(response => {
                const result = response.data;
                if(result.status == 'success'){
                    const { data } = result;
                    console.log('Data Setelah Scan : ', data)
                    if(result.message == 'Pending Process.'){
                        createToast(data,
                        {
                            type: 'warning',
                            timeout: 5000,
                            showIcon: 'true',
                            transition: 'bounce'
                        })
                        return router.replace({
                            name: 'loker-list'
                        });
                    }
                    profile.value = data;
                    localStorage.setItem('locker_number_id', route.params.id)
                    localStorage.setItem('laundry_id', result.data.laundry_id)
                    // publish.setItem('payload', locker_number_id.toLowerCase() + '_open')
                    publish = reactive({
                        topic: 'loker',
                        qos: 0,
                        payload: number.toLowerCase() + '_open',
                    })
                    console.log(publish)
                    doPublish()
                    return router.push({
                        name: 'detail-data',
                        params: {
                            number, 
                            data: JSON.stringify(data)
                        }
                    })
                }else{
                    createToast('Maaf, Data tidak ditemukan!',
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                }
			}).catch((err) => {
				const { data } = err.response;

                createToast(data.message,
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
			}) 
            
        }

        onMounted (() => {
            if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}
            let { host, port, ...options } = connection
            let connectUrl = `wss://${host}:${port}`
            try {
                client.value = mqtt.connect(connectUrl, options)
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
            client.value.on('connect', () => {
                console.log('Connection succeeded!')
            })
            client.value.on('error', error => {
                console.log('Connection failed', error)
            })
            client.value.on('message', (topic, message) => {
                this.receiveNews = this.receiveNews.concat(message)
                console.log(`Received message ${message} from topic ${topic}`)
            })
        })

        function reqPin(){
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            axios.get('driver/req-pin/'+(user)+'/'+(route.params.number_code))
            .then(response => {
                console.log(response)
                const result = response.data
                if(result.status == 'success'){
                    localStorage.setItem('locker_number_id', response.data.data.locker_number_id)
                    localStorage.setItem('laundry_id', response.data.data.laundry_id)
                    return router.push({
                        name: 'pin-pickup',
                        params: number
                    })
                }

            }).catch((err) => {
				console.log(err.response)
                createToast('Terjadi kesalahan saat meminta PIN',
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
			}) 
        }

        function doPublish() {
            let { topic, qos, payload } = publish
            client.value.publish(topic, payload, qos, error => {
                if (error) {
                    console.log('Publish error', error)
                }
            })
        }
        
        
        return {
            ...toRefs(state),
            onDecode,
            user,
            token,
            router,
            route,
            profile,
            doPublish,
            client,
            connection,
            publish,
            reqPin,
            role,
        }
    }
}
</script>

<style>
    .scan_loker{
        color: var(--darkBlue);
    }
    /* ===== Scan ===== */
    .scan .stream video.qr-stream-camera{
        border-radius: 10px !important;
        box-shadow: 0 3px 15px rgba(13, 110, 253, 0.3);
    }
    .scan .stream .frame{
        position: absolute;
        z-index: 5;
    }
    .scan .stream .topleft{
        left: -15px;
        top: -15px;
    }
    .scan .stream .topright{
        right: -15px;
        top: -15px;
    }
    .scan .stream .bottomleft{
        left: -15px;
        bottom: -15px;
    }
    .scan .stream .bottomright{
        right: -15px;
        bottom: -15px;
    }
    .scan .camera{
        position: relative;
    }
    .scan .camera img{
        width: 100%;
    }
    .scan .camera #cameraScan{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: auto;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
    .scan .btn{
        width: 100%;
        margin: 30px 0;
    }
    /* ===== Scan ===== */
    
    @media (max-width: 576px){
        .scan_loker{
            text-align: center;
        }
        .scan .camera{
            width: 80%;
            display: block;
            margin: 0 auto;
        }
        .scan .btn{
            display: block;
            width: 90%;
            margin: 30px auto;
        }
    }
</style>