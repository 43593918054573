<template>
    <Navbarc></Navbarc>
    <div class="d-finish pb-5">
        <div class="container">
            <h2 class="title text-center mt-5">
                <strong>Booking Drop Point Berhasil!</strong>
            </h2>
            <h5 class="text-center mb-3">
                Berikut adalah QR Code Drop Point anda!
            </h5>

            <figure class="text-center">
                <img :src="imageUrl+qr_code" class="barcode-img" alt="">
            </figure>

            <div class="alert alert-primary my-2 fw-bold" role="alert">
                Silahkan menuju lokasi drop poin yang anda pesan dan tunjukan kode QR anda.
            </div>

            <div class="text-center">
                <button
                    type="button"
                    class="btn btn__primary px-5 mt-3"
                    @click="goToHome()"
                >
                    Selesai
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from "@/components/Navbarc.vue";
import { useRouter } from "vue-router";

export default {
    name: "DropPointFinish",
    components: {
        Navbarc,
    },
    setup(){
        const qr_code = localStorage.getItem('qr_code_d');
        const router = useRouter();

        function goToHome() {
            return router.push({
                name: "dashboard",
            });
        }

        return{
            qr_code,
            router,
            goToHome
        }
    }
}
</script>

<style>

</style>