<template>
    <div class="comingsoon">
        <div class="content">
            <figure>
                <img src="../../assets/images/WABO_Laundry_Tagline.png" alt="">
            </figure>
            <h1 class="text-center">
                Coming Soon
            </h1>
            <router-link to="/" class="btn btn__primary">Kembali ke Aplikasi</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Comngsoon'
}
</script>

<style>
.comingsoon .content{
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 100vh; */
}
.comingsoon .content figure img{
    width: 60%;
    display: block;
    margin: 0 auto 50px;
}
.comingsoon h1{
    color: var(--primaryColor);
    font-weight: 800;
    margin-bottom: 50px;
    font-size: 40pt;
}
.comingsoon .btn{
    display: block;
    margin: 0 auto;
    width: 80%;
}
</style>