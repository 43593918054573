<template>
<Navbars></Navbars>
    <div class="riwayat">
        <div class="container">
            <h3 class="mt-5 mb-4 text-secondary"><b>List Riwayat Scan</b></h3>

            <div class="table-responsive my-5">
                <table class="table table-bordered table-striped table-hover" id="riwayat_scan">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Status</th>
                            <th>Nama</th>
                            <th>Kode Laundry</th>
                            <th>Jenis Layanan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(list, index) in histories" :key="index">
                            <td>{{index+1}}</td>
                            <td>
                                <span class="badge bg-primary" v-if="list.status == 'Process'">{{list.status}}</span>
								<span class="badge bg-warning text-dark" v-else-if="list.status == 'Belum Lunas'">{{list.status}}</span>
								<span class="badge bg-light text-dark" v-else-if="list.status == 'Selesai'">{{list.status}}</span>
								<span class="badge bg-warning text-dark" v-else-if="list.status == 'Booking'">{{list.status}}</span>
								<span class="badge bg-success" v-else-if="list.status == 'Booked'">{{list.status}}</span>
								<span class="badge bg-danger" v-else-if="list.status == 'Cancel'">{{list.status}}</span>
								<span class="badge bg-danger" v-else-if="list.status == 'Expired'">{{list.status}}</span>
								<span class="badge bg-info text-dark" v-else-if="list.status == 'Pickup by Driver'">{{list.status}}</span>
								<span class="badge bg-success" v-else>{{list.status}}</span>
                            </td>
                            <td>{{list.fullname}}</td>
                            <td>{{list.laundry_code}}</td>
                            <td>{{list.locker_name}}</td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>
</template>

<script>
import Navbars from '@/components/Navbars.vue'
import { onMounted, nextTick, ref } from 'vue'
import axios from 'axios'
//Datatable Modules
import "datatables.net-bs5/js/dataTables.bootstrap5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"
import $ from 'jquery'
import { useRouter } from 'vue-router'

export default {
    name: 'Riwayat',
    components: {
        Navbars,
    },
    setup(){
        const router = useRouter();
        const token = localStorage.getItem('token')
		const user = localStorage.getItem('user')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'driver'
        const histories = ref([])

        onMounted(() => {
            if(role == 'customer'){
				return router.push({
					path: '/'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get(`driver-history-lockers/${user}`)
			.then(result => {

				histories.value = result.data.data
				console.log('List Riwayat Scan Driver: ',result.data.data)
                getHistory()
			}).catch((err) => {
				console.log(err.response)
			})
		})

		function getHistory(){
			nextTick(() => {
				$('#riwayat_scan').DataTable({
                    ordering:  false
                })
			})
		}
        return{
			getHistory,
            router,
            role,
            histories,
            token,
            user
		}
    }
}
</script>

<style>

</style>