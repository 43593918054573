<template>
	
	<router-view/>
</template>

<script>
	export default{
		name: 'App',
		
	}
</script>

<style>
	#app {
		font-family: 'Poppins', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
		color: #2c3e50;
	}

</style>
