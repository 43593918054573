<template>
    <Navbarc></Navbarc>
    <div class="scan">
        <div class="container">
            <center><h3 class="mt-5 mb-4 title"><b>Booking Loker {{id_loker_scan}}</b></h3></center>

            <div class="scan">
                <div class="row">
                    <div class="col-lg-4 col-sm-10 mx-auto">
                        <!-- <div class="camera"> -->
                            <!-- <img src="../../assets/images/icon/frame-qr.svg" class="frame" alt=""> -->
                            <!-- <video autoplay="true" id="cameraScan">
                            </video> -->
                            <!-- <video ref="video" class="camera-stream" id="cameraScan"/> -->
                            
                        <!-- </div> -->
                        <center>
                            <div class="stream">
                                <qr-stream @decode="onDecode" class="kamera">
                                    
                                </qr-stream>
                                <img src="../../assets/images/icon/frame-qr-topleft.svg" class="frame topleft">
                                <img src="../../assets/images/icon/frame-qr-topright.svg" class="frame topright">
                                <img src="../../assets/images/icon/frame-qr-bottomleft.svg" class="frame bottomleft">
                                <img src="../../assets/images/icon/frame-qr-bottomright.svg" class="frame bottomright">
                            </div>
                        </center>
                        
                        <div class="result__text" v-if="notFound">
                            <p class="text-center text-danger">Data tidak ditemukan.</p>
                        </div>
                        <br>
                        <div class="alert alert-info alert-dismissible">
                            <!-- <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> -->
                            <h5><i class="icon fas fa-info"></i> Info!</h5>
                            Jika scan tidak bisa, gunakan fitur "request pin".
                        </div>
                        <div class="mt-5" v-if="number_code !== null && number_code.length > 0">
                            <button type="button" class="btn btn__primary" @click="handleRequestPin" :disabled="loadingRequestPin">Request PIN</button>
                        </div>
                        
                        <!-- <p v-show="onPublishMessage('loker', profile.locker_number_id+'_open')"></p> -->
            
                        <!-- <router-link to="/identity" class="btn btn__primary">
                            Scan
                            <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" width="13.639" height="13.639" viewBox="0 0 13.639 13.639">
                                <path id="Icon_awesome-qrcode" data-name="Icon awesome-qrcode" d="M0,8.1H5.845V2.25H0ZM1.948,4.2H3.9V6.147H1.948ZM7.794,2.25V8.1h5.845V2.25Zm3.9,3.9H9.742V4.2H11.69ZM0,15.889H5.845V10.044H0Zm1.948-3.9H3.9V13.94H1.948Zm10.716-1.948h.974v3.9H10.716v-.974H9.742v2.923H7.794V10.044h2.923v.974h1.948Zm0,4.871h.974v.974h-.974Zm-1.948,0h.974v.974h-.974Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
// import aesEncrypt from '@/security'
import { QrStream } from 'vue3-qr-reader'
import { reactive, toRefs, ref, onMounted } from 'vue'
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router'
import mqtt from 'mqtt'

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// dokumentasi mosha-vue-toastify => https://szboynono.github.io/mosha-vue-toastify/

export default {
    name: 'Scan',
    components: {
        Navbarc,
        QrStream
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const token = localStorage.getItem('token')
        const user = localStorage.getItem('user')
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        const state = reactive({
            data: null
        })
        const profile = ref([])
        const notFound = ref(false)

        const id_loker_scan = localStorage.getItem('id_loker_scan') ? localStorage.getItem('id_loker_scan') : ''
        
        const connection = reactive({
            host: 'm13.cloudmqtt.com',
            port: 37475,
            endpoint: '',
            clean: true,
            connectTimeout: 4000,
            reconnectPeriod: 4000,
            
            clientId: user + Math.floor((Math.random() * 100000000000) + 1),
            username: 'ouxpjuzq',
            password: 'zMtEaDTaqXmx',
        })
        var publish = reactive({
            topic: 'loker',
            qos: 0,
            payload: '',
        })

        let client = ref(false)

        // number_code untuk nomor loker request pin
        console.log("PARAM NUMBER CODE: ", route.params.number_code)
        const number_code = ref(route.params.number_code ? route.params.number_code : id_loker_scan);
        // loading request pin
        const loadingRequestPin = ref(false);

        axios.defaults.headers.common.Authorization = `Bearer ${token}`

        function onDecode(data) {
            let kode = data
            state.data = kode
            axios.get('booking/scan/'+(user)+'/'+(kode))
            .then(response => {
                console.log(response)
                if(response.data.status == 'success'){
                    profile.value = response.data.data
                    console.log(response.data)
                    localStorage.setItem('profile_name', response.data.data.fullname)
                    localStorage.setItem('profile_address', response.data.data.address)
                    localStorage.setItem('profile_email', response.data.data.email)
                    localStorage.setItem('profile_gender', response.data.data.gender)
                    publish = reactive({
                        topic: 'loker',
                        qos: 0,
                        payload: kode.toLowerCase() + '_open',
                    })
                    console.log(publish)
                    doPublish()
                    return router.push({
                        name: 'identity',
                        params: {kode}
                    })
                }else if(response.data.status == 'not found'){
                    createToast('Maaf, Data Tidak ditemukan',
                    {
                        type: 'warning',
                        timeout: 5000,
                        showIcon: 'true',
                        transition: 'bounce'
                    })
                    notFound.value = true
                }
			}).catch((err) => {
				console.log(err)
                createToast('Terjadi kesalahan yang tak terduga!',
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
			}) 
            
        }

        onMounted (() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            let { host, port, ...options } = connection
            let connectUrl = `wss://${host}:${port}`
            try {
                client.value = mqtt.connect(connectUrl, options)
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
            client.value.on('connect', () => {
                console.log('Connection succeeded!')
            })
            client.value.on('error', error => {
                console.log('Connection failed', error)
            })
            client.value.on('message', (topic, message) => {
                this.receiveNews = this.receiveNews.concat(message)
                console.log(`Received message ${message} from topic ${topic}`)
            })
        })
        function doPublish() {
            let { topic, qos, payload } = publish
            client.value.publish(topic, payload, qos, error => {
                if (error) {
                    console.log('Publish error', error)
                }
            })
        }


        // handle modal ketika di click maka akan request pin
        const handleRequestPin = () => {
            loadingRequestPin.value = true;
            console.log("Number_code on click Scan: ", number_code.value)
            axios.get(`booking/req-pin/${user}/${number_code.value}`).then((res) => {
                const { data } = res;
                console.log('Respon Req Pin: ', res)
                profile.value = data;
                console.log(res)
                // publish = reactive({
                //     topic: 'loker',
                //     qos: 0,
                //     payload: number_code.value.toLowerCase() + '_open',
                // });
                
                // doPublish();
                
                // alert(data.message);

                loadingRequestPin.value = false;

                return router.push({
                    name: 'pin',
                    params: {
                        kode: number_code.value
                    }
                });
            }).catch((err) => {
                const { data } = err.response;

                createToast('ERROR! : ' + data.message,
                {
                    type: 'danger',
                    timeout: 5000,
                    showIcon: 'true',
                    transition: 'bounce'
                })
                loadingRequestPin.value = false;
            });
        }

        return {
            ...toRefs(state),
            onDecode,
            user,
            token,
            router,
            profile,
            notFound,
            doPublish,
            client,
            connection,
            publish,
            handleRequestPin,
            number_code,
            loadingRequestPin,
            id_loker_scan,
            role,
        }
    }

}
</script>

<style>
    /* ===== Scan ===== */
    .scan .stream{
        position: relative;
        z-index: 5;
    }
    .scan .result__text p{
        font-size: 11pt;
        font-weight: 600;
    }
    .scan .stream video.qr-stream-camera{
        border-radius: 10px !important;
        width: 100% !important;
        box-shadow: 0 3px 15px rgba(13, 110, 253, 0.3);
    }
    .scan .stream .qr-stream-wrapper{
        width: 100% !important;
        height: 100% !important;
    }
    .scan .stream .frame{
        position: absolute;
        z-index: 5;
    }
    .scan .stream .topleft{
        left: -15px;
        top: -15px;
    }
    .scan .stream .topright{
        right: -15px;
        top: -15px;
    }
    .scan .stream .bottomleft{
        left: -15px;
        bottom: -15px;
    }
    .scan .stream .bottomright{
        right: -15px;
        bottom: -15px;
    }
    .scan .camera{
        position: relative;
    }
    .scan .camera img{
        width: 60%;
    }
    .scan .camera #cameraScan{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: auto;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
    .scan .btn{
        width: 100%;
        margin: 30px 0;
    }
    /* ===== Scan ===== */
    
    @media (max-width: 576px){
        
        .scan .camera{
            width: 80%;
            display: block;
            margin: 0 auto;
        }
        .scan .btn{
            display: block;
            width: 90%;
            margin: 30px auto;
        }
        .scan .stream{
            position: relative;
            z-index: 5;
            width: 80% !important;
            display: block;
            margin: 10px auto 30px;
        }
        .scan .stream .qr-stream-wrapper{
            width: 100% !important;
            height: 230px !important;
        }
    }
</style>