<template>
<Navbarc></Navbarc>
    <div class="layanan">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="boxes">
                        <router-link v-for="service in services.slice().reverse()" :key="service.service_id" :to="'/booking?service_id='+service.service_id+'&service_name='+service.name" class="box">
                            <figure v-if="service.service_id.toString() === '1'">
                                <img src="../../assets/images/icon-setrika.svg" class="icon_setrika" alt="">
                            </figure>
                            <figure v-else>
                                <img src="../../assets/images/icon-cuci.svg" class="icon_cuci" alt="">
                            </figure>
                            <div class="text-center">
                                <h6>{{service.name}}</h6>
                                <span v-if="service.service_id.toString() === '1'"><h6>Rp.4000/Kg</h6></span>
                                <span v-else><h6>Rp.6000/Kg</h6></span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbarc from '@/components/Navbarc.vue'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useRouter } from "vue-router";

export default {
    name: 'Layanan',
    components: {
        Navbarc,
    },
    setup(){
        const token = localStorage.getItem('token')
        const services = ref([])
        const router = useRouter();
        const role = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer'
        onMounted(() => {
            if(role == 'driver'){
				return router.push({
					path: '/home'
				})
			}
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
			axios.get('service')
			.then((result) => {
                console.log(result)
				services.value = result.data.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        return{
			services,
            router,
            role
		}
    }
}
</script>

<style scoped>
    .boxes{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 10px;
    }
    .box{
        background: linear-gradient(45deg, rgba(0,123,255,1) 0%, rgba(0,153,255,1) 100%);
        padding: 20px;
        text-decoration: none;
        border-radius: 10px;
        box-shadow: 0 5px 20px rgba(0,123,255,0.3);
        margin: 20px;
        display: block;
        vertical-align: middle;
        /* align-items: center;
        justify-content: center; */
        width: 200px;
        height: 200px;
    }
    .box:hover{
        background: linear-gradient(70deg, rgb(0, 107, 221) 0%, rgb(15, 159, 255) 100%);
    }
    .box figure{
        height: 70%;
        display: flex;
        align-items: center;
    }
    .box figure img{
        display: block;
        margin: 0 auto;
    }
    .box h6{
        color: white;
        font-weight: 600;
        text-transform: uppercase;
    }

    @media (max-width: 528px){
        .boxes{
           display: block;
           margin: 80px 10px;
        }
        .box{
            margin: 25px auto;
        }
    }
</style>